import { Link } from '@tanstack/react-router';
import { clsx } from 'clsx';
import type { ReactNode } from 'react';

import type { GetFeatureResponse } from '@/api/models';
import { featureTypeMap } from '@/utils/featureTypeMap';

interface FeatureListItemProps {
  feature: GetFeatureResponse;
  children?: ReactNode;
}

export default function FeatureListItem({
  feature,
  children,
}: FeatureListItemProps) {
  return (
    <Link
      to="/feature/$featureId"
      params={{ featureId: feature.id.toString() }}
      className={clsx(
        'group bg-slate-700 rounded-md shadow-xs transition-all duration-150',
        'hover:bg-slate-600/80 hover:shadow-md overflow-hidden',
      )}
    >
      <div className="flex h-full gap-1">
        <div className="text-slate-400 bg-slate-600 min-h-full w-12">
          {feature.photoUrl ? (
            <img
              src={feature.photoUrl}
              alt={feature.name || 'Unnamed feature'}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center p-2">
              {featureTypeMap[feature.type].icon}
            </div>
          )}
        </div>
        <div className="flex flex-col p-2 grow">
          <div className="flex justify-between items-start gap-2">
            <div className="flex flex-col">
              <h3 className="text-sm font-medium text-slate-100 group-hover:text-white transition-colors">
                {feature.name || 'Unnamed feature'}
              </h3>
              <div className="flex gap-2 text-xs text-slate-400">
                {feature.elevation && (
                  <span>{feature.elevation.toLocaleString()} m</span>
                )}
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </Link>
  );
}
