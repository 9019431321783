import {
  type UseMutationResult,
  type UseQueryOptions,
  type UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import apiClient from '@/api/apiClient';
import type { Ascent, PrivacyLevel } from '@/api/apiTypes';
import type {
  AthleteDTO,
  GetAdminAtheleteResponse,
  GetSelfResponse,
} from '@/api/models';
import type { AxiosError } from 'axios';
import { queryClient } from '../queryClient';

export const useGetSelf = (
  options?: Partial<UseQueryOptions<GetSelfResponse>>,
): UseQueryResult<GetSelfResponse> =>
  useQuery({
    queryKey: ['me'],
    queryFn: () =>
      apiClient.get<GetSelfResponse>('/athlete').then((res) => res.data),
    // Only 1 retry
    retry: 1,
    ...options,
  });

type UpdateProfileData = {
  username?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  profilePrivacy: PrivacyLevel;
  ascentPrivacy: PrivacyLevel;
};

export const useUpdateProfile = () => {
  return useMutation({
    mutationFn: (data: UpdateProfileData) =>
      apiClient.put<GetSelfResponse>('/athlete', data).then((res) => res.data),
    onSuccess: (data, _variables, _context) => {
      queryClient.setQueryData(['me'], data);
      queryClient.invalidateQueries({
        queryKey: ['athlete', data.id],
      });
    },
  });
};

export const useGetAthletes = (): UseQueryResult<GetAdminAtheleteResponse[]> =>
  useQuery({
    queryKey: ['athletes'],
    queryFn: () =>
      apiClient
        .get<GetAdminAtheleteResponse[]>('/athletes')
        .then((res) => res.data),
  });

export const useGetAthleteAscents = (
  athleteId: number,
  options?: Partial<UseQueryOptions<Ascent[]>>,
): UseQueryResult<Ascent[]> =>
  useQuery({
    queryKey: ['athleteAscents', athleteId],
    queryFn: () =>
      apiClient
        .get<Ascent[]>(`/athletes/${athleteId}/ascents`)
        .then((res) => res.data),
    ...options,
  });

export const useGetAthlete = (
  athleteId: number | undefined,
): UseQueryResult<AthleteDTO> =>
  useQuery({
    queryKey: ['athlete', athleteId],
    queryFn: () =>
      apiClient
        .get<AthleteDTO>(`/athletes/${athleteId}`)
        .then((res) => res.data),
    enabled: athleteId !== undefined,
  });

export const useRefreshAthlete = (): UseMutationResult<
  AthleteDTO,
  unknown,
  number
> =>
  useMutation({
    mutationFn: (athleteId: number) =>
      apiClient
        .post<AthleteDTO>(`/athletes/${athleteId}/refresh`)
        .then((res) => res.data),
  });

export const useDeleteAthlete = (): UseMutationResult<void, unknown, number> =>
  useMutation({
    mutationFn: async (athleteId: number) => {
      try {
        await apiClient.delete(`/athletes/${athleteId}`);
        return undefined;
      } catch (error) {
        // If it's a 204 response, treat it as success
        if (error instanceof Error && 'response' in error) {
          const axiosError = error as AxiosError;
          if (axiosError.response?.status === 204) {
            return undefined;
          }
        }
        throw error;
      }
    },
    onSuccess: () => {
      // Clear all athlete related queries
      queryClient.clear();
    },
  });
