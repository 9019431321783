import type { GetListResponse } from '@/api/models';
import ListItem from './listItem';

type ListsProps = {
  setListProgressTooltipContent: (list: GetListResponse | null) => void;
  lists: (GetListResponse & { progress?: number })[];
};

export default function Lists(props: ListsProps) {
  const { setListProgressTooltipContent, lists } = props;

  return (
    <ul className="gap-2 flex flex-col">
      {lists.map((list) => (
        <ListItem
          key={list.id}
          list={list}
          setListProgressTooltipContent={setListProgressTooltipContent}
        />
      ))}
    </ul>
  );
}
