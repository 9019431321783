import {
  AthleteFilter,
  FeatureTypeFilter,
  ListFilter,
  RegionFilter,
} from './filters';

export default function MapFilters() {
  return (
    <div className="absolute top-0 left-0 md:left-96 p-3 md:p-4 z-20">
      <div className="flex flex-row gap-2.5 justify-start items-center flex-wrap md:mx-4">
        <FeatureTypeFilter />
        <AthleteFilter />
        <ListFilter />
        <RegionFilter />
      </div>
    </div>
  );
}
