import { useGetListLeaderboard } from '@/api/hooks';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { FaUser } from 'react-icons/fa';

type ListLeaderboardProps = {
  listId: number;
  listFeatureCount?: number;
};

export default function ListLeaderboard(props: ListLeaderboardProps) {
  const { t } = useTranslation();
  const { listId } = props;
  const { data: leaderboard, isLoading } = useGetListLeaderboard(listId, {
    limit: 5,
  });

  if (isLoading || !leaderboard?.climbers.length) {
    return null;
  }

  return (
    <div>
      <h3 className="text-xs font-medium text-slate-400 mb-1.5 px-2">
        {t('leaderboard.title')}
      </h3>
      <div>
        {leaderboard.climbers.map((climber) => (
          <Link
            key={climber.id}
            to="/athlete/$athleteId"
            params={{ athleteId: climber.id.toString() }}
            className="flex items-center gap-2 px-2 py-1 hover:bg-slate-700/50 transition-colors"
          >
            <div className="w-5 h-5 rounded-full overflow-hidden bg-slate-700 flex items-center justify-center shrink-0">
              {climber.profilePicture ? (
                <img
                  src={climber.profilePicture}
                  alt={climber.username || 'Unknown Climber'}
                  className="w-full h-full object-cover"
                />
              ) : (
                <FaUser className="w-2.5 h-2.5 text-slate-400" />
              )}
            </div>
            <div className="grow min-w-0 flex items-baseline justify-between gap-1">
              <div className="truncate">
                <span className="text-sm text-slate-200">
                  {climber.fullName ||
                    climber.username ||
                    t('leaderboard.unknownClimber')}
                </span>
                {climber.fullName && climber.username && (
                  <span className="text-xs text-slate-400 ml-1">
                    @{climber.username}
                  </span>
                )}
              </div>
              <div className="flex items-center gap-1.5 shrink-0">
                <div className="w-12 h-1.5 rounded-full bg-slate-700 overflow-hidden">
                  {props.listFeatureCount && (
                    <div
                      className="h-full bg-blue-500 rounded-full transition-all duration-300"
                      style={{
                        width: `${(climber.featureCount / props.listFeatureCount) * 100}%`,
                      }}
                    />
                  )}
                </div>
                <span className="text-xs font-medium text-slate-300">
                  {climber.featureCount}
                </span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
