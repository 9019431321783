import {
  type UseMutationResult,
  type UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import apiClient from '@/api/apiClient';
import type {
  CreateFeatureDTO,
  DataSourceFeature,
  Feature,
  GetAllFeaturesQuery,
  UpdateFeatureDTO,
} from '@/api/apiTypes';
import type { GetFeatureResponse } from '@/api/models';
import { queryClient } from '@/api/queryClient';

export const useUpdateFeature = (): UseMutationResult<
  Feature,
  unknown,
  UpdateFeatureDTO
> =>
  useMutation({
    mutationFn: ({ id, ...data }) =>
      apiClient.put(`/features/${id}`, data).then((res) => res.data),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['feature', variables.id],
      });
    },
  });

export const useGetFeature = (
  id: number | undefined,
): UseQueryResult<GetFeatureResponse, AxiosError> =>
  useQuery({
    queryKey: ['feature', id],
    queryFn: () =>
      apiClient
        .get<GetFeatureResponse>(`/features/${id}`)
        .then((res) => res.data),
    enabled: id !== undefined,
    // Load data from features query
    placeholderData: () => {
      return queryClient
        .getQueryData<GetFeatureResponse[]>(['features'])
        ?.find((feature) => feature.id === id);
    },
  });

export const usePrefetchFeature = (id: number): Promise<void> =>
  queryClient.prefetchQuery({
    queryKey: ['feature', id],
    queryFn: () =>
      apiClient.get<Feature>(`/features/${id}`).then((res) => res.data),
  });

export const useCreateFeature = (): UseMutationResult<
  Feature,
  AxiosError,
  CreateFeatureDTO
> =>
  useMutation({
    mutationFn: (data: CreateFeatureDTO) =>
      apiClient.post<Feature>('/features', data).then((res) => res.data),
    onSuccess: (data) => {
      queryClient.setQueryData(['feature', data.id], data);
    },
  });

export const useGetFeatures = (
  data: GetAllFeaturesQuery,
): UseQueryResult<GetFeatureResponse[], AxiosError> =>
  useQuery({
    queryKey: ['features', data],
    queryFn: async () => {
      const returnedData = await apiClient
        .get<GetFeatureResponse[]>('/features', { params: data })
        .then((res) => res.data);

      // Search through all features and update the cache if the feature is not already in the cache
      const queries = queryClient.getQueriesData({ queryKey: ['feature'] });
      const queryKeys = queries.map(([queryKey]) => queryKey[1]);

      for (const feature of returnedData) {
        if (!queryKeys.includes(feature.id)) {
          queryClient.setQueryData(['feature', feature.id], feature);
          await queryClient.invalidateQueries({
            queryKey: ['feature', feature.id],
          });
        }
      }

      return returnedData;
    },
    placeholderData: keepPreviousData,
  });

export const useGetSimilarFeatures = (
  id: number,
): UseQueryResult<
  {
    feature: Feature | DataSourceFeature;
    score: number;
    factors: {
      location: number;
      name?: number;
      type?: number;
      elevation?: number;
      prominence?: number;
    };
  }[],
  AxiosError
> =>
  useQuery({
    queryKey: ['similarFeatures', id],
    queryFn: () =>
      apiClient
        .get<
          {
            feature: Feature | DataSourceFeature;
            score: number;
            factors: {
              location: number;
              name?: number;
              type?: number;
              elevation?: number;
              prominence?: number;
            };
          }[]
        >(`/features/${id}/similar`)
        .then((res) => res.data),
  });

export const useUploadFeatureImage = (): UseMutationResult<
  { data: Feature },
  AxiosError,
  { id: number; file: File }
> =>
  useMutation({
    mutationFn: (data: { id: number; file: File }) => {
      const formData = new FormData();
      formData.append('file', data.file);

      return apiClient.post<Feature>(`/features/${data.id}/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['feature', variables.id.toString()], data.data);
    },
  });
