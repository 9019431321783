import clsx from 'clsx';

type Status = 'planned' | 'in-progress' | 'completed' | 'future';

interface StatusBadgeProps {
  status: Status;
  className?: string;
}

export function StatusBadge({ status, className }: StatusBadgeProps) {
  const getStatusStyles = (status: Status) => {
    switch (status) {
      case 'planned':
        return 'bg-amber-200 text-amber-800';
      case 'future':
        return 'bg-gray-200 text-gray-800';
      case 'in-progress':
        return 'bg-blue-200 text-blue-800';
      case 'completed':
        return 'bg-green-200 text-green-800';
      default:
        return 'bg-gray-200 text-gray-800';
    }
  };

  return (
    <span
      className={clsx(
        'inline-flex items-center rounded-full px-2 py-1 text-xs font-medium',
        getStatusStyles(status),
        className,
      )}
    >
      {status.charAt(0).toUpperCase() + status.slice(1).replace('-', ' ')}
    </span>
  );
}
