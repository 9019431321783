import { Link } from '@tanstack/react-router';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { Button, TooltipTrigger } from 'react-aria-components';
import {
  FaGlobe,
  FaInfoCircle,
  FaList,
  FaMap,
  FaSearch,
  FaTimes,
  FaUser,
} from 'react-icons/fa';
import { useMap } from 'react-map-gl/maplibre';

import { RegionType } from '@/api/apiTypes';
import { usePrefetchFeature, useSearch } from '@/api/hooks';
import type {
  AthleteSearchResult,
  FeatureSearchResult,
  ListSearchResult,
  RegionSearchResult,
  SearchResults,
  SearchType,
} from '@/api/models/search';
import useDebounce from '@/hooks/useDebounce';
import { featureTypeMap } from '@/utils/featureTypeMap';
import sortRegions from '@/utils/sortRegions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from './react-aria/Tooltip';

const getFirstAvailableTab = (
  data: SearchResults | undefined,
  types: SearchType[],
): SearchType | null => {
  if (!data) return null;

  const availableTabs = [
    { type: 'feature' as const, count: data.features.length },
    { type: 'athlete' as const, count: data.athletes.length },
    { type: 'region' as const, count: data.regions.length },
    { type: 'list' as const, count: data.lists.length },
  ].filter((tab) => types.includes(tab.type) && tab.count > 0);

  return availableTabs[0]?.type ?? null;
};

interface SearchProps {
  onFeatureSelect?: (featureId: number) => void;
  resultContainerPosition?: 'top' | 'bottom';
  types?: SearchType[];
}

function RegionText({
  region,
}: {
  region: {
    id: number;
    name: string;
    type: RegionType;
    isocode: string | null;
    imageUrl: string | null;
  };
}) {
  return (
    <div className="flex flex-row items-center gap-1">
      {region.type === RegionType.COUNTRY && (
        <span
          className={`fi fi-${region.isocode?.toLocaleLowerCase()} h-3 w-3`}
        />
      )}
      {region.type !== RegionType.COUNTRY && region.imageUrl && (
        <img src={region.imageUrl} alt={region.name} className="h-3 w-3" />
      )}
      <span className="text-slate-300 text-sm">{region.name}</span>
    </div>
  );
}

function RegionTexts({
  regions,
}: {
  regions: {
    id: number;
    name: string;
    type: RegionType;
    isocode: string | null;
    imageUrl: string | null;
  }[];
}) {
  return (
    <div className="flex flex-row items-center gap-1">
      {regions.sort(sortRegions).map((region, index) => (
        <React.Fragment key={region.id}>
          {index > 0 && (
            <span className="text-slate-300 text-sm">&middot;</span>
          )}
          <RegionText region={region} />
        </React.Fragment>
      ))}
    </div>
  );
}

function FeatureItem({ feature }: { feature: FeatureSearchResult }) {
  const prefetchFeature = async () => {
    await usePrefetchFeature(feature.id);
  };

  return (
    <div
      className="flex flex-row items-center gap-4 w-full"
      onMouseOver={prefetchFeature}
      onFocus={prefetchFeature}
    >
      {feature.primaryPhotoUrl ? (
        <img
          src={feature.primaryPhotoUrl}
          alt={feature.name || 'Unnamed feature'}
          className="max-w-16 h-auto object-cover rounded-xs"
        />
      ) : (
        <div className="w-16 rounded-xs flex items-center justify-center p-3 bg-slate-600/50">
          <div className="w-full h-full flex items-center justify-center text-slate-300">
            {featureTypeMap[feature.type].icon}
          </div>
        </div>
      )}
      <div className="flex flex-col grow min-w-0">
        <div className="text-slate-100 font-medium text-base truncate">
          {feature.name}
        </div>
        <div className="flex flex-row items-center gap-1">
          {feature.regions && feature.regions.length > 0 && (
            <RegionTexts regions={feature.regions} />
          )}
        </div>
      </div>
      <div className="text-right shrink-0">
        {feature.elevation && (
          <div className="bg-slate-700 px-2 py-1 rounded-xs">
            <span className="font-mono font-medium text-sm">
              {feature.elevation?.toFixed(0)}
            </span>
            <span className="text-slate-400 text-xs ml-1">m</span>
          </div>
        )}
      </div>
    </div>
  );
}

function AthleteItem({ athlete }: { athlete: AthleteSearchResult }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center gap-4 w-full">
      <div className="w-6 flex items-center justify-center">
        {athlete.profileImageUrl ? (
          <img
            src={athlete.profileImageUrl}
            alt={athlete.username || athlete.fullName || 'Unknown climber'}
            className="h-6 w-6 rounded-full"
          />
        ) : (
          <FaUser className="text-slate-100" />
        )}
      </div>
      <div className="flex flex-col grow min-w-0">
        <div className="text-slate-100 font-medium text-base truncate">
          {athlete.username && athlete.fullName ? (
            <span className="whitespace-nowrap">
              {athlete.username} ({athlete.fullName})
            </span>
          ) : athlete.username ? (
            <span className="whitespace-nowrap">{athlete.username}</span>
          ) : (
            <span className="whitespace-nowrap">{athlete.fullName}</span>
          )}
        </div>
        {athlete.region && <RegionText region={athlete.region} />}
      </div>
      <div className="text-right shrink-0">
        <span className="text-slate-400 text-sm">
          {athlete.featureCount} {t('search.peaks')}
        </span>
      </div>
    </div>
  );
}

function ListItem({ list }: { list: ListSearchResult }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center gap-4 w-full">
      <div className="w-6 flex items-center justify-center">
        <FaList className="text-slate-100" />
      </div>
      <div className="flex flex-col grow min-w-0">
        <div className="text-slate-100 font-medium text-base truncate">
          {list.name}
        </div>
        <span className="text-slate-400 text-sm">
          {list.featureCount} {t('search.features_count')}
        </span>
      </div>
    </div>
  );
}

function RegionItem({ region }: { region: RegionSearchResult }) {
  return (
    <div className="flex flex-row items-center gap-4 w-full">
      <div className="w-6 flex items-center justify-center">
        {region.type === RegionType.COUNTRY && (
          <span
            className={`fi fi-${region.isocode?.toLocaleLowerCase()} text-xl`}
          />
        )}
        {region.type !== RegionType.COUNTRY && region.imageUrl && (
          <img src={region.imageUrl} alt={region.name} className="h-5 w-5" />
        )}
      </div>
      <div className="flex flex-col grow min-w-0">
        <div className="text-slate-100 font-medium text-base truncate">
          {region.name}
        </div>
        {region.parentRegion && (
          <span className="text-slate-400 text-sm">
            {region.parentRegion.name}
          </span>
        )}
      </div>
    </div>
  );
}

function Tab({
  label,
  active,
  onClick,
  count,
}: {
  label: string;
  active: boolean;
  onClick: () => void;
  count: number;
}) {
  return (
    <button
      type="button"
      className={clsx(
        'px-3 py-2 text-sm font-medium rounded-md transition-colors flex items-center gap-2',
        active
          ? 'bg-slate-700 text-slate-100'
          : 'text-slate-400 hover:text-slate-300',
      )}
      onClick={onClick}
    >
      {label}
      {count > 0 && (
        <span
          className={clsx(
            'text-xs px-1.5 py-0.5 rounded-full font-medium',
            active ? 'bg-slate-600' : 'bg-slate-700',
          )}
        >
          {count >= 10 ? '10+' : count}
        </span>
      )}
    </button>
  );
}

export default function Search({
  onFeatureSelect,
  resultContainerPosition = 'bottom',
  types = ['feature', 'athlete', 'list', 'region'],
}: SearchProps) {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [activeTab, setActiveTab] = useState<SearchType>('feature');
  const [boundsEnabled, setBoundsEnabled] = useState(false);
  const map = useMap();

  const bounds =
    map.default?.getBounds() &&
    ([
      // northEast [lat, lng]
      [
        Number(map.default.getBounds().getNorth().toFixed(6)),
        Number(map.default.getBounds().getEast().toFixed(6)),
      ],
      // southWest [lat, lng]
      [
        Number(map.default.getBounds().getSouth().toFixed(6)),
        Number(map.default.getBounds().getWest().toFixed(6)),
      ],
    ] as [[number, number], [number, number]]);

  const debouncedQuery = useDebounce(query, 100);
  const search = useSearch(
    debouncedQuery,
    types,
    boundsEnabled ? bounds : undefined,
  );

  useEffect(() => {
    if (search.isSuccess && search.data) {
      const currentTabHasResults =
        (activeTab === 'feature' && search.data.features.length > 0) ||
        (activeTab === 'athlete' && search.data.athletes.length > 0) ||
        (activeTab === 'region' && search.data.regions.length > 0) ||
        (activeTab === 'list' && search.data.lists.length > 0);

      if (!currentTabHasResults) {
        const firstAvailableTab = getFirstAvailableTab(search.data, types);
        if (firstAvailableTab) {
          setActiveTab(firstAvailableTab);
        }
      }
    }
  }, [search.data, search.isSuccess, activeTab, types]);

  return (
    <div className="relative rounded-md bg-slate-700">
      <div className="flex flex-col gap-2">
        <input
          type="text"
          name="search"
          placeholder={t('search.placeholder')}
          className="w-full bg-slate-700 text-slate-100 pl-10 pr-20 py-2 rounded-md font-medium focus:outline-hidden focus:ring-3 focus:ring-sky-500"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          autoComplete="off"
        />
      </div>
      <div className="absolute right-3 top-1/2 transform -translate-y-1/2 h-full flex items-center gap-2">
        {map && (
          <TooltipTrigger>
            <Button
              className={clsx(
                'p-2 rounded-md transition-colors',
                boundsEnabled
                  ? 'bg-sky-500/20 text-sky-400'
                  : 'hover:bg-slate-600/50 text-slate-400',
              )}
              onPress={() => setBoundsEnabled(!boundsEnabled)}
            >
              <FaMap className="w-4 h-4" />
            </Button>
            <Tooltip>{t('search.limitToMap')}</Tooltip>
          </TooltipTrigger>
        )}
        {query && (
          <button
            onClick={() => setQuery('')}
            className="p-2 hover:bg-slate-600/50 rounded-md transition-colors"
            type="button"
          >
            <FaTimes className="text-slate-100 w-4 h-4" />
          </button>
        )}
      </div>
      <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
        <FaSearch className="text-slate-100" />
      </div>
      {(search.isSuccess || search.isError || search.isLoading) && (
        <div
          className={clsx(
            'absolute w-full bg-slate-800 rounded-md overflow-y-auto z-50 border border-slate-700',
            'max-h-[80vh] md:max-h-none', // Add responsive max height
            {
              'top-full mt-4 left-0': resultContainerPosition !== 'top',
              'bottom-full mb-4 left-0': resultContainerPosition === 'top',
            },
          )}
        >
          {search.isSuccess &&
            search.data &&
            !Object.values(search.data).every((arr) => arr.length === 0) && (
              <div className="border-b border-slate-700 p-2 flex flex-wrap gap-2">
                {types.includes('feature') &&
                  search.data.features.length > 0 && (
                    <Tab
                      label={t('search.features')}
                      active={activeTab === 'feature'}
                      onClick={() => setActiveTab('feature')}
                      count={search.data.features.length}
                    />
                  )}
                {types.includes('athlete') &&
                  search.data.athletes.length > 0 && (
                    <Tab
                      label={t('search.athletes')}
                      active={activeTab === 'athlete'}
                      onClick={() => setActiveTab('athlete')}
                      count={search.data.athletes.length}
                    />
                  )}
                {types.includes('region') && search.data.regions.length > 0 && (
                  <Tab
                    label={t('search.regions')}
                    active={activeTab === 'region'}
                    onClick={() => setActiveTab('region')}
                    count={search.data.regions.length}
                  />
                )}
                {types.includes('list') && search.data.lists.length > 0 && (
                  <Tab
                    label={t('search.lists')}
                    active={activeTab === 'list'}
                    onClick={() => setActiveTab('list')}
                    count={search.data.lists.length}
                  />
                )}
              </div>
            )}

          <div className="p-2">
            {search.isLoading && (
              <div className="text-slate-300 text-center">
                {t('search.loading')}
              </div>
            )}
            {search.isError && (
              <div className="text-slate-300 text-center">
                {(search.error as Error).message}
              </div>
            )}
            {search.isSuccess && (
              <div className="w-full">
                {Object.values(search.data).every((arr) => arr.length === 0) ? (
                  <div className="flex flex-col items-center justify-center gap-3 py-6 px-4">
                    <FaSearch className="w-8 h-8 text-slate-600" />
                    <div className="space-y-1 text-center">
                      <h3 className="text-slate-200 font-medium">
                        {t('search.noResults')}
                      </h3>
                      {boundsEnabled && (
                        <>
                          <p className="text-sm text-slate-400 flex items-center justify-center gap-1.5">
                            <FaInfoCircle className="w-3.5 h-3.5" />
                            <span>{t('search.limitedToMap')}</span>
                          </p>
                          <button
                            onClick={() => setBoundsEnabled(false)}
                            className="mt-2 text-sm text-sky-400 hover:text-sky-300 transition-colors inline-flex items-center gap-1.5"
                            type="button"
                          >
                            <FaGlobe className="w-3.5 h-3.5" />
                            <span>{t('search.searchEverywhere')}</span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {activeTab === 'feature' &&
                      search.data.features.length > 0 && (
                        <div className="divide-y divide-slate-700">
                          {search.data.features
                            .sort(
                              (a, b) =>
                                (b.prominence || 0) - (a.prominence || 0),
                            )
                            .sort(
                              (a, b) => (b.elevation || 0) - (a.elevation || 0),
                            )
                            .map((feature, index) => (
                              <div
                                className={clsx(
                                  index % 2 === 0
                                    ? 'bg-slate-800'
                                    : 'bg-slate-800/50',
                                )}
                                key={feature.id}
                              >
                                {onFeatureSelect ? (
                                  <div
                                    className="hover:bg-slate-600/50 transition-colors p-3 cursor-pointer"
                                    onClick={() => {
                                      onFeatureSelect(feature.id);
                                      setQuery('');
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        onFeatureSelect(feature.id);
                                        setQuery('');
                                      }
                                    }}
                                  >
                                    <FeatureItem feature={feature} />
                                  </div>
                                ) : (
                                  <Link
                                    to="/feature/$featureId"
                                    params={{
                                      featureId: feature.id.toString(),
                                    }}
                                    key={feature.id}
                                    className="block hover:bg-slate-600/50 transition-colors p-3"
                                    onClick={() => {
                                      setQuery('');
                                    }}
                                  >
                                    <FeatureItem feature={feature} />
                                  </Link>
                                )}
                              </div>
                            ))}
                        </div>
                      )}
                    {activeTab === 'athlete' &&
                      search.data.athletes.length > 0 && (
                        <div className="divide-y divide-slate-700">
                          {search.data.athletes.map((athlete) => (
                            <Link
                              key={athlete.id}
                              to="/athlete/$athleteId"
                              params={{ athleteId: athlete.id.toString() }}
                              className="block hover:bg-slate-600/50 transition-colors p-3"
                              onClick={() => setQuery('')}
                            >
                              <AthleteItem athlete={athlete} />
                            </Link>
                          ))}
                        </div>
                      )}
                    {activeTab === 'region' &&
                      search.data.regions.length > 0 && (
                        <div className="divide-y divide-slate-700">
                          {search.data.regions.map((region) => (
                            <Link
                              key={region.id}
                              to="/region/$regionId"
                              params={{ regionId: region.id.toString() }}
                              className="block hover:bg-slate-600/50 transition-colors p-3"
                              onClick={() => setQuery('')}
                            >
                              <RegionItem region={region} />
                            </Link>
                          ))}
                        </div>
                      )}
                    {activeTab === 'list' && search.data.lists.length > 0 && (
                      <div className="divide-y divide-slate-700">
                        {search.data.lists.map((list) => (
                          <Link
                            key={list.id}
                            to="/list/$listId"
                            params={{ listId: list.id.toString() }}
                            className="block hover:bg-slate-600/50 transition-colors p-3"
                            onClick={() => setQuery('')}
                          >
                            <ListItem list={list} />
                          </Link>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
