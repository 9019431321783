import { useGetRegion } from '@/api/hooks';
import { useFeatureQuery } from '@/contexts/featureQueryContext';
import { useTranslation } from 'react-i18next';
import { FaMap } from 'react-icons/fa6';
import { Filter } from './filter';

const RegionImage = ({ regionId }: { regionId: number }) => {
  const { data: region } = useGetRegion(regionId);

  if (!region) {
    return (
      <FaMap size={16} className="text-slate-300 shrink-0" aria-hidden="true" />
    );
  }

  if (region.type === 'COUNTRY' && region.isocode) {
    return (
      <span
        className={`fi fi-${region.isocode.toLocaleLowerCase()} h-4 w-4 shrink-0`}
      />
    );
  }

  if (region.imageUrl) {
    return (
      <img
        src={region.imageUrl}
        alt=""
        className="h-5 rounded-xs object-cover shrink-0 ring-1 ring-slate-700/50"
      />
    );
  }

  return (
    <FaMap size={16} className="text-slate-300 shrink-0" aria-hidden="true" />
  );
};

export const RegionFilter = () => {
  const { queryOptions, clearFilter } = useFeatureQuery();
  const { t } = useTranslation();
  const { data: region } = useGetRegion(
    queryOptions.regionId?.value
      ? Number(queryOptions.regionId.value)
      : undefined,
  );

  return (
    <Filter
      active={!!queryOptions.regionId?.value}
      isAutomatic={queryOptions.regionId?.isAutomatic}
      onClear={
        queryOptions.regionId?.value && !queryOptions.regionId?.isAutomatic
          ? () => clearFilter('regionId')
          : undefined
      }
      ariaLabel={region?.name || t('mapFilters.region.all')}
    >
      {queryOptions.regionId?.value ? (
        <RegionImage regionId={Number(queryOptions.regionId.value)} />
      ) : (
        <FaMap
          size={16}
          className="text-slate-300 shrink-0"
          aria-hidden="true"
        />
      )}
      <div className="flex flex-col min-w-0 max-w-[150px]">
        <span className="text-[10px] leading-none text-slate-400 mb-0.5">
          {t('mapFilters.region.label')}
        </span>
        <span className="text-slate-200 font-medium truncate">
          {region?.name || t('mapFilters.region.all')}
        </span>
      </div>
    </Filter>
  );
};
