import { clsx } from 'clsx';

interface SectionProps {
  title?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  first?: boolean;
}

export default function Section({
  title,
  icon,
  children,
  className,
  first = false,
}: SectionProps) {
  return (
    <div className={clsx('flex flex-col', className)}>
      {title && (
        <div
          className={clsx('', {
            'pt-4 mt-4 border-t border-slate-700': !first,
          })}
        >
          <h3 className="text-xs font-medium text-slate-400 mb-1.5 px-2 flex items-center gap-2">
            {icon}
            {title}
          </h3>
        </div>
      )}
      <div className={clsx('px-2', { 'flex flex-col gap-4': !className })}>
        {children}
      </div>
    </div>
  );
}
