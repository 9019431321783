import { Link, useNavigate, useParams } from '@tanstack/react-router';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { FaMap, FaMountain } from 'react-icons/fa6';
import { useMap } from 'react-map-gl/maplibre';

import type { RegionDTO } from '@/api/apiTypes';
import ErrorMessage from '@/components/errorMessage';
import Section from '@/components/shared/section';
import { useFeatureQuery } from '@/contexts/featureQueryContext';
import { useGetFeatures, useGetRegion } from '../../../api/hooks';
import LoadingSpinner from '../../loadingSpinner';
import Body from '../body';
import Header from '../header';
import FeatureListItem from '../listitems/featureListItem';
import Sidebar from '../sidebar';

function RegionText({ region }: { region: RegionDTO }) {
  return (
    <Link to="/region/$regionId" params={{ regionId: region.id.toString() }}>
      <div className="rounded-md flex items-center gap-2 p-3 py-2 bg-slate-700 hover:bg-slate-600 cursor-pointer">
        {region.type === 'COUNTRY' && (
          <span
            className={`fi fi-${region.isocode?.toLocaleLowerCase()} max-h-6 max-w-6 shrink-0`}
          />
        )}
        {region.type !== 'COUNTRY' && region.imageUrl && (
          <img
            src={region.imageUrl}
            alt={region.name}
            className="max-h-6 max-w-6 shrink-0"
          />
        )}
        <span className="text-slate-300 text-sm">{region.name}</span>
      </div>
    </Link>
  );
}

export default function RegionView() {
  const { t } = useTranslation();
  const params = useParams({ from: '/region/$regionId' });
  const navigate = useNavigate();
  const map = useMap();
  const { updateQueryOptions, clearFilter } = useFeatureQuery();

  const regionId = Number(params.regionId);
  const regionQuery = useGetRegion(regionId);
  const featuresQuery = useGetFeatures({
    regionId: regionId.toString(),
    withProminenceOnly: true,
  });

  const isMobile = window.innerWidth < 768;

  // biome-ignore lint/correctness/useExhaustiveDependencies: Not changing. Should probably look into this.
  useEffect(() => {
    if (regionId) {
      updateQueryOptions('regionId', regionId.toString(), true);
    }
    return () => {
      clearFilter('regionId');
    };
  }, [regionId]);

  useEffect(() => {
    if (
      regionQuery.isSuccess &&
      regionQuery.data &&
      regionQuery.data.boundEast &&
      regionQuery.data.boundSouth &&
      regionQuery.data.boundWest &&
      regionQuery.data.boundNorth
    ) {
      map.default?.fitBounds(
        [
          [
            Math.min(regionQuery.data.boundEast, regionQuery.data.boundWest),
            Math.min(regionQuery.data.boundSouth, regionQuery.data.boundNorth),
          ],
          [
            Math.max(regionQuery.data.boundEast, regionQuery.data.boundWest),
            Math.max(regionQuery.data.boundSouth, regionQuery.data.boundNorth),
          ],
        ],
        {
          padding: {
            top: 50,
            bottom: isMobile ? 150 : 50,
            left: isMobile ? 50 : 450,
            right: 50,
          },
          maxZoom: 13,
          speed: 2,
        },
      );
    }
  }, [regionQuery.isSuccess, regionQuery.data, map.default, isMobile]);

  return (
    <Sidebar
      header={
        <Header
          actions={[
            {
              icon: <FaTimes className="w-full h-full" />,
              text: t('region.actions.close'),
              onClick: () => navigate({ to: '/' }),
            },
          ]}
          title={
            <>
              {regionQuery.data?.imageUrl ? (
                <img
                  src={regionQuery.data?.imageUrl}
                  alt="Region"
                  className="max-h-12 max-w-12 shrink-0"
                />
              ) : (
                regionQuery.data?.isocode && (
                  <span
                    className={`fi fi-${regionQuery.data?.isocode.toLocaleLowerCase()} max-h-12 max-w-12`}
                  />
                )
              )}

              <span className="grow">
                <div className="flex flex-col items-start">
                  <span className="text-white text-base font-semibold">
                    {regionQuery.data?.name
                      ? regionQuery.data?.name
                      : t('region.loading')}
                  </span>
                  {regionQuery.data?.type && (
                    <span className="text-slate-300 text-sm">
                      {t(`region.types.${regionQuery.data.type}`)}
                    </span>
                  )}
                </div>
              </span>
            </>
          }
        />
      }
      body={
        <Body>
          {regionQuery.isLoading && <LoadingSpinner />}
          {regionQuery.isError && (
            <ErrorMessage
              error={regionQuery.error}
              retry={regionQuery.refetch}
            />
          )}
          {regionQuery.isSuccess && (
            <>
              <Helmet>
                <title>
                  {t('region.meta.title', { name: regionQuery.data.name })}
                </title>
                <meta
                  property="og:title"
                  content={`${regionQuery.data.name}`}
                />
                {regionQuery.data?.imageUrl && (
                  <meta
                    property="og:image"
                    content={regionQuery.data.imageUrl}
                  />
                )}
                <meta
                  property="og:url"
                  content={`https://www.summittale.com/region/${regionId}`}
                />
                <meta property="og:type" content="website" />
              </Helmet>

              <div className="flex flex-col mb-4 mx-4 mt-4">
                <Section
                  title={t('region.sections.features')}
                  icon={<FaMountain />}
                  first
                >
                  {featuresQuery.isLoading && <LoadingSpinner />}
                  {featuresQuery.isError && (
                    <ErrorMessage
                      error={featuresQuery.error}
                      retry={featuresQuery.refetch}
                    />
                  )}
                  {featuresQuery.isSuccess && (
                    <div className="flex flex-col gap-1">
                      {featuresQuery.data.length > 0 ? (
                        featuresQuery.data
                          .slice(0, 5)
                          .map((feature) => (
                            <FeatureListItem
                              key={feature.id}
                              feature={feature}
                            />
                          ))
                      ) : (
                        <div className="text-slate-300 text-sm">
                          {t('region.empty.features')}
                        </div>
                      )}
                    </div>
                  )}
                </Section>

                {regionQuery.data.parentRegion && (
                  <Section
                    title={t('region.sections.parentRegion')}
                    icon={<FaMap />}
                  >
                    <RegionText region={regionQuery.data.parentRegion} />
                  </Section>
                )}

                <Section
                  title={t('region.sections.childRegions')}
                  icon={<FaMap />}
                >
                  {regionQuery.data.childRegions &&
                  regionQuery.data.childRegions.length > 0 ? (
                    <div className="flex flex-col gap-1">
                      {regionQuery.data.childRegions
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((region) => (
                          <RegionText region={region} key={region.id} />
                        ))}
                    </div>
                  ) : (
                    <div className="text-slate-300 text-sm">
                      {t('region.empty.childRegions')}
                    </div>
                  )}
                </Section>
              </div>
            </>
          )}
        </Body>
      }
    />
  );
}
