import { StatusBadge } from '@/components/badge/statusBadge';
import { useNavigate, useParams } from '@tanstack/react-router';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  FaAndroid,
  FaApple,
  FaFileImport,
  FaHome,
  FaList,
  FaRunning,
  FaSync,
  FaTags,
  FaTimes,
} from 'react-icons/fa';
import {
  FaBars,
  FaBook,
  FaChartLine,
  FaClockRotateLeft,
  FaDatabase,
  FaGears,
  FaKey,
  FaLock,
  FaMap,
  FaMountain,
  FaPlug,
  FaShieldHalved,
  FaTriangleExclamation,
  FaUser,
  FaUsers,
} from 'react-icons/fa6';
import type { Components } from 'react-markdown';
import ReactMarkdown from 'react-markdown';

import { documentation, sections } from '@/data/faqData';

function getContentIcon(id: string) {
  const iconClass = 'w-5 h-5 text-sky-400';

  const icons: Record<string, React.JSX.Element> = {
    'concepts-peaks': <FaMountain className={iconClass} />,
    'concepts-prominence': <FaChartLine className={iconClass} />,
    'concepts-regions': <FaMap className={iconClass} />,
    'features-map': <FaMap className={iconClass} />,
    'features-statistics': <FaChartLine className={iconClass} />,
    'features-sharing': <FaUsers className={iconClass} />,
    'integrations-strava': <FaPlug className={iconClass} />,
    'integrations-garmin': <FaGears className={iconClass} />,
    'integrations-sync': <FaSync className={iconClass} />,
    'privacy-data-collection': <FaDatabase className={iconClass} />,
    'privacy-data-storage': <FaLock className={iconClass} />,
    'privacy-third-parties': <FaShieldHalved className={iconClass} />,
    'account-login': <FaKey className={iconClass} />,
    'account-data': <FaUser className={iconClass} />,
    'account-delete': <FaTriangleExclamation className={iconClass} />,
    'trouble-sync': <FaSync className={iconClass} />,
    'trouble-missing-peaks': <FaMountain className={iconClass} />,
    'trouble-incorrect-data': <FaTriangleExclamation className={iconClass} />,
    'changelog-2025-02': <FaClockRotateLeft className={iconClass} />,
    'roadmap-status': <FaList className={iconClass} />,
    'roadmap-prominence-calculation': <FaChartLine className={iconClass} />,
    'roadmap-automatic-osm-sync': <FaSync className={iconClass} />,
    'roadmap-activities': <FaRunning className={iconClass} />,
    'roadmap-manual-import': <FaFileImport className={iconClass} />,
    'roadmap-garmin': <FaGears className={iconClass} />,
    'roadmap-passkey-login': <FaKey className={iconClass} />,
    'roadmap-friends': <FaUsers className={iconClass} />,
    'roadmap-feature-tags': <FaTags className={iconClass} />,
    'roadmap-peak-suggestions': <FaMountain className={iconClass} />,
    'roadmap-smart-lists': <FaList className={iconClass} />,
    'roadmap-android-app': <FaAndroid className={iconClass} />,
    'roadmap-ios-app': <FaApple className={iconClass} />,
  };

  return icons[id] || <FaBook className={iconClass} />;
}

const MarkdownLink: Components['a'] = (props) => (
  <a
    {...props}
    className="text-sky-400 hover:text-sky-300 underline"
    target="_blank"
    rel="noopener noreferrer"
  />
);

function Welcome() {
  const navigate = useNavigate();
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-white">
        Welcome to Summit Tale Documentation
      </h2>
      <p className="text-slate-300 leading-relaxed">
        Learn about tracking your mountain adventures, understanding peak
        prominence, and managing your account. Choose a section from the sidebar
        to get started.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
        {Array.from(new Set(documentation.map((item) => item.section))).map(
          (section) => {
            const sectionDocs = documentation.filter(
              (item) => item.section === section,
            );
            const Icon = sections[section]?.icon;
            return (
              <button
                type="button"
                key={section}
                onClick={() =>
                  navigate({
                    to: '/docs/$section',
                    params: {
                      section: section.toLowerCase().replace(/ /g, '-'),
                    },
                  })
                }
                className="text-left group bg-slate-800/50 hover:bg-slate-700/50 rounded-lg p-6 transition-colors w-full"
              >
                <div className="flex items-start gap-4">
                  {Icon && (
                    <div className="p-3 bg-slate-700/50 group-hover:bg-slate-600/50 rounded-lg transition-colors">
                      <Icon className="w-6 h-6 text-sky-400" />
                    </div>
                  )}
                  <div className="flex-1 min-w-0">
                    <h3 className="text-lg font-medium text-white mb-2 flex items-center justify-between">
                      {section}
                      <span className="text-sm text-slate-400 font-normal">
                        {sectionDocs.length} article
                        {sectionDocs.length !== 1 ? 's' : ''}
                      </span>
                    </h3>
                    <div className="space-y-2">
                      {sectionDocs.slice(0, 2).map((doc) => (
                        <p
                          key={doc.id}
                          className="text-sm text-slate-400 truncate"
                        >
                          • {doc.title}
                        </p>
                      ))}
                      {sectionDocs.length > 2 && (
                        <p className="text-sm text-sky-400 group-hover:text-sky-300 transition-colors">
                          {sectionDocs.length - 2} more article
                          {sectionDocs.length - 2 !== 1 ? 's' : ''}...
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </button>
            );
          },
        )}
      </div>
    </div>
  );
}

export default function FAQ() {
  const navigate = useNavigate();
  const params = useParams({ strict: false });
  const section = params.section;
  const sectionsList = Array.from(
    new Set(documentation.map((item) => item.section)),
  );
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleSectionChange = (newSection: string) => {
    navigate({
      to: '/docs/$section',
      params: { section: newSection.toLowerCase().replace(/ /g, '-') },
    });
  };

  const currentSection = section
    ? sectionsList.find((s) => s.toLowerCase().replace(/ /g, '-') === section)
    : null;

  return (
    <div className="min-h-screen bg-slate-800 flex justify-center">
      <div className="flex w-full max-w-6xl bg-slate-700/50 relative flex-col md:flex-row">
        {/* Section Navigation - Mobile Overlay */}
        <div
          className={`${
            isMobileMenuOpen ? 'block' : 'hidden'
          } md:block fixed md:static inset-0 bg-slate-800 md:bg-transparent z-10 md:w-64 md:shrink-0`}
        >
          <div className="p-8 space-y-1 h-full overflow-y-auto">
            <button
              type="button"
              onClick={() => {
                navigate({ to: '/docs' });
                setIsMobileMenuOpen(false);
              }}
              className={`w-full text-left px-3 py-2 rounded-md transition-colors flex items-center gap-2
                ${!currentSection ? 'text-slate-100 bg-slate-700' : 'text-slate-400 hover:text-slate-200 hover:bg-slate-700/50'}`}
            >
              <FaHome className="w-4 h-4 shrink-0" />
              <span>Overview</span>
            </button>
            {sectionsList.map((section) => {
              const Icon = sections[section]?.icon;
              return (
                <button
                  type="button"
                  key={section}
                  onClick={() => {
                    handleSectionChange(section);
                    setIsMobileMenuOpen(false);
                  }}
                  className={`w-full text-left px-3 py-2 rounded-md transition-colors flex items-center gap-2 ${
                    currentSection === section
                      ? 'text-slate-100 bg-slate-700'
                      : 'text-slate-400 hover:text-slate-200 hover:bg-slate-700/50'
                  }`}
                >
                  {Icon && <Icon className="w-4 h-4 shrink-0" />}
                  <span>{section}</span>
                </button>
              );
            })}
          </div>
        </div>

        {/* Main content */}
        <div className="flex-1 overflow-y-auto border-l-0 md:border-l border-slate-600">
          <div className="max-w-3xl p-4 md:p-8">
            {/* Updated header layout */}
            <div className="flex items-center gap-4 mb-8">
              <button
                type="button"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="md:hidden p-2 text-slate-400 hover:text-white hover:bg-slate-800 rounded-lg"
              >
                <FaBars className="w-5 h-5" />
              </button>
              <h1 className="flex-1 text-2xl md:text-3xl font-bold text-white">
                Documentation
              </h1>
              <button
                type="button"
                onClick={() => navigate({ to: '/' })}
                className="p-2 text-slate-400 hover:text-white hover:bg-slate-800 rounded-lg transition-colors"
              >
                <FaTimes className="w-5 h-5" />
              </button>
            </div>
            <Helmet>
              <title>
                {currentSection
                  ? `${currentSection} | Documentation`
                  : 'Documentation'}{' '}
                | Summit Tale
              </title>
            </Helmet>

            {!currentSection ? (
              <Welcome />
            ) : (
              <div className="space-y-8 md:space-y-16">
                {documentation
                  .filter((item) => item.section === currentSection)
                  .map((item) => (
                    <div key={item.id} className="group">
                      <div className="flex items-start gap-4 flex-col md:flex-row">
                        <div className="p-2 bg-slate-700/50 rounded-lg">
                          {getContentIcon(item.id)}
                        </div>
                        <div className="flex-1 min-w-0">
                          <h3 className="text-lg md:text-xl font-semibold text-white mb-4 flex items-center gap-3">
                            {item.title}
                            {item.status && (
                              <StatusBadge status={item.status} />
                            )}
                          </h3>
                          <div className="prose prose-invert prose-sky max-w-none">
                            <ReactMarkdown
                              components={{
                                a: MarkdownLink,
                              }}
                              className="whitespace-pre-wrap"
                            >
                              {item.content}
                            </ReactMarkdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
