import type { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaSignature, FaUser, FaUserTag } from 'react-icons/fa';
import type { FormData } from './types';

interface ProfileTabProps {
  register: UseFormRegister<FormData>;
  errors: FieldErrors<FormData>;
}

export default function ProfileTab({ register, errors }: ProfileTabProps) {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      {/* Username Field */}
      <div className="space-y-1">
        <label
          htmlFor="username"
          className="block text-sm font-medium text-slate-300"
        >
          <div className="flex items-center gap-2 mb-1">
            <FaUserTag className="w-4 h-4 text-slate-400" />
            {t('profile.username')}
          </div>
        </label>
        <input
          id="username"
          type="text"
          {...register('username')}
          className="w-full bg-slate-700 border border-slate-600 rounded-lg px-3 py-2 text-white focus:outline-hidden focus:ring-2 focus:ring-sky-500 transition-shadow"
        />
        <p className="text-sm text-slate-400 mt-1">
          {t('profile.usernameDescription')}
        </p>
        {errors.username && (
          <p className="text-red-400 text-sm mt-1">{errors.username.message}</p>
        )}
      </div>

      {/* Name Fields Group */}
      <div className="space-y-2">
        <div className="grid grid-cols-2 gap-4">
          {/* First Name Field */}
          <div className="space-y-1">
            <label
              htmlFor="firstname"
              className="block text-sm font-medium text-slate-300"
            >
              <div className="flex items-center gap-2 mb-1">
                <FaUser className="w-4 h-4 text-slate-400" />
                {t('profile.firstname')}
              </div>
            </label>
            <input
              id="firstname"
              type="text"
              {...register('firstname')}
              className="w-full bg-slate-700 border border-slate-600 rounded-lg px-3 py-2 text-white focus:outline-hidden focus:ring-2 focus:ring-sky-500 transition-shadow"
            />
            {errors.firstname && (
              <p className="text-red-400 text-sm mt-1">
                {errors.firstname.message}
              </p>
            )}
          </div>

          {/* Last Name Field */}
          <div className="space-y-1">
            <label
              htmlFor="lastname"
              className="block text-sm font-medium text-slate-300"
            >
              <div className="flex items-center gap-2 mb-1">
                <FaSignature className="w-4 h-4 text-slate-400" />
                {t('profile.lastname')}
              </div>
            </label>
            <input
              id="lastname"
              type="text"
              {...register('lastname')}
              className="w-full bg-slate-700 border border-slate-600 rounded-lg px-3 py-2 text-white focus:outline-hidden focus:ring-2 focus:ring-sky-500 transition-shadow"
            />
            {errors.lastname && (
              <p className="text-red-400 text-sm mt-1">
                {errors.lastname.message}
              </p>
            )}
          </div>
        </div>
        <p className="text-sm text-slate-400">{t('profile.nameDescription')}</p>
      </div>
    </div>
  );
}
