import {
  type UseMutationResult,
  type UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import apiClient from '@/api/apiClient';
import type { Ascent } from '@/api/apiTypes';
import type { CreateAscentDTO, GetFeatureResponse } from '@/api/models';
import { queryClient } from '../queryClient';

export const useGetAscent = (ascentId: number): UseQueryResult<Ascent> =>
  useQuery({
    queryKey: ['ascent', ascentId],
    queryFn: () =>
      apiClient.get<Ascent>(`/ascents/${ascentId}`).then((res) => res.data),
  });

export const useCreateAscent = (): UseMutationResult<
  Ascent,
  unknown,
  CreateAscentDTO
> =>
  useMutation({
    mutationFn: (data) =>
      apiClient.post<Ascent>('/ascents', data).then((res) => res.data),
    onSuccess(data, _variables, _context) {
      queryClient.invalidateQueries({ queryKey: ['athleteAscents'] });
      queryClient.invalidateQueries({ queryKey: ['feature', data.featureId] });
      queryClient.invalidateQueries({ queryKey: ['list'] });
      queryClient.invalidateQueries({ queryKey: ['listLeaderboard'] });
      queryClient.invalidateQueries({ queryKey: ['lists'] });
    },
  });

export const useDeleteAscent = (): UseMutationResult<void, unknown, number> =>
  useMutation({
    mutationFn: (id) => apiClient.delete(`/ascents/${id}`),
    onSuccess(_data, deletedAscentId, _context) {
      const queries = queryClient.getQueriesData({ queryKey: ['feature'] });

      for (const [queryKey, queryData] of queries) {
        if (!queryData) continue;

        const oldFeature = queryData as GetFeatureResponse;
        const hasAscent = oldFeature.ascentIds?.some(
          (ascentId: number) => ascentId === deletedAscentId,
        );

        // Skip if feature doesn't contain the ascent
        if (!hasAscent) {
          continue;
        }

        // Create new feature object with filtered ascents
        const newFeature = {
          ...oldFeature,
          ascentIds: oldFeature.ascentIds?.filter(
            (ascentId: number) => ascentId !== deletedAscentId,
          ),
        };

        queryClient.setQueryData(queryKey, newFeature);
      }

      queryClient.invalidateQueries({ queryKey: ['list'] });
      queryClient.invalidateQueries({ queryKey: ['listLeaderboard'] });
      queryClient.invalidateQueries({ queryKey: ['lists'] });
    },
  });
