import { Link } from '@tanstack/react-router';
import clsx from 'clsx';
import {
  FaHouseChimney,
  FaLocationDot,
  FaMountain,
  FaSquareParking,
} from 'react-icons/fa6';
import { Marker } from 'react-map-gl/maplibre';

import { FeatureType } from '@/api/apiTypes';
import type { GetFeatureResponse } from '@/api/models';
import { useHoveredFeatureId } from '@/hooks/useHoveredFeature';
import { useTranslation } from 'react-i18next';

type FeatureMarkerProps = {
  feature: GetFeatureResponse;
  highestProminence?: number;
  lowestProminence?: number;
  highestElevation?: number;
  lowestElevation?: number;
  showName?: boolean;
};

const MIN_MARKER_SIZE = 15;
const MAX_MARKER_SIZE = 35;
const MAX_NAME_LENGTH = 30;

export default function FeatureMarker(props: FeatureMarkerProps) {
  const {
    feature,
    highestProminence,
    lowestProminence,
    highestElevation,
    lowestElevation,
  } = props;

  const { t } = useTranslation();

  const { hoveredFeatureId, setHoveredFeatureId } = useHoveredFeatureId();
  const hovered = hoveredFeatureId === feature.id;

  let markerSize = MIN_MARKER_SIZE;

  if (highestProminence && lowestProminence) {
    if (feature.prominence) {
      // Interpolate prominence between highest and lowest prominence
      markerSize =
        ((feature.prominence - lowestProminence) /
          (highestProminence - lowestProminence)) *
          (MAX_MARKER_SIZE - MIN_MARKER_SIZE) +
        MIN_MARKER_SIZE;
    } else {
      markerSize = MIN_MARKER_SIZE;
    }
  } else if (feature.elevation && highestElevation && lowestElevation) {
    // Interpolate elevation between highest and lowest elevation
    markerSize =
      ((feature.elevation - lowestElevation) /
        (highestElevation - lowestElevation)) *
        (MAX_MARKER_SIZE - MIN_MARKER_SIZE) +
      MIN_MARKER_SIZE;
  } else {
    markerSize = MIN_MARKER_SIZE;
  }

  markerSize = Math.max(MIN_MARKER_SIZE, Math.min(MAX_MARKER_SIZE, markerSize));

  if (hovered) {
    markerSize *= 1.5;
  }

  const markerProps = {
    className: clsx(
      'cursor-pointer block map-marker bg-white rounded-md shadow-md border-2 border-slate-600 p-0.5',
      feature.ascentIds && feature.ascentIds.length > 0
        ? 'text-green-500'
        : 'text-slate-600',
    ),
    onMouseEnter: () => {
      setHoveredFeatureId(feature.id);
    },
    onMouseLeave: () => {
      setHoveredFeatureId(null);
    },
  };

  const renderIcon = (featureType: FeatureType) => {
    switch (featureType) {
      case FeatureType.CABIN:
        return <FaHouseChimney size={markerSize} {...markerProps} />;
      case FeatureType.PARKING:
        return <FaSquareParking size={markerSize} {...markerProps} />;
      case FeatureType.PEAK:
        return <FaMountain size={markerSize} {...markerProps} />;
      default:
        return <FaLocationDot size={markerSize} {...markerProps} />;
    }
  };

  const truncateName = (name: string) => {
    if (name.length <= MAX_NAME_LENGTH) return name;

    // Try to truncate at a space or dash if possible
    const truncatePoint = name.substring(0, MAX_NAME_LENGTH).lastIndexOf(' ');
    if (truncatePoint > MAX_NAME_LENGTH * 0.7) {
      // Only use space if it's after 70% of max length
      return `${name.substring(0, truncatePoint)}...`;
    }

    // If no good breaking point, just truncate at max length
    return `${name.substring(0, MAX_NAME_LENGTH)}...`;
  };

  return (
    <Marker
      key={feature.id}
      longitude={feature.longitude}
      latitude={feature.latitude}
      anchor="center"
      // Increase z-index when hovered
      className={clsx(hovered && 'z-10')}
    >
      <Link
        to="/feature/$featureId"
        params={{ featureId: feature.id.toString() }}
        className="flex flex-col items-center"
      >
        {renderIcon(feature.type)}
        {props.showName && (
          <div
            className={clsx(
              'text-xs mt-0.5 px-0.5 rounded-md bg-white border-2 border-slate-600 shadow-md text-center font-bold whitespace-nowrap',
              feature.ascentIds && feature.ascentIds.length > 0
                ? 'text-green-600'
                : 'text-slate-600',
            )}
          >
            {truncateName(feature.name || t('feature.unnamedFeature'))}
          </div>
        )}
      </Link>
    </Marker>
  );
}
