import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';

type LoadingSpinnerProps = {
  direction?: 'vertical' | 'horizontal';
};

export default function LoadingSpinner({
  direction = 'vertical',
}: LoadingSpinnerProps) {
  const { t } = useTranslation();

  if (direction === 'vertical') {
    return (
      <div className="flex flex-col justify-center items-center h-full gap-4 p-4">
        <FaSpinner className="animate-spin h-full" />
        <span className="text-slate-100">{t('feature.loading')}</span>
      </div>
    );
  }

  return (
    <div className="flex flex-row justify-center items-center h-full gap-4 p-4">
      <FaSpinner className="animate-spin h-full" />
      <span className="text-slate-100">{t('feature.loading')}</span>
    </div>
  );
}
