import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import apiClient from '@/api/apiClient';
import type { GetDatasourceResponse } from '@/api/models';

export const useGetDatasource = (
  id: number | undefined,
): UseQueryResult<GetDatasourceResponse, AxiosError> =>
  useQuery({
    queryKey: ['datasource', id],
    queryFn: () =>
      apiClient
        .get<GetDatasourceResponse>(`/datasources/${id}`)
        .then((res) => res.data),
    enabled: id !== undefined,
  });
