interface BodyProps {
  children: React.ReactNode;
}

export default function Body({ children }: BodyProps) {
  return (
    <div className="flex flex-col grow overflow-hidden">
      <div className="grow overflow-y-auto">{children}</div>
    </div>
  );
}
