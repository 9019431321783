import { clsx } from 'clsx';
import type { ReactNode } from 'react';

export type BadgeStyle = 'blue' | 'slate' | 'amber' | 'red';

export interface BadgeProps {
  icon?: ReactNode;
  label?: string;
  children: ReactNode;
  variant?: BadgeStyle;
  className?: string;
  onClick?: () => void;
  iconClassName?: string;
}

const badgeStyles: Record<BadgeStyle, string> = {
  blue: 'bg-blue-900/30 text-blue-200 hover:bg-blue-900/40 border border-blue-900/50',
  slate:
    'bg-slate-700 text-slate-200 hover:bg-slate-600 border border-slate-600/50',
  amber:
    'bg-slate-700/80 text-amber-200 hover:bg-slate-600/90 border border-slate-600/50',
  red: 'bg-red-900/30 text-red-200 hover:bg-red-900/40 border border-red-900/50',
};

export default function Badge({
  icon,
  label,
  children,
  variant = 'slate',
  className,
  onClick,
  iconClassName,
}: BadgeProps) {
  return (
    <div
      className={clsx(
        'flex items-center gap-3 px-3 py-2 rounded-md border',
        badgeStyles[variant],
        onClick && 'cursor-pointer transition-colors duration-150',
        className,
      )}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      tabIndex={onClick ? 0 : undefined}
      onKeyDown={(e) => {
        if (onClick && (e.key === 'Enter' || e.key === ' ')) {
          e.preventDefault();
          onClick();
        }
      }}
    >
      {icon && (
        <div className={clsx('flex-shrink-0 text-slate-300', iconClassName)}>
          {icon}
        </div>
      )}
      <div className="flex flex-col gap-0.5">
        {label && <span className="text-xs text-slate-400/80">{label}</span>}
        <span className="text-xs truncate">{children}</span>
      </div>
    </div>
  );
}
