import ErrorFallback from '@/components/errorFallback';
import { Outlet } from '@tanstack/react-router';
import { ErrorBoundary } from 'react-error-boundary';

function Root() {
  return (
    <div className="h-full">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Outlet />
      </ErrorBoundary>
    </div>
  );
}

export default Root;
