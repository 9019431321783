import { Link } from '@tanstack/react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { Popup } from 'react-map-gl/maplibre';

interface ContextMenuProps {
  showContextMenu: boolean;
  setShowContextMenu: (show: boolean) => void;
  location: {
    latitude: number;
    longitude: number;
  };
}

export default function ContextMenu(props: ContextMenuProps) {
  const { showContextMenu, setShowContextMenu, location } = props;
  const { t } = useTranslation();

  if (!showContextMenu) {
    return null;
  }

  return (
    <Popup
      latitude={location.latitude}
      longitude={location.longitude}
      closeButton={false}
      closeOnClick={false}
      onClose={() => setShowContextMenu(false)}
      anchor="top-left"
      className="context-menu-popup"
    >
      <Link
        to="/feature/edit"
        params={{ latitude: location.latitude, longitude: location.longitude }}
        className={clsx(
          'bg-slate-700 text-slate-200 flex gap-2 px-4 py-2 text-sm hover:bg-slate-600 transition duration-150 ease-in-out rounded-xs items-center',
        )}
      >
        <FaPlus />
        <span>{t('contextMenu.createFeature')}</span>
      </Link>
    </Popup>
  );
}
