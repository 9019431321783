import { useRouter, useSearch } from '@tanstack/react-router';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { AlertCircle, ChevronRight, Loader2 } from 'lucide-react';

import { type RegionDTO, RegionType } from '../../api/apiTypes';
import { useGetRegions, useUploadRegionImage } from '../../api/hooks/regions';
import AdminTable from './adminTable';

function isValidRegionType(value: RegionType | undefined): value is RegionType {
  return value !== undefined && Object.values(RegionType).includes(value);
}

function parseParentId(value: string | undefined): number | null {
  if (!value) return null;
  const parsed = Number(value);
  return Number.isInteger(parsed) ? parsed : null;
}

export default function Regions() {
  const router = useRouter();
  const search = useSearch({ from: '/admin/regions' });
  const regionType = search.type;
  const parentId = parseParentId(search.parentId);

  if (regionType && !isValidRegionType(regionType)) {
    throw new Error('Invalid region type');
  }

  if (parentId === null && search.parentId) {
    throw new Error('Invalid parent ID');
  }

  // If both unset, then fetch planets
  if (!regionType && !parentId) {
    router.navigate({
      to: '/admin/regions',
      search: { type: RegionType.PLANET, parentId: undefined },
    });
  }

  const regionsQuery = useGetRegions({
    regionType: regionType,
    parentRegionId: parentId || undefined,
    includeGeometry: false,
  });

  const columnHelper = createColumnHelper<RegionDTO>();

  const uploadRegionImage = useUploadRegionImage();

  const columns = [
    columnHelper.accessor('id', { header: 'ID' }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: (props) => (
        <div className="flex items-center">
          <span>{props.getValue()}</span>
          <button
            type="button"
            className="ml-3 p-1 rounded-xs flex items-center text-sm text-slate-600 hover:text-slate-400"
            onClick={() =>
              router.navigate({
                to: '/admin/regions',
                search: {
                  parentId: props.row.original.id.toString(),
                  type: undefined,
                },
              })
            }
          >
            <span>View Subregions</span>
            <ChevronRight className="w-4 h-4 ml-1" />
          </button>
        </div>
      ),
    }),
    columnHelper.accessor('description', { header: 'Description' }),
    columnHelper.accessor('type', {
      header: 'Type',
    }),
    columnHelper.accessor('isocode', {
      header: 'ISO Code',
      cell: (props) => {
        const isocode = props.getValue();
        return (
          <div className="flex items-center">
            {isocode && (
              <>
                <span className={`fi fi-${isocode.toLocaleLowerCase()}`} />
                <span className="ml-3">{isocode}</span>
              </>
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor('imageUrl', {
      header: 'Image',
      cell: (props) => (
        <div className="flex items-center gap-2 justify-between w-full">
          <div className="shrink-0">
            {props.getValue() && (
              <div className="h-8 overflow-hidden">
                <img
                  src={props.getValue()}
                  alt={props.row.original.name}
                  className="h-full object-cover"
                />
              </div>
            )}
          </div>
          <label className="cursor-pointer px-2 py-1 border rounded-xs hover:bg-slate-600 whitespace-nowrap shrink-0">
            Upload Image
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  if (file.size > 5 * 1024 * 1024) {
                    alert('File size must be less than 5MB');
                    return;
                  }
                  uploadRegionImage.mutate({ id: props.row.original.id, file });
                }
              }}
            />
          </label>
        </div>
      ),
    }),
  ];

  if (regionsQuery.isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
      </div>
    );
  }

  if (regionsQuery.isError) {
    return (
      <div className="flex items-center justify-center h-64 text-red-600">
        <AlertCircle className="w-6 h-6 mr-2" />
        <span>Failed to load regions: {regionsQuery.error.message}</span>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4">
        {parentId && (
          <button
            type="button"
            className="flex items-center text-slate-600 hover:text-slate-400"
            onClick={() =>
              router.navigate({
                to: '/admin/regions',
                search: { type: RegionType.PLANET, parentId: undefined },
              })
            }
          >
            <ChevronRight className="w-4 h-4 mr-2 rotate-180" />
            Back to Planets
          </button>
        )}
      </div>
      <AdminTable
        columns={columns as ColumnDef<RegionDTO>[]}
        data={(regionsQuery.data || []).sort((a, b) =>
          a.name.localeCompare(b.name),
        )}
      />
    </div>
  );
}
