import { useTranslation } from 'react-i18next';
import { FaQuestionCircle } from 'react-icons/fa';

interface MetricCardProps {
  icon: React.ReactNode;
  label: string;
  value?: number | string | null;
  unit?: string;
  formatter?: (value: number) => string;
  customValue?: React.ReactNode;
}

export function MetricCard({
  icon,
  label,
  value,
  unit,
  formatter,
  customValue,
}: MetricCardProps) {
  const { t } = useTranslation();

  const formattedValue =
    value !== undefined &&
    value !== null &&
    formatter &&
    typeof value === 'number'
      ? formatter(value)
      : value?.toLocaleString();

  return (
    <div className="flex flex-col items-center justify-center bg-slate-700 rounded-lg p-4 hover:bg-slate-600 transition-colors">
      <div className="flex items-center gap-2 text-slate-300 mb-1">
        {icon}
        <span>{label}</span>
      </div>
      {customValue ? (
        <div className="w-full flex justify-center">{customValue}</div>
      ) : value !== undefined && value !== null ? (
        <div className="flex items-baseline gap-1">
          <span className="text-2xl font-bold text-slate-100">
            {formattedValue}
          </span>
          {unit && <span className="text-slate-400">{unit}</span>}
        </div>
      ) : (
        <div className="flex items-center gap-1 text-slate-400">
          <FaQuestionCircle className="text-xs" />
          <span>{t('metrics.unknown')}</span>
        </div>
      )}
    </div>
  );
}
