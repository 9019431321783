import type React from 'react';
import { createContext, useContext, useState } from 'react';
import type { GetAllFeaturesQuery } from '../api/apiTypes';

interface QueryOption<T> {
  value: T;
  isAutomatic: boolean;
}

type FeatureQueryOptions = {
  [K in keyof GetAllFeaturesQuery]: QueryOption<
    NonNullable<GetAllFeaturesQuery[K]>
  >;
};

interface FeatureQueryContextType {
  queryOptions: Partial<FeatureQueryOptions>;
  setQueryOptions: (options: Partial<FeatureQueryOptions>) => void;
  updateQueryOptions: <K extends keyof GetAllFeaturesQuery>(
    key: K,
    value: NonNullable<GetAllFeaturesQuery[K]>,
    isAutomatic?: boolean,
  ) => void;
  clearFilter: (key: keyof GetAllFeaturesQuery) => void;
  getQueryForApi: () => GetAllFeaturesQuery;
}

const FeatureQueryContext = createContext<FeatureQueryContextType | undefined>(
  undefined,
);

export function FeatureQueryProvider({
  children,
}: { children: React.ReactNode }) {
  const [queryOptions, setQueryOptions] = useState<
    Partial<FeatureQueryOptions>
  >({});

  const updateQueryOptions = <K extends keyof GetAllFeaturesQuery>(
    key: K,
    value: NonNullable<GetAllFeaturesQuery[K]>,
    isAutomatic = false,
  ) => {
    setQueryOptions((prev) => ({
      ...prev,
      [key]: { value, isAutomatic },
    }));
  };

  const clearFilter = (key: keyof GetAllFeaturesQuery) => {
    setQueryOptions((prev) => {
      const next = { ...prev };
      delete next[key];
      return next;
    });
  };

  const getQueryForApi = (): Partial<GetAllFeaturesQuery> => {
    return Object.entries(queryOptions).reduce(
      (acc, [key, { value }]) => {
        if (value !== null) {
          return Object.assign(acc, { [key]: value });
        }
        return acc;
      },
      {} as Partial<GetAllFeaturesQuery>,
    );
  };

  return (
    <FeatureQueryContext.Provider
      value={{
        queryOptions,
        setQueryOptions,
        updateQueryOptions,
        clearFilter,
        getQueryForApi,
      }}
    >
      {children}
    </FeatureQueryContext.Provider>
  );
}

export function useFeatureQuery() {
  const context = useContext(FeatureQueryContext);
  if (context === undefined) {
    throw new Error(
      'useFeatureQuery must be used within a FeatureQueryProvider',
    );
  }
  return context;
}
