import { useDeleteAthlete } from '@/api/hooks/athletes';
import { Button } from '@/components/react-aria/Button';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FaExclamationTriangle, FaTrash } from 'react-icons/fa';

interface DangerZoneTabProps {
  athleteId: number;
}

export default function DangerZoneTab({ athleteId }: DangerZoneTabProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deleteAthlete = useDeleteAthlete();
  const [deleteConfirmation, setDeleteConfirmation] = useState('');

  const handleDeleteAccount = async () => {
    try {
      await deleteAthlete.mutateAsync(athleteId);
      toast.success(t('profile.deleteAccountSuccess'));
      navigate({ to: '/' });
    } catch (error) {
      console.error('Failed to delete account:', error);
      toast.error(t('profile.deleteAccountError'));
    }
  };

  const isDeleteConfirmed =
    deleteConfirmation.toLowerCase().trim() === 'delete my account';

  return (
    <div className="space-y-4">
      <h3 className="text-base font-semibold text-red-400 flex items-center gap-2 mb-4">
        <FaExclamationTriangle className="w-4 h-4" />
        {t('profile.dangerZone')}
      </h3>

      <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4">
        <h4 className="text-sm font-medium text-red-400 mb-2">
          {t('profile.deleteAccount')}
        </h4>
        <p className="text-sm text-red-300/80 mb-4">
          {t('profile.deleteAccountWarning')}
        </p>

        <div className="space-y-4">
          <div>
            <label
              htmlFor="deleteConfirmation"
              className="block text-sm text-red-300/80 mb-2"
            >
              {t('profile.deleteAccountConfirmPrompt')}
            </label>
            <input
              id="deleteConfirmation"
              type="text"
              value={deleteConfirmation}
              onChange={(e) => setDeleteConfirmation(e.target.value)}
              className="w-full bg-red-950/20 border border-red-500/20 rounded-lg px-3 py-2 text-red-100 placeholder-red-300/30 focus:outline-none focus:ring-2 focus:ring-red-500/30"
              placeholder={t('profile.deleteAccountConfirmPlaceholder')}
            />
          </div>

          <Button
            variant="destructive"
            className="w-full flex items-center justify-center gap-2 !bg-red-500/20 !text-red-400 hover:!bg-red-500/30 disabled:opacity-50 disabled:cursor-not-allowed"
            onPress={handleDeleteAccount}
            isDisabled={!isDeleteConfirmed || deleteAthlete.isPending}
          >
            {deleteAthlete.isPending ? (
              <>
                <div className="w-4 h-4 border-2 border-red-400/20 border-t-red-400 rounded-full animate-spin" />
                <span>{t('profile.deleting')}</span>
              </>
            ) : (
              <>
                <FaTrash className="w-4 h-4" />
                <span>{t('profile.deleteAccount')}</span>
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
