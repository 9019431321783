import { useGetList } from '@/api/hooks';
import ListItem from '@/components/sidebar/overview/listItem';

export default function ListListItem(props: { listId: number }) {
  const { listId } = props;
  const { data: list, status: listStatus } = useGetList(listId);

  if (listStatus !== 'success') {
    return null;
  }

  const progress = (list.userFeatureCount / list.featureCount) * 100;

  return <ListItem list={{ ...list, progress }} />;
}
