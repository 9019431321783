import { RegionType } from '../api/apiTypes';

const sortRegions = (
  a: {
    name: string;
    type: RegionType;
  },
  b: {
    name: string;
    type: RegionType;
  },
) => {
  // Define the order of region types
  const order = [
    RegionType.PLANET,
    RegionType.CONTINENT,
    RegionType.COUNTRY,
    RegionType.REGION,
    RegionType.COUNTY,
    RegionType.MUNICIPALITY,
  ];

  // Get the order index of each region type
  const orderA = order.indexOf(a.type);
  const orderB = order.indexOf(b.type);

  // First, sort by region type
  if (orderA < orderB) {
    return -1;
  }
  if (orderA > orderB) {
    return 1;
  }

  // If types are the same, sort alphabetically by name
  return a.name.localeCompare(b.name);
};

export default sortRegions;
