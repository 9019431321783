import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import apiClient from '../apiClient';
import type { SearchResults } from '../models';

export const useSearch = (
  query: string,
  types: string[] = ['feature'],
  bounds?: [[number, number], [number, number]],
): UseQueryResult<SearchResults, AxiosError> =>
  useQuery({
    queryKey: ['search', query, types, bounds],
    queryFn: async ({ signal }) => {
      const params = new URLSearchParams({
        q: query,
        types: types.join(','),
      });

      if (bounds) {
        params.append('bounds', JSON.stringify(bounds));
      }

      const res = await apiClient.get(`/search?${params.toString()}`, {
        signal,
      });
      return res.data;
    },
    enabled: query !== undefined && query !== '',
    staleTime: 0,
  });
