import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { CgEditBlackPoint } from 'react-icons/cg';
import { FaMountain, FaSave, FaTimes } from 'react-icons/fa';
import { FaArrowTrendUp, FaMapLocation } from 'react-icons/fa6';

import type { UpdateFeatureDTO } from '@/api/apiTypes';
import { useUpdateFeature } from '@/api/hooks';
import type { GetFeatureResponse } from '@/api/models';
import { featureTypeMap } from '@/utils/featureTypeMap';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../react-aria/Button';
import { ComboBox, ComboBoxItem } from '../react-aria/ComboBox';
import { NumberField } from '../react-aria/NumberField';
import { TextField } from '../react-aria/TextField';

interface EditFeatureModalProps {
  feature: GetFeatureResponse;
  onClose: () => void;
}

export default function EditFeatureModal({
  feature,
  onClose,
}: EditFeatureModalProps) {
  const { t } = useTranslation();
  const updateFeature = useUpdateFeature();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = useForm<UpdateFeatureDTO>({
    defaultValues: {
      id: feature.id,
      name: feature.name || undefined,
      type: feature.type,
      latitude: feature.latitude,
      longitude: feature.longitude,
      elevation: feature.elevation || undefined,
      prominence: feature.prominence || undefined,
      isolation: feature.isolation || undefined,
    },
  });

  const onSubmit = async (data: UpdateFeatureDTO) => {
    try {
      await updateFeature.mutateAsync(data);
      toast.success(t('feature.updateSuccess'));
      onClose();
    } catch (error) {
      console.error('Failed to update feature:', error);
      setError('root', {
        type: 'manual',
        message: t('feature.updateError'),
      });
    }
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && !isSubmitting) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose, isSubmitting]);

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-xs flex items-center justify-center p-4 z-50">
      <div className="bg-slate-800 rounded-lg shadow-xl w-full max-w-4xl">
        <div className="flex items-center justify-between p-4 border-b border-slate-700">
          <div className="flex items-center gap-2">
            <FaMountain className="w-6 h-6 text-slate-400" />
            <h2 className="text-xl font-semibold text-white">
              {t('feature.edit')}
            </h2>
          </div>
          <Button
            variant="icon"
            onPress={onClose}
            aria-label={t('common.close')}
          >
            <FaTimes className="w-5 h-5" />
          </Button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-6 space-y-6">
            <p className="text-slate-300 text-sm">
              {t('feature.editDescription')}
            </p>

            <div className="space-y-4">
              {/* Name field */}
              <div className="space-y-1">
                <label
                  htmlFor="feature-name"
                  className="block text-sm font-medium text-slate-300"
                >
                  {t('feature.fields.name')}
                </label>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="feature-name"
                      aria-label={t('feature.fields.name')}
                    />
                  )}
                />
              </div>

              {/* Type field */}
              <div className="space-y-1">
                <label
                  htmlFor="feature-type"
                  className="block text-sm font-medium text-slate-300"
                >
                  {t('feature.fields.type')}
                </label>
                <Controller
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <ComboBox
                      id="feature-type"
                      aria-label={t('feature.fields.type')}
                      selectedKey={field.value}
                      onSelectionChange={(selected) => field.onChange(selected)}
                      onBlur={field.onBlur}
                      items={Object.entries(featureTypeMap).map(
                        ([key, value]) => ({
                          key,
                          icon: value.icon,
                          textValue: t(value.translationKey),
                          colorText: value.colorText,
                        }),
                      )}
                    >
                      {(item) => (
                        <ComboBoxItem textValue={item.textValue} key={item.key}>
                          <span className={`${item.colorText}`}>
                            {item.icon}
                          </span>
                          {item.textValue}
                        </ComboBoxItem>
                      )}
                    </ComboBox>
                  )}
                />
              </div>

              {/* Location fields */}
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-1">
                  <label
                    htmlFor="feature-latitude"
                    className="block text-sm font-medium text-slate-300"
                  >
                    <div className="flex items-center gap-2">
                      <FaMapLocation className="w-4 h-4" />
                      {t('feature.fields.latitude')}
                    </div>
                  </label>
                  <Controller
                    control={control}
                    name="latitude"
                    render={({ field }) => (
                      <NumberField
                        {...field}
                        id="feature-latitude"
                        formatOptions={{
                          style: 'unit',
                          maximumFractionDigits: 6,
                          unit: 'degree',
                        }}
                        maxValue={90}
                        minValue={-90}
                        step={0.000001}
                        aria-label={t('feature.fields.latitude')}
                      />
                    )}
                  />
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="feature-longitude"
                    className="block text-sm font-medium text-slate-300"
                  >
                    <div className="flex items-center gap-2">
                      <FaMapLocation className="w-4 h-4" />
                      {t('feature.fields.longitude')}
                    </div>
                  </label>
                  <Controller
                    control={control}
                    name="longitude"
                    render={({ field }) => (
                      <NumberField
                        {...field}
                        id="feature-longitude"
                        formatOptions={{
                          style: 'unit',
                          maximumFractionDigits: 6,
                          unit: 'degree',
                        }}
                        maxValue={180}
                        minValue={-180}
                        step={0.000001}
                        aria-label={t('feature.fields.longitude')}
                      />
                    )}
                  />
                </div>
              </div>

              {/* Metrics fields */}
              <div className="space-y-4">
                <div className="space-y-1">
                  <label
                    htmlFor="feature-elevation"
                    className="block text-sm font-medium text-slate-300"
                  >
                    <div className="flex items-center gap-2">
                      <FaMountain className="w-4 h-4" />
                      {t('feature.fields.elevation')}
                    </div>
                  </label>
                  <Controller
                    control={control}
                    name="elevation"
                    render={({ field }) => (
                      <NumberField
                        {...field}
                        id="feature-elevation"
                        formatOptions={{
                          style: 'unit',
                          maximumFractionDigits: 1,
                          unit: 'meter',
                        }}
                        minValue={0}
                        aria-label={t('feature.fields.elevation')}
                      />
                    )}
                  />
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="feature-prominence"
                    className="block text-sm font-medium text-slate-300"
                  >
                    <div className="flex items-center gap-2">
                      <FaArrowTrendUp className="w-4 h-4" />
                      {t('feature.fields.prominence')}
                    </div>
                  </label>
                  <Controller
                    control={control}
                    name="prominence"
                    render={({ field }) => (
                      <NumberField
                        {...field}
                        id="feature-prominence"
                        formatOptions={{
                          style: 'unit',
                          maximumFractionDigits: 1,
                          unit: 'meter',
                        }}
                        minValue={0}
                        aria-label={t('feature.fields.prominence')}
                      />
                    )}
                  />
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="feature-isolation"
                    className="block text-sm font-medium text-slate-300"
                  >
                    <div className="flex items-center gap-2">
                      <CgEditBlackPoint className="w-4 h-4" />
                      {t('feature.fields.isolation')}
                    </div>
                  </label>
                  <Controller
                    control={control}
                    name="isolation"
                    render={({ field }) => (
                      <NumberField
                        {...field}
                        id="feature-isolation"
                        formatOptions={{
                          style: 'unit',
                          maximumFractionDigits: 1,
                          unit: 'meter',
                        }}
                        minValue={0}
                        aria-label={t('feature.fields.isolation')}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            {/* Error message */}
            {errors.root && (
              <div className="mt-4 p-4 bg-red-500/10 border border-red-500/20 rounded-lg">
                <p className="text-red-400 text-sm">{errors.root.message}</p>
              </div>
            )}
          </div>

          <div className="flex justify-end gap-3 p-4 border-t border-slate-700">
            <Button
              variant="secondary"
              onPress={onClose}
              isDisabled={isSubmitting}
            >
              {t('common.cancel')}
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="flex items-center gap-2"
              isDisabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <div className="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                  <span>{t('common.saving')}</span>
                </>
              ) : (
                <>
                  <FaSave className="w-4 h-4" />
                  <span>{t('common.save')}</span>
                </>
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
