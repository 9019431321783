import { type RegionDTO, RegionType } from '@/api/apiTypes';
import { useGetRegionsById } from '@/api/hooks';
import LoadingSpinner from '@/components/loadingSpinner';
import { Link } from '@tanstack/react-router';
import React, { type ReactNode } from 'react';

function RegionItem(props: { region: RegionDTO }) {
  const { region } = props;

  return (
    <Link to="/region/$regionId" params={{ regionId: region.id.toString() }}>
      <div
        className="text-sm text-slate-100 flex items-center gap-2 bg-slate-700 p-2 rounded-md hover:bg-slate-600 cursor-pointer"
        key={region.id}
      >
        {((region.type === RegionType.COUNTRY && region.isocode) ||
          region.type !== RegionType.COUNTRY) && (
          <>
            {region.type === RegionType.COUNTRY && region.isocode && (
              <span
                className={`fi fi-${region.isocode.toLocaleLowerCase()} h-6 w-6`}
              />
            )}
            {region.type !== RegionType.COUNTRY && region.imageUrl && (
              <img className="h-6" alt={region.name} src={region.imageUrl} />
            )}
          </>
        )}
        {region.name}
      </div>
    </Link>
  );
}

export function Regions(props: { regions: number[] }) {
  const { regions } = props;

  const regionQueries = useGetRegionsById(regions);

  const isLoading = regionQueries.some((query) => query.isLoading);
  const hasError = regionQueries.some((query) => query.isError);
  const regionsData = regionQueries
    .map((query) => query.data)
    .filter((data) => data !== undefined) as RegionDTO[];

  if (isLoading) return <LoadingSpinner />;
  if (hasError) return null;

  const hasSiblings = (r: RegionDTO[], parentId?: number | null): boolean => {
    return (
      r.filter((region) => region.parentRegion?.id === parentId).length > 1
    );
  };

  const buildRegionHierarchy = (r: RegionDTO[]): RegionDTO[] => {
    // Create a map for easy access to each region by id
    const regionMap = new Map<number, RegionDTO>(
      r.map((region) => [region.id, { ...region, childRegions: [] }]),
    );

    // Populate childRegions and separate top-level regions
    const topLevelRegions: RegionDTO[] = [];
    for (const region of regionMap.values()) {
      if (!region.parentRegion?.id || !regionMap.has(region.parentRegion.id)) {
        topLevelRegions.push(region);
      } else {
        const parentRegion = regionMap.get(region.parentRegion.id);
        if (parentRegion) {
          parentRegion.childRegions.push(region);
        }
      }
    }

    if (topLevelRegions.length === 0) {
      // Look for a region with type CONTINENT in regionMap
      const continent = Array.from(regionMap.values()).find(
        (region) => region.type === RegionType.CONTINENT,
      );
      if (continent) {
        topLevelRegions.push(continent);
      } else {
        // Look for a region with type COUNTRY
        const country = Array.from(regionMap.values()).find(
          (region) => region.type === RegionType.COUNTRY,
        );
        if (country) {
          topLevelRegions.push(country);
        }
      }
    }

    return topLevelRegions;
  };

  const renderRegions = (r: RegionDTO[], depth = 0): ReactNode => {
    // Check if this is a single chain (no siblings at any level)
    const isSingleChain =
      r.length === 1 && !hasSiblings(regionsData, r[0].parentRegion?.id);

    if (isSingleChain) {
      const regionChain: RegionDTO[] = [];
      let currentRegion: RegionDTO | undefined = r[0];
      while (currentRegion) {
        regionChain.push(currentRegion);
        currentRegion = currentRegion.childRegions[0];
      }

      return (
        <div className="flex flex-row items-center gap-2 w-full flex-wrap justify-center">
          {regionChain.map((region, index) => (
            <React.Fragment key={region.id}>
              <RegionItem region={region} />
              {index < regionChain.length - 1 && (
                <span className="text-slate-400">→</span>
              )}
            </React.Fragment>
          ))}
        </div>
      );
    }

    // Original hierarchical rendering for branching structures
    return r.map((region) => (
      <React.Fragment key={region.id}>
        <div className="flex flex-row items-center gap-2 w-full">
          {depth > 0 && (
            <hr
              className="border-slate-400"
              style={{
                marginLeft: `${depth * 15}px`,
                width: `${depth * 15}px`,
              }}
            />
          )}
          <RegionItem region={region} />
        </div>
        {region.childRegions?.length > 0 &&
          renderRegions(region.childRegions, depth + 1)}
      </React.Fragment>
    ));
  };

  const hierarchicalRegions = buildRegionHierarchy(regionsData);

  return (
    <div className="flex flex-col gap-2">
      {renderRegions(hierarchicalRegions)}
    </div>
  );
}
