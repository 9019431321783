import type { LatLngTuple } from '@googlemaps/polyline-codec';
import { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl/maplibre';

import type { Activity } from '@/api/apiTypes';
import type { GetFeatureResponse } from '@/api/models';
import { decodePolyline } from '@/utils/geoUtils';
import FeatureMarker from './featureMarker';

type FeaturesProps = {
  features: GetFeatureResponse[];
  activities: Activity[];
};

export default function Features(props: FeaturesProps) {
  const { features, activities } = props;

  // Calculate highest and lowest prominence
  let highestProminence: number | undefined = useMemo(
    () => Math.max(...features.map((feature) => feature.prominence || 0)),
    [features],
  );

  if (highestProminence === 0) {
    highestProminence = undefined;
  }

  let lowestProminence: number | undefined = useMemo(
    () =>
      Math.min(
        ...features
          .filter((feature) => feature.prominence)
          .map((feature) => feature.prominence || 0),
      ),
    [features],
  );

  if (lowestProminence === 0) {
    lowestProminence = undefined;
  }

  if (
    highestProminence &&
    lowestProminence &&
    highestProminence === lowestProminence
  ) {
    lowestProminence = highestProminence - 1;
  }

  // Calculate highest and lowest elevation
  let highestElevation: number | undefined = useMemo(
    () => Math.max(...features.map((feature) => feature.elevation || 0)),
    [features],
  );

  if (highestElevation === 0) {
    highestElevation = undefined;
  }

  let lowestElevation: number | undefined = useMemo(
    () =>
      Math.min(
        ...features
          .filter((feature) => feature.elevation)
          .map((feature) => feature.elevation || 0),
      ),
    [features],
  );

  if (lowestElevation === 0) {
    lowestElevation = undefined;
  }

  if (
    highestElevation &&
    lowestElevation &&
    highestElevation === lowestElevation
  ) {
    lowestElevation = highestElevation - 1;
  }

  const markers = useMemo(
    () =>
      features
        .sort((a, b) => (a.elevation || 0) - (b.elevation || 0))
        .sort((a, b) => (a.prominence || 0) - (b.prominence || 0))
        .map((feature, index) => (
          <FeatureMarker
            feature={feature}
            key={feature.id}
            highestProminence={highestProminence}
            lowestProminence={lowestProminence}
            highestElevation={highestElevation}
            lowestElevation={lowestElevation}
            showName={index >= features.length - 7}
          />
        )),
    [
      features,
      highestElevation,
      lowestElevation,
      highestProminence,
      lowestProminence,
    ],
  );

  const activitiesGeoJSON = useMemo(
    () => ({
      type: 'FeatureCollection' as const,
      features: activities.map((activity) => {
        let coordinates: LatLngTuple[] = [];
        if (activity.mapPoyline) {
          coordinates = decodePolyline(activity.mapPoyline);
        } else if (activity.mapSummaryPolyline) {
          coordinates = decodePolyline(activity.mapSummaryPolyline);
        }

        const geometry = {
          type: 'LineString' as const,
          coordinates: coordinates.map(([lat, lng]) => [lng, lat]),
        };

        return {
          type: 'Feature' as const,
          geometry,
          properties: {
            name: activity.name,
            type: activity.sportType,
            id: activity.id,
          },
        };
      }),
    }),
    [activities],
  );

  return (
    <>
      <Source id="activities" type="geojson" data={activitiesGeoJSON}>
        <Layer
          id="activities"
          type="line"
          paint={{
            'line-color': '#dc2626',
            'line-width': 2,
          }}
        />
        <Layer
          beforeId="activities"
          id="activities-outline"
          type="line"
          paint={{
            'line-color': '#FFF',
            'line-width': 3,
          }}
        />
      </Source>
      {markers}
    </>
  );
}
