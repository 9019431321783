import { useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { FaCircleExclamation, FaLock, FaMap } from 'react-icons/fa6';

import { PrivacyLevel, RegionType } from '@/api/apiTypes';
import { useGetAthlete, useGetRegion } from '@/api/hooks';
import type { GetListResponse } from '@/api/models';
import Badge from '@/components/shared/badge';
import { FaUserCircle } from 'react-icons/fa';

export const CreatorBadge = ({ list }: { list: GetListResponse }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (list.createdBySummitTale) {
    return (
      <Badge
        icon={<img src="/logo.png" alt="Summit Tale" className="h-4 w-4" />}
        variant="blue"
      >
        {t('list.creator.summittale')}
      </Badge>
    );
  }

  if (list.createdById) {
    return (
      <Badge
        icon={(() => {
          const { data: creator } = useGetAthlete(
            list.createdById || undefined,
          );
          return creator?.profilePhotoUrl ? (
            <img
              src={creator.profilePhotoUrl}
              alt=""
              className="h-4 w-4 rounded-full object-cover"
            />
          ) : (
            <FaUserCircle className="h-4 w-4" />
          );
        })()}
        variant="slate"
        onClick={() => {
          if (list.createdById) {
            navigate({
              to: '/athlete/$athleteId',
              params: { athleteId: list.createdById.toString() },
            });
          }
        }}
        label={t('list.creator.label')}
      >
        {(() => {
          const { data: creator } = useGetAthlete(
            list.createdById || undefined,
          );
          return (
            creator?.username ||
            creator?.firstname ||
            t('list.creator.unknownUser')
          );
        })()}
      </Badge>
    );
  }

  return (
    <Badge icon={<FaCircleExclamation className="h-4 w-4" />} variant="amber">
      {t('list.creator.orphaned')}
    </Badge>
  );
};

export const PrivacyBadge = ({ privacy }: { privacy: PrivacyLevel }) => {
  const { t } = useTranslation();

  if (privacy === PrivacyLevel.PUBLIC) {
    return null;
  }

  const privacyVariants = {
    [PrivacyLevel.PRIVATE]: 'red',
    [PrivacyLevel.FRIENDS]: 'blue',
    [PrivacyLevel.USERS]: 'amber',
    [PrivacyLevel.PUBLIC]: 'slate',
  } as const;

  return (
    <Badge
      icon={<FaLock className="h-4 w-4" />}
      variant={privacyVariants[privacy]}
      label={t('privacy.label')}
    >
      {t(`privacy.short.${privacy.toLowerCase()}`)}
    </Badge>
  );
};

export const RegionBadge = ({ list }: { list: GetListResponse }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: region } = useGetRegion(list.regionId || undefined);

  if (!region) {
    return null;
  }

  return (
    <Badge
      icon={
        region.type === RegionType.COUNTRY && region.isocode ? (
          <span
            className={`fi fi-${region.isocode.toLocaleLowerCase()} h-4 w-4`}
          />
        ) : region.imageUrl ? (
          <img
            src={region.imageUrl}
            alt={region.name}
            className="h-4 w-4 object-cover"
          />
        ) : (
          <FaMap className="h-4 w-4" />
        )
      }
      variant="slate"
      onClick={() =>
        navigate({
          to: '/region/$regionId',
          params: { regionId: region.id.toString() },
        })
      }
      label={t('list.region')}
    >
      {region.name}
    </Badge>
  );
};
