import 'flag-icons/css/flag-icons.min.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import './i18n/i18n';
import './index.css';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';

import APIProvider from '@/api/apiProvider';
import { queryClient } from '@/api/queryClient';
import ErrorFallback from '@/components/errorFallback';
import { FeatureQueryProvider } from '@/contexts/featureQueryContext';
import { router } from './router';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <Helmet>
          <title>Summit Tale</title>
        </Helmet>
        <APIProvider>
          <FeatureQueryProvider>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
              {import.meta.env.DEV && (
                <TanStackRouterDevtools router={router} />
              )}
              <ReactQueryDevtools initialIsOpen={false} />
              <Toaster
                position="bottom-center"
                toastOptions={{
                  duration: 5000,
                  style: {
                    background: 'rgb(30 41 59)', // slate-800
                    color: 'rgb(226 232 240)', // slate-200
                    border: '1px solid rgb(51 65 85)', // slate-700
                    borderRadius: '0.5rem',
                  },
                  success: {
                    iconTheme: {
                      primary: 'rgb(34 197 94)', // green-500
                      secondary: 'rgb(30 41 59)', // slate-800
                    },
                  },
                  error: {
                    iconTheme: {
                      primary: 'rgb(239 68 68)', // red-500
                      secondary: 'rgb(30 41 59)', // slate-800
                    },
                  },
                }}
              />
            </QueryClientProvider>
          </FeatureQueryProvider>
        </APIProvider>
      </HelmetProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
