import { useGetFeature } from '@/api/hooks';
import { useHoveredFeatureId } from '@/hooks/useHoveredFeature';
import { featureTypeMap } from '@/utils/featureTypeMap';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

export const FeatureTooltip = () => {
  const { t } = useTranslation();
  const { hoveredFeatureId } = useHoveredFeatureId();
  const { data: feature, status } = useGetFeature(
    hoveredFeatureId || undefined,
  );

  return (
    <Tooltip
      anchorSelect=".map-marker"
      className="bg-slate-900/90 rounded-lg font-medium"
      disableStyleInjection
    >
      {hoveredFeatureId && (
        <>
          {status === 'pending' && (
            <div className="p-3">{t('featureTooltip.loading')}</div>
          )}
          {status === 'error' && (
            <div className="p-3">{t('featureTooltip.error')}</div>
          )}
          {status === 'success' && (
            <div className="flex flex-row justify-center items-stretch gap-3">
              {feature.photoUrl ? (
                <img
                  src={feature.photoUrl}
                  alt={feature.name || t('featureTooltip.unnamedFeature')}
                  className="max-w-32 h-auto object-cover rounded-l-lg"
                />
              ) : (
                <div className="w-16 rounded-l-lg flex items-center justify-center p-3 bg-slate-600/50">
                  <div className="w-full h-full flex items-center justify-center text-slate-300">
                    {featureTypeMap[feature.type].icon}
                  </div>
                </div>
              )}
              <div className="py-3 pr-4 pl-1">
                <div className="flex justify-between text-base font-semibold">
                  <div className="whitespace-nowrap text-slate-100">
                    {feature.name || t('featureTooltip.unnamedFeature')}
                  </div>
                  {feature.elevation && (
                    <div className="ml-3">
                      <span className="text-slate-200">
                        {feature.elevation}
                      </span>
                      <span className="text-sm text-slate-400 ml-0.5">m</span>
                    </div>
                  )}
                </div>
                {(feature.prominence || feature.isolation) && (
                  <div className="text-sm text-slate-300 space-y-0.5 mt-1">
                    {feature.prominence && (
                      <div className="whitespace-nowrap">
                        {t('featureTooltip.metrics.prominence', {
                          value: feature.prominence,
                        })}
                      </div>
                    )}
                    {feature.isolation && (
                      <div>
                        {t('featureTooltip.metrics.isolation', {
                          value: feature.isolation,
                        })}
                      </div>
                    )}
                  </div>
                )}
                {/* TODO: Implement ascents
                {feature.ascents && feature.ascents.length > 0 && (
                  <div className="text-green-400 text-sm flex items-center gap-1.5 mt-1.5 font-medium">
                    <FaCheck size={12} />
                    {t('featureTooltip.ascents', {
                      count: feature.ascents.length,
                    })}
                  </div>
                )}*/}
              </div>
            </div>
          )}
        </>
      )}
    </Tooltip>
  );
};
