import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { APIContext } from '@/api/apiProvider';
import { FeatureType } from '@/api/apiTypes';
import { useGetSelf } from '@/api/hooks';
import { EditProfileModal } from '@/components/modals';
import { useContext, useState } from 'react';
import { FaCog, FaUserCircle } from 'react-icons/fa';
import logo from '../../assets/logo.png';
import DocsButton from '../docsButton';
import { LanguageSwitcher } from '../languageSwitcher';
import LoadingSpinner from '../loadingSpinner';
import Search from '../search';

function Logo() {
  return (
    <Link to="/" className="items-center hidden md:flex shrink-0">
      <img src={logo} alt="Logo" className="h-8" />
      <span className="text-white text-2xl font-bold ml-2 hidden lg:inline-block">
        Summit Tale
      </span>
    </Link>
  );
}

function Profile() {
  const { t } = useTranslation();
  const { isSignedIn } = useContext(APIContext);
  const { data: athlete, status: getAthleteStatus } = useGetSelf();
  const [showEditProfile, setShowEditProfile] = useState(false);

  if (getAthleteStatus === 'pending') {
    return (
      <div className="h-10 px-4 flex items-center bg-slate-700/50 rounded-md">
        <LoadingSpinner direction="horizontal" />
      </div>
    );
  }

  if (getAthleteStatus === 'error' || !athlete || !isSignedIn) {
    return (
      <Link
        to="/login"
        className="h-10 px-4 flex items-center gap-2 bg-slate-700 hover:bg-slate-600 text-white rounded-md transition-colors"
      >
        <FaUserCircle className="w-6 h-6" />
        <span className="font-medium">{t('topbar.signIn')}</span>
      </Link>
    );
  }

  return (
    <>
      <div className="flex items-center gap-2">
        <Link
          to="/athlete/$athleteId"
          params={{ athleteId: athlete.id.toString() }}
          className="group flex items-center gap-3 px-3 py-2 hover:bg-slate-700/75 rounded-md transition-colors"
        >
          <div className="relative">
            {athlete.profilePhotoUrl ? (
              <img
                src={athlete.profilePhotoUrl}
                alt="Profile"
                className="h-8 w-8 rounded-full ring-2 ring-slate-600 group-hover:ring-slate-500/50 transition-all"
              />
            ) : (
              <FaUserCircle className="w-8 h-8 text-slate-400 group-hover:text-slate-400 transition-colors" />
            )}
          </div>
          <div className="hidden md:flex flex-col items-start">
            <span className="text-slate-200 text-sm font-mediumtransition-colors">
              {athlete.firstname && athlete.lastname
                ? `${athlete.firstname} ${athlete.lastname}`
                : athlete.username || athlete.email}
            </span>
            <div className="flex items-center gap-1.5 text-xs">
              <span className="font-semibold text-slate-200">
                {athlete.featureCount[FeatureType.PEAK] || 0}
              </span>
              <span className="text-slate-400">
                {t('topbar.features.count')}
              </span>
            </div>
          </div>
        </Link>
        <button
          type="button"
          onClick={() => setShowEditProfile(true)}
          className="p-2 hover:bg-slate-700/75 rounded-md transition-colors"
          title={t('topbar.settings')}
        >
          <FaCog className="w-5 h-5 text-slate-400 hover:text-slate-300" />
        </button>
      </div>

      {showEditProfile && (
        <EditProfileModal onClose={() => setShowEditProfile(false)} />
      )}
    </>
  );
}

export default function Topbar() {
  return (
    <div className="w-full h-16 p-2 bg-slate-800 flex flex-row items-center justify-between gap-2 md:gap-6 px-2 md:px-4 lg:px-8">
      <Logo />
      <div className="grow max-w-2xl">
        <Search />
      </div>
      <div className="flex flex-row items-center gap-2 shrink-0">
        <Profile />
        <DocsButton />
        <LanguageSwitcher />
      </div>
    </div>
  );
}
