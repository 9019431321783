import { PrivacyLevel } from '@/api/apiTypes';
import type {
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FaExclamationTriangle,
  FaMountain,
  FaUserCircle,
} from 'react-icons/fa';
import { FaCircleExclamation } from 'react-icons/fa6';
import type { FormData } from './types';

interface PrivacyTabProps {
  register: UseFormRegister<FormData>;
  errors: FieldErrors<FormData>;
  watch: UseFormWatch<FormData>;
}

export default function PrivacyTab({
  register,
  errors,
  watch,
}: PrivacyTabProps) {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <div className="space-y-1">
        <label
          htmlFor="profilePrivacy"
          className="block text-sm font-medium text-slate-300"
        >
          <div className="flex items-center gap-2 mb-1">
            <FaUserCircle className="w-4 h-4 text-slate-400" />
            {t('profile.profilePrivacy')}
          </div>
        </label>
        <select
          id="profilePrivacy"
          {...register('profilePrivacy')}
          className="w-full bg-slate-700 border border-slate-600 rounded-lg px-3 py-2 text-white focus:outline-hidden focus:ring-2 focus:ring-sky-500 transition-shadow"
        >
          <option value={PrivacyLevel.PUBLIC}>{t('privacy.public')}</option>
          <option value={PrivacyLevel.USERS}>{t('privacy.users')}</option>
          <option value={PrivacyLevel.FRIENDS}>{t('privacy.friends')}</option>
          <option value={PrivacyLevel.PRIVATE}>{t('privacy.private')}</option>
        </select>
      </div>

      {/* Ascent Privacy */}
      <div className="space-y-1">
        <label
          htmlFor="ascentPrivacy"
          className="block text-sm font-medium text-slate-300"
        >
          <div className="flex items-center gap-2 mb-1">
            <FaMountain className="w-4 h-4 text-slate-400" />
            {t('profile.ascentPrivacy')}
          </div>
        </label>
        <select
          id="ascentPrivacy"
          {...register('ascentPrivacy')}
          className="w-full bg-slate-700 border border-slate-600 rounded-lg px-3 py-2 text-white focus:outline-hidden focus:ring-2 focus:ring-sky-500 transition-shadow"
        >
          <option value={PrivacyLevel.PUBLIC}>{t('privacy.public')}</option>
          <option value={PrivacyLevel.USERS}>{t('privacy.users')}</option>
          <option value={PrivacyLevel.FRIENDS}>{t('privacy.friends')}</option>
          <option value={PrivacyLevel.PRIVATE}>{t('privacy.private')}</option>
        </select>
        {errors.ascentPrivacy && (
          <p className="text-red-400 text-sm mt-2 flex items-center gap-2 px-2 py-1">
            <FaCircleExclamation className="w-3.5 h-3.5 shrink-0" />
            <span>{errors.ascentPrivacy.message}</span>
          </p>
        )}
        {/* Add warning for leaderboard participation */}
        {![PrivacyLevel.PUBLIC, PrivacyLevel.USERS].includes(
          watch('ascentPrivacy'),
        ) && (
          <p className="text-yellow-400 text-sm mt-2 flex items-center gap-2 px-2 py-1">
            <FaExclamationTriangle className="w-3.5 h-3.5 shrink-0" />
            <span>{t('profile.leaderboardWarning')}</span>
          </p>
        )}
      </div>
    </div>
  );
}
