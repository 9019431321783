import { useNavigate, useParams } from '@tanstack/react-router';
import { Helmet } from 'react-helmet-async';
import { FaTimes, FaUserCircle } from 'react-icons/fa';
import {
  FaArrowRotateRight,
  FaArrowTrendUp,
  FaClock,
  FaCrown,
  FaDoorClosed,
  FaMountain,
  FaPencil,
} from 'react-icons/fa6';

import { APIContext } from '@/api/apiProvider';
import { FeatureType } from '@/api/apiTypes';
import {
  useGetAthlete,
  useGetAthleteAscents,
  useLogout,
  useRefreshAthlete,
} from '@/api/hooks';
import ErrorMessage from '@/components/errorMessage';
import { EditProfileModal } from '@/components/modals';
import { Button } from '@/components/react-aria/Button';
import { MetricCard } from '@/components/shared/metricCard';
import Section from '@/components/shared/section';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl/maplibre';
import LoadingSpinner from '../../loadingSpinner';
import Body from '../body';
import Header from '../header';
import AscentListItem from '../listitems/ascentListItem';
import { Activities } from '../shared/activities';
import Sidebar from '../sidebar';

interface AscentsProps {
  athleteId: number;
}

function Ascents({ athleteId }: AscentsProps) {
  const { t } = useTranslation();

  const { currentUserId } = useContext(APIContext);

  const map = useMap();

  const {
    data: ascents,
    status,
    error,
    refetch,
  } = useGetAthleteAscents(athleteId);

  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if (status === 'success') {
      const boundNorth = Math.max(
        ...ascents.map((ascent) => ascent.feature?.latitude || 0),
      );
      const boundSouth = Math.min(
        ...ascents.map((ascent) => ascent.feature?.latitude || 0),
      );
      const boundEast = Math.max(
        ...ascents.map((ascent) => ascent.feature?.longitude || 0),
      );
      const boundWest = Math.min(
        ...ascents.map((ascent) => ascent.feature?.longitude || 0),
      );

      if (map.default && ascents.length > 0) {
        map.default?.fitBounds(
          [
            [Math.min(boundEast, boundWest), Math.min(boundSouth, boundNorth)],
            [Math.max(boundEast, boundWest), Math.max(boundSouth, boundNorth)],
          ],
          {
            padding: {
              top: 50,
              bottom: isMobile ? 150 : 50,
              left: isMobile ? 50 : 450,
              right: 50,
            },
            maxZoom: 13,
            speed: 2,
          },
        );
      }
    }
  }, [status, ascents, map.default, isMobile]);

  if (status === 'pending') {
    return <LoadingSpinner />;
  }

  if (status === 'error') {
    return <ErrorMessage error={error as Error} retry={refetch} />;
  }

  if (status === 'success' && (!ascents || ascents.length === 0)) {
    if (currentUserId === athleteId) {
      return (
        <div className="flex flex-col items-center justify-center gap-4 p-6 text-center">
          <FaMountain className="text-4xl text-slate-500" />
          <div className="text-slate-300">
            {t('athlete.noAscentsLogged.self')}
          </div>
        </div>
      );
    }
    return (
      <div className="flex flex-col items-center justify-center gap-4 p-6 text-center">
        <FaMountain className="text-4xl text-slate-500" />
        <div className="text-slate-300">
          {t('athlete.noAscentsLogged.other')}
        </div>
      </div>
    );
  }

  const recentAscents = ascents
    .filter((ascent) => ascent.date && !ascent.activityId)
    .sort((a, b) => (b.date || '').localeCompare(a.date || ''))
    .slice(0, 5);
  return (
    <div className="flex flex-col">
      {ascents.length > 0 && (
        <>
          <Section
            title={t('athlete.stats.mostProminent')}
            icon={<FaArrowTrendUp />}
          >
            <div className="flex flex-col gap-1">
              {ascents
                .filter((ascent) => ascent.feature?.prominence)
                .sort(
                  (a, b) =>
                    (b.feature?.prominence || 0) - (a.feature?.prominence || 0),
                )
                .slice(0, 5)
                .map((ascent) => (
                  <AscentListItem ascent={ascent} key={ascent.id} />
                ))}
            </div>
          </Section>

          {recentAscents.length > 0 && (
            <Section
              title={t('athlete.stats.recentAscents')}
              icon={<FaClock />}
            >
              <div className="flex flex-col gap-1">
                {recentAscents.map((ascent) => (
                  <AscentListItem ascent={ascent} key={ascent.id} />
                ))}
              </div>
            </Section>
          )}
        </>
      )}
    </div>
  );
}

export default function AthleteView() {
  const params = useParams({ from: '/athlete/$athleteId' });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const athleteId = Number(params.athleteId);
  const athleteQuery = useGetAthlete(athleteId);
  const { data: athleteAscents } = useGetAthleteAscents(athleteId);
  const { currentUserId } = useContext(APIContext);
  const [showEditProfile, setShowEditProfile] = useState(false);

  const logOut = useLogout();
  const refreshAthlete = useRefreshAthlete();

  const stats = useMemo(() => {
    if (!athleteAscents) return null;
    return {
      totalAscents: athleteAscents.filter(
        (a) => a.feature.type === FeatureType.PEAK,
      ).length,
      uniqueAscents: new Set(
        athleteAscents
          .filter((a) => a.feature.type === FeatureType.PEAK)
          .map((a) => a.featureId),
      ).size,
      totalElevation: athleteAscents.reduce(
        (acc, a) => acc + (a.feature?.elevation ?? 0),
        0,
      ),
      highestAscent: Math.max(
        ...athleteAscents.map((a) => a.feature?.elevation ?? 0),
      ),
    };
  }, [athleteAscents]);

  const needsProfileCompletion = useMemo(() => {
    if (!athleteQuery.data) return false;
    return (
      currentUserId === athleteId &&
      !athleteQuery.data.username &&
      (!athleteQuery.data.firstname || !athleteQuery.data.lastname)
    );
  }, [athleteQuery.data, currentUserId, athleteId]);

  return (
    <Sidebar
      header={
        <Header
          actions={[
            ...(currentUserId === athleteId
              ? [
                  {
                    icon: <FaPencil className="w-full h-full" />,
                    text: t('athlete.actions.editProfile'),
                    onClick: () => {
                      setShowEditProfile(true);
                    },
                  },
                  {
                    icon: <FaDoorClosed className="w-full h-full" />,
                    text: t('athlete.actions.signOut'),
                    onClick: () => {
                      logOut.mutate();
                      navigate({ to: '/' });
                    },
                  },
                  {
                    icon: <FaArrowRotateRight className="w-full h-full" />,
                    text: t('athlete.actions.refresh'),
                    onClick: () => {
                      refreshAthlete.mutate(athleteId);
                    },
                  },
                ]
              : []),
            {
              icon: <FaTimes className="w-full h-full" />,
              text: t('athlete.actions.close'),
              onClick: () => navigate({ to: '/' }),
            },
          ]}
          title={
            <>
              {athleteQuery.data?.profilePhotoUrl ? (
                <img
                  src={athleteQuery.data.profilePhotoUrl}
                  alt="Profile"
                  className="h-12 w-12 rounded-full shrink-0 object-cover"
                />
              ) : (
                <FaUserCircle className="h-12 w-12 rounded-full text-slate-400 shrink-0" />
              )}
              <span className="grow">
                <div className="flex flex-col items-start">
                  <span className="text-white text-base font-semibold">
                    {athleteQuery.data ? (
                      <>
                        {athleteQuery.data.username ? (
                          <div className="flex flex-col">
                            <span className="text-lg font-bold">
                              {athleteQuery.data.username}
                            </span>
                            {athleteQuery.data.firstname && (
                              <span className="text-sm text-slate-300">
                                {athleteQuery.data.lastname
                                  ? `${athleteQuery.data.firstname} ${athleteQuery.data.lastname}`
                                  : athleteQuery.data.firstname}
                              </span>
                            )}
                          </div>
                        ) : (
                          <>
                            {athleteQuery.data?.firstname &&
                            athleteQuery.data?.lastname
                              ? `${athleteQuery.data?.firstname} ${athleteQuery.data?.lastname}`
                              : athleteQuery.data?.firstname ||
                                t('athlete.unnamedUser')}
                          </>
                        )}
                      </>
                    ) : (
                      'Loading...'
                    )}
                  </span>
                </div>
              </span>
            </>
          }
        />
      }
      body={
        <Body>
          {athleteQuery.isLoading && <LoadingSpinner />}
          {athleteQuery.isError && (
            <ErrorMessage
              error={athleteQuery.error}
              retry={athleteQuery.refetch}
            />
          )}
          {athleteQuery.isSuccess && (
            <>
              <Helmet>
                <title>
                  {t('athlete.meta.title', {
                    name: athleteQuery.data?.username
                      ? `${athleteQuery.data.username} ${
                          athleteQuery.data.firstname
                            ? `(${
                                athleteQuery.data.lastname
                                  ? `${athleteQuery.data.firstname} ${athleteQuery.data.lastname}`
                                  : athleteQuery.data.firstname
                              })`
                            : ''
                        }`
                      : athleteQuery.data?.firstname &&
                          athleteQuery.data?.lastname
                        ? `${athleteQuery.data?.firstname} ${athleteQuery.data?.lastname}`
                        : athleteQuery.data?.firstname ||
                          t('athlete.unnamedUser'),
                  })}
                </title>
                <meta
                  property="og:title"
                  content={
                    athleteQuery.data?.firstname && athleteQuery.data?.lastname
                      ? `${athleteQuery.data?.firstname} ${athleteQuery.data?.lastname}`
                      : athleteQuery.data?.username || t('athlete.unnamedUser')
                  }
                />
                {athleteQuery.data?.profilePhotoUrl && (
                  <meta
                    property="og:image"
                    content={athleteQuery.data?.profilePhotoUrl}
                  />
                )}
                <meta property="og:type" content="profile" />
                <meta
                  property="description"
                  content={t('athlete.meta.description', {
                    username:
                      athleteQuery.data?.username ||
                      athleteQuery.data?.firstname ||
                      t('athlete.unnamedUser'),
                    totalAscents: stats?.totalAscents || 0,
                    uniquePeaks: stats?.uniqueAscents || 0,
                    totalElevation:
                      stats?.totalElevation?.toLocaleString() || 0,
                    highestPeak: stats?.highestAscent?.toLocaleString() || 0,
                  })}
                />
                <meta
                  property="og:description"
                  content={t('athlete.meta.description', {
                    username:
                      athleteQuery.data?.username ||
                      athleteQuery.data?.firstname ||
                      t('athlete.unnamedUser'),
                    totalAscents: stats?.totalAscents || 0,
                    uniquePeaks: stats?.uniqueAscents || 0,
                    totalElevation:
                      stats?.totalElevation?.toLocaleString() || 0,
                    highestPeak: stats?.highestAscent?.toLocaleString() || 0,
                  })}
                />
                <meta
                  property="og:url"
                  content={`https://www.summittale.com/athlete/${athleteId}`}
                />
              </Helmet>
              <div className="flex flex-col mb-4 mx-4 mt-4">
                <Section title="" first>
                  {needsProfileCompletion && (
                    <div className="bg-slate-700 rounded-lg p-4 mb-4">
                      <div className="flex items-center justify-between gap-4">
                        <p className="text-blue-100">
                          {t('athlete.completeProfile.prompt')}
                        </p>
                        <Button onPress={() => setShowEditProfile(true)}>
                          {t('athlete.completeProfile.action')}
                        </Button>
                      </div>
                    </div>
                  )}
                  {athleteQuery.data?.premium ? (
                    <div className="bg-amber-800 rounded-lg p-3">
                      <div className="flex items-center gap-2 justify-center">
                        <FaCrown className="text-2xl text-amber-200" />
                        <div>
                          <h3 className="text-lg font-bold text-white">
                            {t('athlete.premium.member.title')}
                          </h3>
                        </div>
                      </div>
                    </div>
                  ) : (
                    currentUserId === athleteId && (
                      <div className="bg-linear-to-r from-amber-700 to-amber-600 rounded-lg p-4">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <FaCrown className="text-2xl text-amber-200" />
                            <div>
                              <h3 className="text-lg font-bold text-white">
                                {t('athlete.premium.upgrade.title')}
                              </h3>
                              <p className="text-amber-100 text-sm">
                                {t('athlete.premium.upgrade.description')}
                              </p>
                            </div>
                          </div>
                          <Button className="w-full py-3 px-4 bg-amber-900 hover:bg-amber-800 text-white font-medium rounded-lg transition-all duration-200 flex items-center justify-center gap-2 hover:shadow-lg hover:shadow-amber-900/50 group">
                            {t('athlete.premium.upgrade.button')}
                          </Button>
                        </div>
                      </div>
                    )
                  )}
                  {athleteAscents && athleteAscents.length > 0 && (
                    <div className="grid grid-cols-2 gap-6 w-full px-4">
                      <MetricCard
                        icon={<FaMountain />}
                        label={t('athlete.stats.totalAscents')}
                        value={stats?.uniqueAscents}
                        unit={t('athlete.stats.units.peaks')}
                      />
                      <MetricCard
                        icon={<FaArrowTrendUp />}
                        label={t('athlete.stats.totalElevation')}
                        value={stats?.totalElevation}
                        unit={t('athlete.stats.units.meters')}
                      />
                    </div>
                  )}
                </Section>
                <Activities athleteId={athleteId} />

                <Ascents athleteId={athleteId} />
              </div>
            </>
          )}
          {athleteQuery.isSuccess &&
            currentUserId === athleteId &&
            showEditProfile && (
              <EditProfileModal onClose={() => setShowEditProfile(false)} />
            )}
        </Body>
      }
    />
  );
}
