import { type UseMutationResult, useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import apiClient from '../apiClient';

export const useQueueEvent = (): UseMutationResult<
  AxiosResponse,
  AxiosError,
  { type: string; data: unknown }
> =>
  useMutation({
    mutationFn: (data: { type: string; data: unknown }) =>
      apiClient.post('/queue', data),
  });
