import { useNavigate, useParams } from '@tanstack/react-router';
import type { LineString } from 'geojson';
import { Helmet } from 'react-helmet-async';
import { FaTachometerAlt, FaTimes } from 'react-icons/fa';
import {
  FaBicycle,
  FaClock,
  FaRoute,
  FaRulerHorizontal,
  FaStopwatch,
} from 'react-icons/fa6';
import { useMap } from 'react-map-gl/maplibre';

import { ActivityType } from '@/api/apiTypes';
import { useGetActivity } from '@/api/hooks';
import ErrorMessage from '@/components/errorMessage';
import LoadingSpinner from '@/components/loadingSpinner';
import { MetricCard } from '@/components/shared/metricCard';
import Body from '@/components/sidebar/body';
import Header from '@/components/sidebar/header';
import Sidebar from '@/components/sidebar/sidebar';
import { renderActivityOutline } from '@/utils/geoUtils';
import { useEffect } from 'react';
type Bounds = {
  west: number;
  east: number;
  south: number;
  north: number;
};

export default function ActivityView() {
  const params = useParams({ from: '/activity/$activityId' });
  const navigate = useNavigate();
  const map = useMap();

  const activityId = Number(params.activityId);
  const activityQuery = useGetActivity(activityId);

  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    const geometry = activityQuery.data?.geoJson?.features?.[0]?.geometry as
      | LineString
      | undefined;
    if (geometry?.coordinates) {
      const coordinates = geometry.coordinates;
      const bounds = coordinates.reduce<Bounds>(
        (acc, coord) => {
          const [lng, lat] = coord;
          acc.west = Math.min(acc.west, lng);
          acc.east = Math.max(acc.east, lng);
          acc.south = Math.min(acc.south, lat);
          acc.north = Math.max(acc.north, lat);
          return acc;
        },
        {
          west: Number.POSITIVE_INFINITY,
          east: Number.NEGATIVE_INFINITY,
          south: Number.POSITIVE_INFINITY,
          north: Number.NEGATIVE_INFINITY,
        },
      );

      map.default?.fitBounds(
        [
          [bounds.west, bounds.south],
          [bounds.east, bounds.north],
        ],
        {
          padding: {
            top: 50,
            bottom: isMobile ? 150 : 50,
            left: isMobile ? 50 : 450,
            right: 50,
          },
          speed: 2,
        },
      );
    }
  }, [activityQuery.data?.geoJson, map.default, isMobile]);

  const formatDuration = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
  };

  const calculatePace = (distance: number, time: number) => {
    // Minutes per kilometer
    return time / 60 / (distance / 1000);
  };

  const calculateSpeed = (distance: number, time: number) => {
    // Kilometers per hour
    return distance / 1000 / (time / 3600);
  };

  return (
    <Sidebar
      header={
        <Header
          actions={[
            {
              icon: <FaTimes className="w-full h-full" />,
              text: 'Close',
              onClick: () => navigate({ to: '/' }),
            },
          ]}
          title={
            <span className="grow">
              <div className="flex flex-col items-start">
                <span className="text-white text-base font-semibold">
                  {activityQuery.status === 'success'
                    ? activityQuery.data?.name
                    : 'Loading...'}
                </span>
              </div>
            </span>
          }
        />
      }
      body={
        <Body>
          {activityQuery.status === 'pending' && <LoadingSpinner />}
          {activityQuery.status === 'error' && (
            <ErrorMessage
              error={activityQuery.error}
              retry={activityQuery.refetch}
            />
          )}
          {activityQuery.status === 'success' && activityQuery.data && (
            <>
              <Helmet>
                <title>{activityQuery.data.name} | Summit Tale</title>
                <meta
                  property="og:title"
                  content={`${activityQuery.data.name}`}
                />
                <meta
                  property="og:description"
                  content={`${((activityQuery.data.distance || 0) / 1000).toFixed(1)}km ${
                    activityQuery.data.type
                  }`}
                />
                <meta
                  property="og:url"
                  content={`https://www.summittale.com/activity/${activityId}`}
                />
              </Helmet>
              <div className="flex flex-col gap-4 mb-4 mx-4 mt-6">
                <div className="grid grid-cols-2 gap-4">
                  {activityQuery.data.geoJson && (
                    <MetricCard
                      icon={<FaRoute />}
                      label="Route"
                      customValue={
                        <div className="w-full aspect-square">
                          {renderActivityOutline(activityQuery.data, 200, 200)}
                        </div>
                      }
                    />
                  )}
                  <MetricCard
                    icon={<FaBicycle />}
                    label="Activity Type"
                    value={activityQuery.data.type}
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <MetricCard
                    icon={<FaRulerHorizontal />}
                    label="Distance"
                    value={(activityQuery.data.distance || 0) / 1000}
                    unit="km"
                    formatter={(v) => v.toFixed(1)}
                  />
                  <MetricCard
                    icon={<FaStopwatch />}
                    label="Duration"
                    value={formatDuration(activityQuery.data.duration || 0)}
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <MetricCard
                    icon={<FaTachometerAlt />}
                    label={
                      activityQuery.data.type === ActivityType.WALK
                        ? 'Pace'
                        : 'Speed'
                    }
                    value={
                      activityQuery.data.type === ActivityType.WALK
                        ? calculatePace(
                            activityQuery.data.distance || 0,
                            activityQuery.data.duration || 0,
                          )
                        : calculateSpeed(
                            activityQuery.data.distance || 0,
                            activityQuery.data.duration || 0,
                          )
                    }
                    unit={
                      activityQuery.data.type === ActivityType.WALK
                        ? 'min/km'
                        : 'km/h'
                    }
                    formatter={(v) => v.toFixed(1)}
                  />
                  <MetricCard
                    icon={<FaClock />}
                    label="Date"
                    value={new Date(
                      activityQuery.data.startDateLocal,
                    ).toLocaleDateString(undefined, {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  />
                </div>
              </div>
            </>
          )}
        </Body>
      }
    />
  );
}
