import type { GetListResponse } from '@/api/models';

export const getTranslationForLanguage = (
  list: GetListResponse,
  language: string,
): { name: string; description: string } => {
  // For English (default language), return the base list name/description
  if (language === 'en') {
    return {
      name: list.name || '',
      description: list.description || '',
    };
  }

  // For other languages, look for translations
  const translation = list.translations?.find((t) => t.language === language);
  return {
    name: translation?.name || '',
    description: translation?.description || '',
  };
};

export const getTranslatedName = (list: GetListResponse, language: string) => {
  if (!list) return '';
  const translation = list.translations?.find((t) => t.language === language);
  return translation?.name || list.name || '';
};

export const getTranslatedDescription = (
  list: GetListResponse,
  language: string,
) => {
  if (!list) return '';
  const translation = list.translations?.find((t) => t.language === language);
  return translation?.description || list.description || '';
};
