import { LanguageSwitcher } from '@/components/languageSwitcher';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMap } from 'react-icons/fa6';
import { useCreateMagicLink } from '../api/hooks/auth';
import logo from '../assets/logo.png';

function LandingPage() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [link, setLink] = useState('');
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const createMagicLink = useCreateMagicLink();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');
    setError('');

    try {
      const response = await createMagicLink.mutateAsync({ email });
      setMagicLinkSent(true);

      // In development, show the magic link URL directly
      if (import.meta.env.DEV && response.url) {
        setMessage(t('landing.magicLink.created'));
        setLink(response.url);
      } else {
        setMessage(t('landing.magicLink.sent'));
      }
    } catch (error) {
      setError(t('landing.magicLink.error'));
      setMagicLinkSent(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen bg-slate-900 flex flex-col justify-center items-center text-center">
      <header className="flex flex-col mb-10 gap-3">
        <div className="flex items-center gap-4">
          <img src={logo} alt="Summit Tale logo" className="w-16 h-16" />
          <h1 className="text-5xl text-slate-100">{t('landing.title')}</h1>
        </div>
        <p className="text-xl text-slate-200">{t('landing.tagline')}</p>
      </header>

      <main className="w-full max-w-md px-6">
        {!magicLinkSent ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex flex-col gap-2">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('landing.form.email.placeholder')}
                className="w-full px-4 py-3 rounded-lg bg-slate-800 border border-slate-700 text-white placeholder:text-slate-400 focus:outline-hidden focus:ring-2 focus:ring-sky-500"
                required
              />
              {error && (
                <div className="text-red-500 text-sm mt-2">{error}</div>
              )}
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-3 px-4 bg-sky-500 hover:bg-sky-600 text-white font-medium rounded-lg transition disabled:opacity-50"
            >
              {isLoading
                ? t('landing.form.email.sending')
                : t('landing.form.email.continue')}
            </button>
          </form>
        ) : (
          <>
            <div className="p-4 bg-slate-800 rounded-lg text-slate-200">
              {message}
            </div>
            {link && (
              <div className="p-4 ">
                <a href={link} className="underline">
                  Verify
                </a>
              </div>
            )}
          </>
        )}

        <div className="relative mt-8 mb-6 flex items-center gap-4">
          <div className="flex-1 border-t border-slate-600" />
          <span className="text-sm text-slate-400">{t('landing.or')}</span>
          <div className="flex-1 border-t border-slate-600" />
        </div>

        <div>
          <button
            type="button"
            onClick={() => navigate({ to: '/' })}
            className="w-full py-3 px-4 bg-slate-700 hover:bg-slate-600 text-white font-medium rounded-lg transition-all duration-200 flex items-center justify-center gap-2 hover:shadow-lg hover:shadow-slate-900/50 group"
          >
            <FaMap className="w-6 h-6" />
            {t('landing.exploreMap')}
          </button>
        </div>
      </main>

      {/* Add footer with language switcher */}
      <footer className="fixed bottom-0 left-0 right-0 p-4 flex justify-center">
        <LanguageSwitcher />
      </footer>
    </div>
  );
}

export default LandingPage;
