import { useRouter } from '@tanstack/react-router';
import type React from 'react';
import { FaArrowLeft } from 'react-icons/fa';

export type HeaderAction = {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
};

interface HeaderProps {
  actions?: HeaderAction[];
  title?: React.ReactNode;
}

export default function Header(props: HeaderProps) {
  const { actions, title } = props;
  const router = useRouter();
  const isOverview = router.state.location.pathname === '/overview';
  const canGoBack = router.state.matches.length > 1 && !isOverview;

  const backAction: HeaderAction = {
    icon: <FaArrowLeft className="w-full h-full" />,
    text: 'Go back',
    onClick: () => router.history.back(),
  };

  const allActions = canGoBack ? actions : actions;

  return (
    <div className="flex flex-row items-center justify-between w-full px-4 py-2 bg-slate-700 min-h-12">
      <div className="flex flex-row items-center grow gap-4">
        {canGoBack && (
          <button
            type="button"
            className="flex shrink-0 w-7 h-7 p-2 transition-colors duration-200 rounded-lg cursor-pointer text-slate-100 hover:text-slate-200 hover:bg-slate-600 justify-items-center items-center pointer-events-auto"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                backAction.onClick();
                e.stopPropagation();
              }
            }}
            title={backAction.text}
            aria-label={backAction.text}
            onClick={(e) => {
              backAction.onClick();
              e.stopPropagation();
            }}
          >
            {backAction.icon}
          </button>
        )}
        {title}
      </div>
      {allActions?.map((action) => (
        <button
          type="button"
          key={action.text}
          className="flex shrink-0 w-8 h-8 p-2 transition-colors duration-200 rounded-lg cursor-pointer text-slate-100 hover:text-slate-200 hover:bg-slate-600 justify-items-center items-center pointer-events-auto"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              action.onClick();
              e.stopPropagation();
            }
          }}
          title={action.text}
          aria-label={action.text}
          onClick={(e) => {
            action.onClick();
            e.stopPropagation();
          }}
        >
          {action.icon}
        </button>
      ))}
    </div>
  );
}
