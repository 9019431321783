import { FaClock } from 'react-icons/fa6';

import { useGetFeature } from '@/api/hooks';
import type { Ascent } from '../../../api/apiTypes';
import FeatureListItem from './featureListItem';

interface AscentListItemProps {
  ascent: Ascent;
}

export default function AscentListItem({ ascent }: AscentListItemProps) {
  if (!ascent.featureId) {
    return null;
  }

  const { data: feature } = useGetFeature(ascent.featureId);

  if (!feature) {
    return null;
  }

  const localeDateStringOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  switch (ascent.datePrecision) {
    case 'YEAR':
      localeDateStringOptions.month = undefined;
      localeDateStringOptions.day = undefined;
      break;
    case 'MONTH':
      localeDateStringOptions.day = undefined;
      break;
  }

  const ascentDate = ascent.date ? new Date(ascent.date) : null;

  return (
    <FeatureListItem feature={feature}>
      {ascentDate && (
        <div className="flex items-center gap-1 text-xs text-slate-400 shrink-0">
          <FaClock className="w-3 h-3" />
          <time dateTime={ascentDate.toISOString()}>
            {ascentDate.toLocaleDateString(
              navigator.language,
              localeDateStringOptions,
            )}
          </time>
        </div>
      )}
    </FeatureListItem>
  );
}
