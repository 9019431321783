import type { RegionType } from '@/api/apiTypes';
import ActivityView from '@/components/sidebar/activity/activity';
import AthleteView from '@/components/sidebar/athleteview/athleteView';
import FeatureView from '@/components/sidebar/featureview/featureView';
import ListView from '@/components/sidebar/listview/listView';
import RegionView from '@/components/sidebar/regionview/regionView';
import FAQ from '@/routes/FAQ';
import Root from '@/routes/__root';
import Admin from '@/routes/admin';
import Athletes from '@/routes/admin/athletes';
import Features from '@/routes/admin/features';
import Regions from '@/routes/admin/regions';
import WorkerQueue from '@/routes/admin/workerQueue';
import EditFeature from '@/routes/editFeature/editFeature';
import LandingPage from '@/routes/landing';
import Mapplication from '@/routes/mapplication';
import { createRootRoute, createRoute, redirect } from '@tanstack/react-router';
import Overview from './components/sidebar/overview/overview';

// Create a root route
const rootRoute = createRootRoute({
  component: Root,
});

const mapRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: Mapplication,
  loader: async ({ location }) => {
    if (location.pathname === '/') {
      throw redirect({ to: '/overview' });
    }
  },
});

// Map subroutes
const overviewRoute = createRoute({
  getParentRoute: () => mapRoute,
  path: '/overview',
  component: Overview,
});

const listRoute = createRoute({
  getParentRoute: () => mapRoute,
  path: '/list/$listId',
  component: ListView,
});

const featureRoute = createRoute({
  getParentRoute: () => mapRoute,
  path: '/feature/$featureId',
  component: FeatureView,
});

const editFeatureRoute = createRoute({
  getParentRoute: () => mapRoute,
  path: '/feature/edit',
  component: EditFeature,
});

const editFeatureWithIdRoute = createRoute({
  getParentRoute: () => mapRoute,
  path: '/feature/edit/$featureId',
  component: EditFeature,
});

const athleteRoute = createRoute({
  getParentRoute: () => mapRoute,
  path: '/athlete/$athleteId',
  component: AthleteView,
});

const activityRoute = createRoute({
  getParentRoute: () => mapRoute,
  path: '/activity/$activityId',
  component: ActivityView,
});

const regionRoute = createRoute({
  getParentRoute: () => mapRoute,
  path: '/region/$regionId',
  component: RegionView,
});

const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/login',
  component: LandingPage,
});

const docsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/docs/',
  component: FAQ,
});

const docsWithSectionRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/docs/$section',
  component: FAQ,
});

// Admin routes
const adminRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/admin',
  component: Admin,
});

const adminFeaturesRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: '/features',
  component: Features,
});

const adminAthletesRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: '/athletes',
  component: Athletes,
});

const adminRegionsRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: '/regions',
  component: Regions,
  validateSearch: (search: Record<string, unknown>) => ({
    type: search.type as RegionType | undefined,
    parentId: search.parentId as string | undefined,
  }),
});

const adminQueueRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: '/queue',
  component: WorkerQueue,
});

const adminListsRoute = createRoute({
  getParentRoute: () => adminRoute,
  path: '/lists',
  component: () => <div>Lists</div>,
});

// Create the route tree
export const routeTree = rootRoute.addChildren([
  mapRoute.addChildren([
    listRoute,
    featureRoute,
    editFeatureRoute,
    editFeatureWithIdRoute,
    athleteRoute,
    activityRoute,
    regionRoute,
    overviewRoute,
  ]),
  loginRoute,
  docsRoute,
  docsWithSectionRoute,
  adminRoute.addChildren([
    adminFeaturesRoute,
    adminAthletesRoute,
    adminRegionsRoute,
    adminQueueRoute,
    adminListsRoute,
  ]),
]);
