import { useGetAthlete } from '@/api/hooks';
import { useFeatureQuery } from '@/contexts/featureQueryContext';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FaUser } from 'react-icons/fa6';
import { Filter } from './filter';

export const AthleteFilter = () => {
  const { queryOptions, clearFilter } = useFeatureQuery();
  const { t } = useTranslation();
  const { data: athlete, isLoading } = useGetAthlete(
    queryOptions.athleteId?.value
      ? Number(queryOptions.athleteId.value)
      : undefined,
  );

  let athleteFilterText = t('mapFilters.athletes.all');
  if (athlete?.firstname && athlete.lastname) {
    athleteFilterText = `${athlete.firstname} ${athlete.lastname}`;
  } else if (athlete?.username) {
    athleteFilterText = athlete.username;
  } else if (queryOptions.athleteId?.value) {
    athleteFilterText = t('mapFilters.athletes.unnamed', {
      id: queryOptions.athleteId.value,
    });
  }

  return (
    <Filter
      onClick={() =>
        !queryOptions.athleteId?.isAutomatic && clearFilter('athleteId')
      }
      active={!!queryOptions.athleteId?.value}
      isAutomatic={queryOptions.athleteId?.isAutomatic}
      onClear={
        queryOptions.athleteId?.value && !queryOptions.athleteId?.isAutomatic
          ? () => clearFilter('athleteId')
          : undefined
      }
      ariaLabel={athleteFilterText}
    >
      {athlete?.profilePhotoUrl ? (
        <img
          src={athlete.profilePhotoUrl}
          alt=""
          className="h-5 w-5 rounded-full shrink-0 ring-1 ring-slate-700/50 object-cover"
        />
      ) : (
        <FaUser
          size={16}
          className="text-slate-300 shrink-0"
          aria-hidden="true"
        />
      )}
      <div className="flex flex-col">
        <span className="text-[10px] leading-none text-slate-400 mb-0.5">
          {t('mapFilters.athletes.label')}
        </span>
        <span
          className={clsx(
            'text-slate-200 font-medium whitespace-nowrap',
            isLoading && 'animate-pulse',
          )}
        >
          {athleteFilterText}
        </span>
      </div>
    </Filter>
  );
};
