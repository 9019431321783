import { Link } from '@tanstack/react-router';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { FaHeart, FaMountain } from 'react-icons/fa6';

import { useGetRegion } from '@/api/hooks';
import type { GetListResponse } from '@/api/models';
import { RegionType } from '../../../api/apiTypes';

type ListProps = {
  list: GetListResponse & { progress?: number };
  setListProgressTooltipContent?: (list: GetListResponse | null) => void;
};

export default function ListItem(props: ListProps) {
  const { list } = props;
  const { t, i18n } = useTranslation();

  const { data: region } = useGetRegion(list.regionId || undefined);

  // Find translation for current language, fallback to default name
  const translation = list.translations.find(
    (t) => t.language === i18n.language,
  );

  const listName = translation?.name || list.name;

  return (
    <Link to="/list/$listId" params={{ listId: list.id.toString() }}>
      <div
        key={list.id}
        className={clsx(
          'hover:bg-slate-600 px-2 py-1 rounded-md cursor-pointer bg-slate-700 relative overflow-hidden group',
          {
            'pb-2': list.progress && list.progress > 0,
          },
        )}
      >
        <div
          className={clsx('absolute bottom-0 left-0 h-1 rounded-md', {
            'bg-green-400 group-hover:bg-green-300': list.progress === 100,
            'bg-green-500 group-hover:bg-green-400':
              list.progress && list.progress < 100,
          })}
          style={{
            width: `${list.progress}%`,
          }}
        />
        <div className="flex flex-row items-center gap-3 z-10 relative">
          <div className="flex items-center justify-center w-6 h-6 shrink-0">
            {region && region.type === RegionType.COUNTRY && region.isocode && (
              <span
                className={`fi fi-${region.isocode.toLocaleLowerCase()} max-h-6 max-w-6 shrink-0`}
              />
            )}
            {region &&
              region.type !== RegionType.COUNTRY &&
              region.imageUrl && (
                <img
                  className="max-h-6 max-w-6 shrink-0"
                  alt={region.name}
                  src={region.imageUrl}
                />
              )}
          </div>
          <div className="w-6 flex flex-col items-start justify-center grow">
            <div className="flex flex-row items-center justify-between w-full gap-4">
              <div className="text-slate-100 text-xs font-semibold truncate w-full">
                {listName}
              </div>
              <div>
                <span className="text-slate-100 text-xs flex items-center justify-end gap-1">
                  <span className="font-semibold font-mono">
                    {list.favoriteCount}
                  </span>
                  <span className="text-red-500 text-xs">
                    <FaHeart className="inline-block" />
                  </span>
                </span>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-2 w-full">
              {list.progress === 0 || !list.progress ? (
                <div>
                  <span className="text-slate-100 text-xs flex items-center justify-end gap-1">
                    <span className="text-slate-300 text-xs">
                      <FaMountain className="inline-block" />
                    </span>
                    <span>
                      {list.featureCount} {t('listItem.features')}
                    </span>
                  </span>
                </div>
              ) : (
                <div>
                  <span className="text-slate-100 text-xs flex items-center justify-end gap-1">
                    <span className="text-slate-300 text-xs">
                      <FaMountain className="inline-block" />
                    </span>
                    <span>
                      {`${list.userFeatureCount} / ${list.featureCount}`}
                    </span>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
