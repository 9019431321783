import type React from 'react';
import {
  FaDumbbell,
  FaHouseChimney,
  FaLandmark,
  FaLocationDot,
  FaMountain,
  FaSignsPost,
  FaSquareParking,
} from 'react-icons/fa6';
import { GiMountainClimbing } from 'react-icons/gi';
import { FeatureType } from '../api/apiTypes';

type FeatureTypeMap = {
  [key in FeatureType]: {
    colorBackground: string;
    colorText: string;
    icon: React.JSX.Element;
    translationKey: string;
  };
};

export const featureTypeMap: FeatureTypeMap = {
  [FeatureType.ATTRACTION]: {
    colorBackground: 'bg-purple-800',
    colorText: 'text-purple-400',
    icon: <FaLandmark />,
    translationKey: 'featureTypes.ATTRACTION',
  },
  [FeatureType.CABIN]: {
    colorBackground: 'bg-amber-800',
    colorText: 'text-amber-600',
    icon: <FaHouseChimney />,
    translationKey: 'featureTypes.CABIN',
  },
  [FeatureType.CLIMBING_GYM]: {
    colorBackground: 'bg-red-800',
    colorText: 'text-red-400',
    icon: <FaDumbbell />,
    translationKey: 'featureTypes.CLIMBING_GYM',
  },
  [FeatureType.CRAG]: {
    colorBackground: 'bg-orange-800',
    colorText: 'text-orange-400',
    icon: <GiMountainClimbing />,
    translationKey: 'featureTypes.CRAG',
  },
  [FeatureType.PARKING]: {
    colorBackground: 'bg-gray-800',
    colorText: 'text-gray-400',
    icon: <FaSquareParking />,
    translationKey: 'featureTypes.PARKING',
  },
  [FeatureType.PEAK]: {
    colorBackground: 'bg-blue-800',
    colorText: 'text-blue-400',
    icon: <FaMountain />,
    translationKey: 'featureTypes.PEAK',
  },
  [FeatureType.POINT]: {
    colorBackground: 'bg-green-800',
    colorText: 'text-green-400',
    icon: <FaLocationDot />,
    translationKey: 'featureTypes.POINT',
  },
  [FeatureType.WAYPOINT]: {
    colorBackground: 'bg-teal-800',
    colorText: 'text-teal-400',
    icon: <FaSignsPost />,
    translationKey: 'featureTypes.WAYPOINT',
  },
};
