import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { FaWandSparkles } from 'react-icons/fa6';

export type FilterProps = {
  children: React.ReactNode;
  onClick?: () => void;
  showMenu?: boolean;
  onMenuClose?: () => void;
  active?: boolean;
  isAutomatic?: boolean;
  onClear?: () => void;
  ariaLabel: string;
  menuContent?: React.ReactNode;
};

export const Filter = ({
  children,
  onClick,
  showMenu = false,
  onMenuClose,
  active = false,
  isAutomatic = false,
  onClear,
  ariaLabel,
  menuContent,
}: FilterProps) => {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <div
        className={clsx(
          'bg-slate-800/90 backdrop-blur-xs px-3 py-2 rounded-xl',
          'flex flex-row gap-2.5 items-center shadow-2xs',
          'transition-all duration-150 ease-in-out',
          'border border-slate-700/50',
          !isAutomatic &&
            'cursor-pointer hover:bg-slate-700/90 hover:shadow-md',
          active && 'ring-1 ring-blue-500/50 bg-slate-700/90',
        )}
        onClick={!isAutomatic ? onClick : undefined}
        onKeyDown={(e) => !isAutomatic && e.key === 'Enter' && onClick?.()}
        role={!isAutomatic ? 'button' : undefined}
        tabIndex={!isAutomatic ? 0 : undefined}
        aria-label={ariaLabel}
        aria-expanded={showMenu}
        aria-haspopup={!!onMenuClose}
      >
        {children}
        {active &&
          (isAutomatic ? (
            <div title={t('mapFilters.automatic.tooltip')} className="ml-1">
              <FaWandSparkles
                className="text-blue-400 w-3 h-3"
                aria-hidden="true"
              />
            </div>
          ) : (
            onClear && (
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  onClear();
                }}
                className="text-slate-400 hover:text-slate-200 ml-1 cursor-pointer"
                aria-label={t('common.clear')}
              >
                <FaTimes className="w-3 h-3" aria-hidden="true" />
              </button>
            )
          ))}
      </div>
      {showMenu && onMenuClose && (
        <>
          <div
            className="fixed inset-0"
            onClick={onMenuClose}
            onKeyDown={(e) => e.key === 'Escape' && onMenuClose()}
            role="presentation"
          />
          {menuContent}
        </>
      )}
    </div>
  );
};
