import {
  type UseMutationResult,
  type UseQueryResult,
  useMutation,
  useQueries,
  useQuery,
} from '@tanstack/react-query';

import type { AxiosError, AxiosResponse } from 'axios';
import apiClient from '../apiClient';
import type { RegionDTO, RegionType } from '../apiTypes';
import { queryClient } from '../queryClient';

export const useGetRegions = (options: {
  parentRegionId?: number;
  regionType?: RegionType;
  includeGeometry?: boolean;
}): UseQueryResult<RegionDTO[], AxiosError> =>
  useQuery({
    queryKey: ['regions', options],
    queryFn: () =>
      apiClient
        .get<RegionDTO[]>('/regions', {
          params: options,
        })
        .then((res) => res.data),
  });

export const useGetRegion = (
  regionId: number | undefined,
): UseQueryResult<RegionDTO, AxiosError> =>
  useQuery({
    queryKey: ['region', regionId],
    queryFn: () =>
      apiClient.get<RegionDTO>(`/regions/${regionId}`).then((res) => res.data),
    // Load initial data from all regions list
    initialData: () => {
      const regions = queryClient.getQueryData<RegionDTO[]>([
        'regions',
        undefined,
      ]);
      return regions?.find((region: RegionDTO) => region.id === regionId);
    },
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
    enabled: regionId !== undefined,
  });

export const useGetRegionsById = (regionIds: number[]) => {
  return useQueries({
    queries: regionIds.map((id) => ({
      queryKey: ['region', id],
      queryFn: () =>
        apiClient.get<RegionDTO>(`/regions/${id}`).then((res) => res.data),
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
    })),
  });
};

export const useAddRegion = (): UseMutationResult<
  AxiosResponse<RegionDTO>,
  AxiosError,
  RegionDTO
> =>
  useMutation({
    mutationFn: ({ ...data }: RegionDTO) => apiClient.post('/regions', data),
    onSuccess: (data) => {
      queryClient.setQueryData(['region', data.data.id.toString()], data.data);
      queryClient.setQueriesData(
        { queryKey: ['regions'] },
        (oldData: RegionDTO[] = []) => [...oldData, data.data],
      );
    },
  });

export const useUpdateRegion = (): UseMutationResult<
  AxiosResponse<RegionDTO>,
  AxiosError,
  RegionDTO
> =>
  useMutation({
    mutationFn: ({ id, ...data }: RegionDTO) =>
      apiClient.put<RegionDTO>(`/regions/${id}`, data),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['region', variables.id.toString()], data);
      queryClient.setQueriesData(
        { queryKey: ['regions'] },
        (oldData: RegionDTO[] = []) =>
          oldData.map((d: RegionDTO) =>
            d.id === variables.id ? data.data : d,
          ),
      );
    },
  });

export const useDeleteRegion = () =>
  useMutation({
    mutationFn: (data: RegionDTO) =>
      apiClient.delete<void>(`/regions/${data.id}`),
    onSuccess: (_, variables) => {
      queryClient.removeQueries({
        queryKey: ['region', variables.id.toString()],
      });
      queryClient.setQueriesData(
        { queryKey: ['regions'] },
        (oldData: RegionDTO[] = []) =>
          oldData.filter((d: RegionDTO) => d.id !== variables.id),
      );
    },
  });

export const useUploadRegionImage = () =>
  useMutation({
    mutationFn: (data: { id: number; file: File }) => {
      const formData = new FormData();
      formData.append('file', data.file);

      return apiClient.post<RegionDTO>(`/regions/${data.id}/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['region', variables.id.toString()], data.data);
      queryClient.setQueriesData(
        { queryKey: ['regions'] },
        (oldData: RegionDTO[] = []) =>
          oldData.map((d: RegionDTO) =>
            d.id === variables.id ? data.data : d,
          ),
      );
    },
  });
