import { Outlet, useMatch } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { type LngLatBounds, MapProvider } from 'react-map-gl/maplibre';

import { useGetFeatures } from '@/api/hooks';
import { FeatureTooltip } from '@/components/map/featureTooltip';
import Features from '@/components/map/features';
import MapComponent from '@/components/map/map';
import MapFilters from '@/components/map/mapFilters';
import Topbar from '@/components/topbar/topbar';
import { useFeatureQuery } from '@/contexts/featureQueryContext';
import useDebounce from '@/hooks/useDebounce';
import { HoveredFeatureIdProvider } from '@/hooks/useHoveredFeature';

const DEBOUNCE_TIME = 400;

function Mapplication() {
  const [bounds, setBounds] = useState<LngLatBounds | undefined>(undefined);
  const debouncedBounds = useDebounce<LngLatBounds | undefined>(
    bounds,
    DEBOUNCE_TIME,
  );

  const { listId: selectedListId } =
    useMatch({ from: '/list/$listId', shouldThrow: false })?.params || {};
  const { athleteId: selectedAthleteId } =
    useMatch({ from: '/athlete/$athleteId', shouldThrow: false })?.params || {};

  const { getQueryForApi, updateQueryOptions, queryOptions, clearFilter } =
    useFeatureQuery();

  useEffect(() => {
    if (selectedListId) {
      updateQueryOptions('listId', selectedListId, true);
    } else if (queryOptions.listId?.isAutomatic) {
      clearFilter('listId');
    }

    if (selectedAthleteId) {
      updateQueryOptions('athleteId', selectedAthleteId, true);
    } else if (queryOptions.athleteId?.isAutomatic) {
      clearFilter('athleteId');
    }
  }, [selectedListId, selectedAthleteId]);

  useEffect(() => {
    if (debouncedBounds) {
      updateQueryOptions('bounds', {
        north: debouncedBounds.getNorth().toFixed(3),
        east: debouncedBounds.getEast().toFixed(3),
        south: debouncedBounds.getSouth().toFixed(3),
        west: debouncedBounds.getWest().toFixed(3),
      });
    }
  }, [debouncedBounds]);

  const { data: features, status: featuresStatus } = useGetFeatures(
    getQueryForApi(),
  );

  const showLoadingIndicator = useDebounce(featuresStatus === 'pending', 1000);

  return (
    <>
      <div className="flex flex-col w-screen h-dvh">
        <HoveredFeatureIdProvider>
          <MapProvider>
            <Topbar />
            <div className="w-full h-full">
              <div className="relative grow w-full h-full bg-slate-900">
                <MapComponent setCurrentBounds={setBounds}>
                  <div className="hidden md:block">
                    <Outlet />
                  </div>
                  <Features
                    features={featuresStatus === 'success' ? features : []}
                    activities={[]}
                  />
                  <MapFilters />
                </MapComponent>
                <div className="md:hidden">
                  <Outlet />
                </div>
              </div>
            </div>
          </MapProvider>
          <FeatureTooltip />
        </HoveredFeatureIdProvider>

        {showLoadingIndicator && featuresStatus === 'pending' && (
          <div className="absolute bottom-0 left-0 justify-between w-screen pointer-events-none text-slate-100">
            <div className="flex-col items-end">
              <div className="flex flex-col items-center m-4">
                <div className="inline-flex items-center p-3 space-x-2 rounded-full shadow-xs bg-slate-800 shadow-slate-800">
                  <svg className="w-4 h-4 animate-spin" viewBox="0 0 24 24">
                    <title>Loading...</title>
                    <path
                      fill="currentColor"
                      d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                    />
                  </svg>
                  <span className="text-sm">Loading</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Mapplication;
