import type { IconType } from 'react-icons';
import {
  FaBook,
  FaClockRotateLeft,
  FaPlug,
  FaUser,
  FaWrench,
} from 'react-icons/fa6';

interface SectionConfig {
  icon?: IconType;
  title: string;
}

export const sections: Record<string, SectionConfig> = {
  'Core Concepts': { icon: FaBook, title: 'Core Concepts' },
  Integrations: { icon: FaPlug, title: 'Integrations' },
  Account: { icon: FaUser, title: 'Account' },
  Troubleshooting: { icon: FaWrench, title: 'Troubleshooting' },
  Changelog: { icon: FaClockRotateLeft, title: 'Changelog' },
};

interface DocItem {
  id: string;
  section: string;
  title: string;
  content: string;
  status?: 'future' | 'planned' | 'in-progress' | 'completed';
}

export const documentation: DocItem[] = [
  // Core Concepts
  {
    id: 'concepts-peaks',
    section: 'Core Concepts',
    title: 'Peaks and Ascents',
    content:
      'Summit Tale tracks your mountain ascents by analyzing your GPS activities. When your track passes near a known peak, we record this as an ascent. Each peak has attributes like elevation, prominence, and location that help us understand the significance of your achievement.',
  },
  {
    id: 'concepts-lists',
    section: 'Core Concepts',
    title: 'Peak Lists',
    content: `
Peak lists are collections of mountains or features that you can track your progress on. They can represent various goals like regional peaks, difficulty levels, or themed collections.

**Key Features:**
• Track progress on multiple lists simultaneously
• Favorite lists you're actively pursuing
• View leaderboards to compare progress with others
• Multilingual support with translations for list names and descriptions
• Regional organization with country/area-specific icons

**Types of Lists:**
• Official lists curated by Summit Tale
• Community-created lists for specific challenges
• Personal lists for custom goals

Lists help organize your mountain objectives and provide a structured way to track your achievements across different regions and difficulty levels.`,
  },
  {
    id: 'concepts-prominence',
    section: 'Core Concepts',
    title: 'Understanding Prominence',
    content: `
Prominence, or topographic prominence, measures a peak's independence from higher terrain. It's calculated as the vertical distance between a peak's summit and the lowest contour line that encircles it and no higher summit.

**Key Points:**
• A peak with 1000m prominence rises at least 1000m above any connecting ridge to higher ground
• Prominence is independent of absolute height - a lower peak can have higher prominence
• Major peaks typically have high prominence values (>1000m)
• Subsidiary peaks have lower prominence (<100m)

**Examples:**
• Mont Blanc (4808m): 4696m prominence - dominates surrounding terrain
• Matterhorn (4478m): 1040m prominence - connected to higher Monte Rosa massif
• Säntis (2502m): 2021m prominence - stands alone despite moderate height

[Learn more about prominence](https://en.wikipedia.org/wiki/Topographic_prominence)`,
  },
  {
    id: 'concepts-regions',
    section: 'Core Concepts',
    title: 'Mountain Regions',
    content:
      'Peaks are organized into geographical regions to help you track your progress in specific mountain ranges or areas. Each region has its own statistics and leaderboards, allowing you to compare your achievements within specific mountainous areas. Regions can be countries or specific mountain ranges, each with their own unique identifiers and imagery.',
  },

  // Integrations
  {
    id: 'integrations-strava',
    section: 'Integrations',
    title: 'Strava Integration',
    content:
      'Strava is no longer supported due to recent changes in their API policies. [Read more about Strava API changes](https://www.dcrainmaker.com/2023/11/strava-significantly-cuts-3rd-party-app-access-impacting-many-apps.html) for more information about these changes.',
  },
  {
    id: 'integrations-sync',
    section: 'Integrations',
    title: 'Activity Synchronization',
    content:
      'Activities are automatically synchronized when you complete them. Historical activities are imported when you first connect an integration. You can manually trigger a sync from your profile settings if needed.',
  },

  // Account Management
  {
    id: 'account-login',
    section: 'Account',
    title: 'Passwordless Login',
    content:
      'Summit Tale uses modern passwordless authentication methods for enhanced security:\n\n' +
      '**Magic Links**\n' +
      "For devices that don't support passkeys, we send a secure one-time login link to your email. Simply click the link to sign in - no password needed.",
  },
  {
    id: 'account-data',
    section: 'Account',
    title: 'Your Data & Privacy',
    content: `
**Data Control & Transparency**
You have full control over your personal data on Summit Tale. Access your complete data archive, including:
• Activity tracks and ascent records
• Profile information and preferences
• Integration connections and settings

**Data Usage**
We use your data exclusively for:
• Identifying and verifying peak ascents
• Generating statistics and achievements
• Improving peak detection accuracy

**Privacy Commitment**
We follow privacy-first principles:
• No data sharing with third parties
• No tracking or analytics beyond essential features
• Minimal data collection policy`,
  },
  {
    id: 'account-delete',
    section: 'Account',
    title: 'Account Deletion',
    content:
      'You can delete your account and all associated data at any time through account settings. This process is immediate and irreversible, removing all your personal information, activities, and achievements from our systems.',
  },

  // Troubleshooting
  {
    id: 'trouble-sync',
    section: 'Troubleshooting',
    title: 'Sync Issues',
    content:
      "If activities aren't syncing, first check your connection status in settings. You may need to reconnect your account if permissions have expired. For historical activities, use the manual sync option.",
  },
  {
    id: 'trouble-missing-peaks',
    section: 'Troubleshooting',
    title: 'Missing Peaks',
    content:
      "Some peaks might not be detected if your GPS track passes too far from the summit or if the peak isn't in our database. Functionality for adding missing peaks is coming soon.",
  },
  {
    id: 'trouble-lists',
    section: 'Troubleshooting',
    title: 'List Issues',
    content: `
**Common List Problems:**
• List progress not updating: Progress updates may take a few minutes to reflect after new ascents
• Missing translations: Lists may not be available in all languages - you can help by contributing translations

Contact support if you encounter persistent issues with lists or progress tracking.`,
  },

  // Roadmap
  {
    id: 'roadmap-status',
    section: 'Roadmap',
    title: 'Status',
    content:
      'This is a list of features that are planned for the future.\n\n' +
      'In progress: Features that are currently being developed.\n' +
      'Planned: Features that are next on the roadmap.\n' +
      'Future: Features that are planned for the future but not yet started.\n' +
      'Completed: Features that have recently been released.',
  },
  {
    id: 'roadmap-prominence-calculation',
    section: 'Roadmap',
    title: 'Digital Elevation Model (DEM) for Prominence Calculation',
    content:
      'Digital Elevation Model (DEM) for prominence calculation that will:\n\n' +
      '• Process high-resolution terrain data for precise calculations\n' +
      '• Update prominence values automatically as new data becomes available\n' +
      '• Enable better peak classification and list organization',
    status: 'in-progress',
  },
  {
    id: 'roadmap-automatic-osm-sync',
    section: 'Roadmap',
    title: 'Automatic OpenStreetMap Sync',
    content:
      'OpenStreetMap (OSM) integration that will:\n\n' +
      '• Automatically detect and import new peaks from OSM data\n' +
      '• Keep peak information (elevation, coordinates, names) up to date\n' +
      '• Support multiple languages and local peak names\n' +
      '• Maintain data quality through automated validation',
    status: 'in-progress',
  },
  {
    id: 'roadmap-friends',
    section: 'Roadmap',
    title: 'Friends',
    content:
      'Friends to help you compare your progress with others and see who has the most ascents.\n\n' +
      '• Add friends by username\n' +
      "• See friends' ascents and achievements\n" +
      '• Compare progress and challenge each other to more ascents',
    status: 'planned',
  },
  {
    id: 'roadmap-activities',
    section: 'Roadmap',
    title: 'Enhanced Activity Integration',
    content:
      'A comprehensive activity tracking system that will:\n\n' +
      '• Link multiple ascents to a single activity\n' +
      '• Provide detailed statistics for each ascent (time, elevation gain, distance)\n' +
      '• Generate shareable activity cards with peak achievements',
    status: 'planned',
  },
  {
    id: 'roadmap-manual-import',
    section: 'Roadmap',
    title: 'Manual Activity Import',
    content:
      'A flexible import system that will support:\n\n' +
      '• Multiple GPS file formats (GPX, TCX, FIT)\n' +
      '• Bulk import of historical activities\n' +
      '• Smart peak detection on imported tracks\n' +
      '• Custom activity metadata fields\n' +
      '• Data validation and error correction',
    status: 'planned',
  },
  {
    id: 'roadmap-garmin',
    section: 'Roadmap',
    title: 'Garmin Integration',
    content:
      'Direct integration with Garmin Connect that will provide:\n\n' +
      '• Real-time activity syncing\n' +
      '• Automatic import of historical activities',
    status: 'future',
  },
  {
    id: 'roadmap-passkey-login',
    section: 'Roadmap',
    title: 'Passkey Authentication',
    content:
      'A modern authentication system using passkeys that will offer:\n\n' +
      '• Passwordless login across all your devices\n' +
      '• Enhanced security through public key cryptography\n' +
      '• Support for biometric authentication\n' +
      '[Learn more about passkeys](https://fidoalliance.org/passkeys/)',
    status: 'future',
  },
  {
    id: 'roadmap-android-app',
    section: 'Roadmap',
    title: 'Android App',
    content:
      'A native Android app that will provide:\n\n' +
      '• Offline access to data\n' +
      '• Push notifications for new achievements\n' +
      '• Logging of ascents and peaks',
    status: 'future',
  },
  {
    id: 'roadmap-ios-app',
    section: 'Roadmap',
    title: 'iOS App',
    content:
      'A native iOS app that will provide:\n\n' +
      '• Offline access to data\n' +
      '• Push notifications for new achievements\n' +
      '• Logging of ascents and peaks',
    status: 'future',
  },
  {
    id: 'roadmap-smart-lists',
    section: 'Roadmap',
    title: 'Smart Lists',
    content:
      'Smart lists which automatically updates based on search criteria.',
    status: 'future',
  },
  {
    id: 'roadmap-peak-suggestions',
    section: 'Roadmap',
    title: 'Peak Suggestions',
    content: 'Peak suggestions based on your ascents and preferences.',
    status: 'future',
  },
  {
    id: 'roadmap-feature-tags',
    section: 'Roadmap',
    title: 'Feature Tags',
    content:
      'Feature tags to help organize and search for peaks and lists.\n\n' +
      'Examples:\n\n' +
      '• Difficulty\n' +
      '• Guide required\n' +
      '• Season\n' +
      '• Amenities',
    status: 'future',
  },
];
