import apiClient from '@/api/apiClient';
import type { GetActivitiesQuery, GetActivityResponse } from '@/api/models';
import {
  type UseQueryOptions,
  type UseQueryResult,
  useQuery,
} from '@tanstack/react-query';

export const useGetActivity = (
  activityId: number,
  queryOptions?: Partial<UseQueryOptions<GetActivityResponse>>,
): UseQueryResult<GetActivityResponse> =>
  useQuery({
    queryKey: ['activity', activityId],
    queryFn: () =>
      apiClient
        .get<GetActivityResponse>(`/activities/${activityId}`)
        .then((res) => res.data),
    ...queryOptions,
  });

export const useGetActivities = (
  params?: GetActivitiesQuery,
  queryOptions?: Partial<UseQueryOptions<GetActivityResponse[]>>,
): UseQueryResult<GetActivityResponse[]> =>
  useQuery({
    queryKey: ['activities', params],
    queryFn: () =>
      apiClient
        .get<GetActivityResponse[]>('/activities', { params })
        .then((res) => res.data),
    ...queryOptions,
  });
