import { FeatureType } from '@/api/apiTypes';
import { useFeatureQuery } from '@/contexts/featureQueryContext';
import { featureTypeMap } from '@/utils/featureTypeMap';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Filter } from './filter';

const FeatureTypeMenu = () => {
  const { queryOptions, updateQueryOptions, clearFilter } = useFeatureQuery();
  const selectedTypes = queryOptions.featureTypes?.value || [];
  const { t } = useTranslation();

  const handleTypeClick = (type: FeatureType) => {
    const newTypes = selectedTypes.includes(type)
      ? selectedTypes.filter((t) => t !== type)
      : [...selectedTypes, type];

    if (newTypes.length === 0) {
      clearFilter('featureTypes');
    } else {
      updateQueryOptions('featureTypes', newTypes);
    }
  };

  return (
    <div
      className="absolute top-full left-0 mt-2 bg-slate-800/95 backdrop-blur-xs rounded-xl shadow-lg p-2 min-w-[180px] border border-slate-700/50 flex flex-col gap-1"
      role="menu"
      style={{ zIndex: 31 }}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      {Object.values(FeatureType)
        .filter((type, index, self) => self.indexOf(type) === index)
        .sort((a, b) =>
          t(featureTypeMap[a].translationKey).localeCompare(
            t(featureTypeMap[b].translationKey),
          ),
        )
        .map((type) => (
          <div
            key={type}
            role="menuitem"
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && handleTypeClick(type)}
            className={clsx(
              'flex items-center gap-3 px-3 py-2 rounded-lg cursor-pointer',
              'transition-all duration-150 ease-in-out',
              selectedTypes.includes(type)
                ? 'bg-slate-700/80'
                : 'hover:bg-slate-700/70 active:bg-slate-700/90',
            )}
            onClick={() => handleTypeClick(type)}
          >
            <div className="flex items-center gap-2.5 flex-1">
              {featureTypeMap[type].icon}
              <span className="text-slate-200 font-medium">
                {t(featureTypeMap[type].translationKey)}
              </span>
            </div>
            {selectedTypes.includes(type) && (
              <div
                className="h-2 w-2 rounded-full bg-blue-500 shadow-2xs shadow-blue-500/20"
                aria-hidden="true"
              />
            )}
          </div>
        ))}
    </div>
  );
};

export const FeatureTypeFilter = () => {
  const { queryOptions, clearFilter } = useFeatureQuery();
  const [showTypeMenu, setShowTypeMenu] = useState(false);
  const { t } = useTranslation();
  const selectedTypes = queryOptions.featureTypes?.value || [];

  const renderFeatureTypeIcons = (types: FeatureType[]) => {
    if (types.length === 0) {
      return (
        <div className="grid grid-cols-2 gap-1 shrink-0" aria-hidden="true">
          {featureTypeMap[FeatureType.PEAK].icon}
          {featureTypeMap[FeatureType.CABIN].icon}
          {featureTypeMap[FeatureType.PARKING].icon}
          {featureTypeMap[FeatureType.POINT].icon}
        </div>
      );
    }

    if (types.length === 1) {
      return featureTypeMap[types[0]].icon;
    }

    return (
      <div
        className={clsx(
          'grid gap-1 shrink-0',
          types.length <= 2 ? 'grid-cols-2' : 'grid-cols-2 grid-rows-2',
        )}
        aria-hidden="true"
      >
        {types.slice(0, 4).map((type) => featureTypeMap[type].icon)}
      </div>
    );
  };

  const filterContent = (
    <>
      {renderFeatureTypeIcons(selectedTypes)}
      <div className="flex flex-col">
        <span className="text-[10px] leading-none text-slate-400 mb-0.5">
          {t('mapFilters.features.label')}
        </span>
        <span className="text-slate-200 font-medium whitespace-nowrap">
          {selectedTypes.length === 1
            ? t(featureTypeMap[selectedTypes[0]].translationKey)
            : selectedTypes.length > 1
              ? t('mapFilters.features.selected', {
                  count: selectedTypes.length,
                })
              : t('mapFilters.features.all')}
        </span>
      </div>
    </>
  );

  return (
    <Filter
      onClick={() => setShowTypeMenu(!showTypeMenu)}
      showMenu={showTypeMenu}
      onMenuClose={() => setShowTypeMenu(false)}
      active={!!queryOptions.featureTypes?.value?.length}
      isAutomatic={queryOptions.featureTypes?.isAutomatic}
      onClear={
        queryOptions.featureTypes?.value?.length
          ? () => clearFilter('featureTypes')
          : undefined
      }
      ariaLabel={t('mapFilters.features.all')}
      menuContent={showTypeMenu && <FeatureTypeMenu />}
    >
      {filterContent}
    </Filter>
  );
};
