import { Link } from '@tanstack/react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FaQuestionCircle, FaTimes } from 'react-icons/fa';
import { FaRegCircle, FaRegCircleCheck } from 'react-icons/fa6';

import type { GetFeatureResponse } from '@/api/models';

const MAX_NAME_LENGTH = 30;

// Helper function to truncate names
const truncateName = (name: string) => {
  if (name.length <= MAX_NAME_LENGTH) return name;

  // Try to truncate at a space or dash if possible
  const truncatePoint = name.substring(0, MAX_NAME_LENGTH).lastIndexOf(' ');
  if (truncatePoint > MAX_NAME_LENGTH * 0.7) {
    // Only use space if it's after 70% of max length
    return `${name.substring(0, truncatePoint)}...`;
  }

  // If no good breaking point, just truncate at max length
  return `${name.substring(0, MAX_NAME_LENGTH)}...`;
};

interface FeatureListItemProps {
  feature: GetFeatureResponse;
  isEditing?: boolean;
  isHovered?: boolean;
  onRemove?: () => void;
  onHover?: (id: number | null) => void;
}

export default function FeatureListItem({
  feature,
  isEditing,
  isHovered,
  onRemove,
  onHover,
}: FeatureListItemProps) {
  const { t } = useTranslation();

  const commonClasses = clsx(
    'rounded-md flex items-center justify-between gap-2 p-2 py-1 transition-all duration-200',
    feature.ascentIds?.length ? 'bg-slate-600' : 'bg-slate-700',
    isHovered && 'outline-2 outline-blue-300 outline -outline-offset-2',
    'hover:bg-slate-600/80',
  );

  const content = (
    <>
      {feature.ascentIds?.length ? (
        <FaRegCircleCheck className="text-green-400 shrink-0" />
      ) : (
        <FaRegCircle className="text-slate-400 shrink-0" />
      )}
      <div className="text-sm text-slate-200 whitespace-pre-wrap break-words grow">
        {truncateName(feature.name || t('feature.unnamedFeature'))}
      </div>
      <div className="text-xs text-slate-300 whitespace-nowrap shrink-0 flex items-center gap-1">
        {feature.elevation ? (
          feature.elevation.toLocaleString()
        ) : (
          <FaQuestionCircle className="text-xs" />
        )}
        <span className="text-slate-400">m</span>
      </div>
      {isEditing && (
        <button
          className="text-slate-300 hover:text-red-400 transition-colors text-sm cursor-pointer p-1"
          type="button"
          aria-label="Remove feature from list"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove?.();
          }}
        >
          <FaTimes />
        </button>
      )}
    </>
  );

  return isEditing ? (
    <div
      className={commonClasses}
      onMouseEnter={() => onHover?.(feature.id)}
      onMouseLeave={() => onHover?.(null)}
    >
      {content}
    </div>
  ) : (
    <Link
      to="/feature/$featureId"
      params={{ featureId: feature.id.toString() }}
      className={commonClasses}
      onMouseEnter={() => onHover?.(feature.id)}
      onMouseLeave={() => onHover?.(null)}
    >
      {content}
    </Link>
  );
}
