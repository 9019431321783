import { useGetRegion } from '@/api/hooks';
import { useParams } from '@tanstack/react-router';
import { Layer, Source } from 'react-map-gl/maplibre';

export default function RegionHighlight() {
  const params = useParams({ strict: false });
  const regionId = params.regionId ? Number(params.regionId) : undefined;
  const { data: region } = useGetRegion(regionId);

  if (!regionId || !region?.geometrySimplified) {
    return null;
  }

  const regionFeature = {
    type: 'Feature',
    geometry: region.geometrySimplified,
    properties: {
      id: region.id,
      name: region.name,
    },
  } as GeoJSON.Feature;

  return (
    <Source id="region-highlight" type="geojson" data={regionFeature}>
      <Layer
        id="region-highlight-fill"
        type="fill"
        source="region-highlight"
        paint={{
          'fill-color': '#3b82f6',
          'fill-opacity': 0.1,
          'fill-outline-color': '#3b82f6',
        }}
      />
      <Layer
        id="region-highlight-outline"
        type="line"
        source="region-highlight"
        paint={{
          'line-color': '#3b82f6',
          'line-width': 2,
          'line-opacity': 0.8,
          'line-dasharray': [2, 2],
        }}
      />
    </Source>
  );
}
