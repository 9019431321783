import type { GetActivityResponse } from '@/api/models/activities';
import type { LatLngTuple } from '@googlemaps/polyline-codec';
import type { LineString, Position } from 'geojson';
import type React from 'react';

export function decodePolyline(str: string, precision = 5): LatLngTuple[] {
  let index = 0;
  let lat = 0;
  let lng = 0;
  const coordinates: [number, number][] = [];
  let shift = 0;
  let result = 0;

  while (index < str.length) {
    let byte = null;
    let result_1 = 0;
    let shift_1 = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result_1 |= (byte & 0x1f) << shift_1;
      shift_1 += 5;
    } while (byte >= 0x20);

    const latitude_change = result_1 & 1 ? ~(result_1 >> 1) : result_1 >> 1;

    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    const longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    lat += latitude_change;
    lng += longitude_change;

    coordinates.push([lng / 10 ** precision, lat / 10 ** precision]);
  }

  return coordinates;
}

type BoundsXY = {
  minLng: number;
  maxLng: number;
  minLat: number;
  maxLat: number;
};

export function renderActivityOutline(
  activity: GetActivityResponse,
  containerWidth?: number,
  containerHeight?: number,
): React.ReactNode {
  const geometry = activity.geoJson?.features?.[0]?.geometry as
    | LineString
    | undefined;
  if (!geometry?.coordinates) {
    return null;
  }

  const coordinates = geometry.coordinates;
  const width = containerWidth || 48; // Default width for small containers
  const height = containerHeight || 48; // Default height for small containers
  const padding = Math.min(width, height) * 0.1; // Proportional padding

  const bounds = coordinates.reduce<BoundsXY>(
    (acc, coord) => {
      const [lng, lat] = coord;
      return {
        minLng: Math.min(acc.minLng, lng),
        maxLng: Math.max(acc.maxLng, lng),
        minLat: Math.min(acc.minLat, lat),
        maxLat: Math.max(acc.maxLat, lat),
      };
    },
    {
      minLng: Number.POSITIVE_INFINITY,
      maxLng: Number.NEGATIVE_INFINITY,
      minLat: Number.POSITIVE_INFINITY,
      maxLat: Number.NEGATIVE_INFINITY,
    },
  );

  // Calculate center points of the bounds
  const centerLng = (bounds.maxLng + bounds.minLng) / 2;
  const centerLat = (bounds.maxLat + bounds.minLat) / 2;

  // Calculate the dimensions
  const boundsWidth = bounds.maxLng - bounds.minLng;
  const boundsHeight = bounds.maxLat - bounds.minLat;

  // Calculate scale based on the larger dimension to maintain aspect ratio
  const scale = Math.min(
    (width - 2 * padding) / boundsWidth,
    (height - 2 * padding) / boundsHeight,
  );

  const centeredPoints = coordinates
    .map((coord: Position) => {
      const [lng, lat] = coord;
      // Center the coordinates around 0,0 first
      const centeredLng = lng - centerLng;
      const centeredLat = lat - centerLat;

      // Scale and translate to final position
      return {
        x: centeredLng * scale + width / 2,
        y: height / 2 - centeredLat * scale,
      };
    })
    .map(({ x, y }) => `${x},${y}`)
    .join(' ');

  return (
    <svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMidYMid meet"
      className="w-full h-full min-h-[48px] min-w-[48px]"
    >
      <title>Activity Route</title>
      <defs>
        <filter id="glow">
          <feGaussianBlur stdDeviation="0.8" result="coloredBlur" />
          <feOffset dx="0" dy="0" result="offsetBlur" />
          <feFlood floodColor="#fb923c" floodOpacity="0.3" result="glowColor" />
          <feComposite
            in="glowColor"
            in2="coloredBlur"
            operator="in"
            result="softGlow"
          />
          <feMerge>
            <feMergeNode in="softGlow" />
            <feMergeNode in="softGlow" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <polyline
        points={centeredPoints}
        fill="none"
        stroke="#0f172a"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        filter="url(#glow)"
      />
      <polyline
        points={centeredPoints}
        fill="none"
        stroke="#f97316"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
