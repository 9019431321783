import { useNavigate, useParams } from '@tanstack/react-router';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { CgEditBlackPoint } from 'react-icons/cg';
import {
  FaExternalLinkAlt,
  FaList,
  FaMountain,
  FaQuestionCircle,
  FaTimes,
} from 'react-icons/fa';
import {
  FaArrowTrendUp,
  FaArrowsRotate,
  FaCameraRetro,
  FaPencil,
} from 'react-icons/fa6';
import { useMap } from 'react-map-gl/maplibre';

import {
  useCreateAscent,
  useGetDatasource,
  useGetFeature,
  useGetSelf,
  useQueueEvent,
  useUploadFeatureImage,
} from '@/api/hooks';
import ErrorMessage from '@/components/errorMessage';
import LoadingSpinner from '@/components/loadingSpinner';
import { Button } from '@/components/react-aria/Button';
import Alert from '@/components/shared/alert';
import Section from '@/components/shared/section';
import { featureTypeMap } from '@/utils/featureTypeMap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from '../../../api/apiTypes';
import Body from '../body';
import Header from '../header';
import Sidebar from '../sidebar';
import AscentListItem from './ascentListItem';
import AscentRegistration from './ascentRegistration';
import ListListItem from './listListItem';
import { Regions } from './regionList';

const ZOOM_LEVEL = 13;
const FLY_SPEED = 5;

const Photo = ({
  photo,
}: {
  photo: {
    id: number;
    url: string;
  };
}) => {
  return (
    <div className="aspect-square rounded-xs shadow-xs transition-transform hover:scale-105">
      <img
        className="w-full h-full object-cover rounded-xs"
        src={photo.url}
        alt="Feature"
      />
    </div>
  );
};

const DatasourceFeatureListItem = ({
  datasourceFeature,
}: {
  datasourceFeature: {
    id: number;
    datasourceId: number;
    link: string | null;
  };
}) => {
  const { data: datasource, status: getDatasourceStatus } = useGetDatasource(
    datasourceFeature.datasourceId,
  );

  if (getDatasourceStatus !== 'success') {
    return null;
  }

  return (
    <div>
      {datasourceFeature.link ? (
        <a
          href={datasourceFeature.link}
          target="_blank"
          rel="noreferrer"
          className={clsx(
            'flex flex-row gap-2 items-center text-slate-100 rounded-md p-2 bg-slate-700',
            {
              'hover:bg-slate-600': datasourceFeature.link,
            },
          )}
        >
          <div className="size-6">
            {datasource?.icon && (
              <img
                className="size-6"
                src={datasource.icon}
                alt={datasource.name}
              />
            )}
          </div>
          {datasource?.name && <span className="grow">{datasource.name}</span>}

          {datasourceFeature.link && <FaExternalLinkAlt />}
        </a>
      ) : (
        <div
          className={clsx(
            'flex flex-row gap-2 items-center text-slate-100 rounded-md p-2 bg-slate-700',
          )}
        >
          <div className="size-6">
            {datasource?.icon && (
              <img
                className="size-6"
                src={datasource.icon}
                alt={datasource.name}
              />
            )}
          </div>
          <div className="flex flex-col gap-0.5 items-start grow">
            {datasource?.name && <span>{datasource.name}</span>}
            <span className="text-slate-400">ID: {datasourceFeature.id}</span>
          </div>
        </div>
      )}
    </div>
  );
};

interface MetricCardProps {
  icon: React.ReactNode;
  label: string;
  value?: number | null;
  unit: string;
  formatter?: (value: number) => string;
}

function MetricCard({ icon, label, value, unit, formatter }: MetricCardProps) {
  const { t } = useTranslation();
  const formattedValue =
    value !== undefined && value !== null && formatter
      ? formatter(value)
      : value?.toLocaleString();

  return (
    <div className="flex flex-col items-center justify-center bg-slate-700 rounded-lg p-4 hover:bg-slate-600 transition-colors">
      <div className="flex items-center gap-2 text-slate-300 mb-1">
        {icon}
        <span>{label}</span>
      </div>
      {value !== undefined && value !== null ? (
        <div className="flex items-baseline gap-1">
          <span className="text-2xl font-bold text-slate-100">
            {formattedValue}
          </span>
          <span className="text-slate-400">{unit}</span>
        </div>
      ) : (
        <div className="flex items-center gap-1 text-slate-400">
          <FaQuestionCircle className="text-xs" />
          <span>{t('feature.metrics.unknown')}</span>
        </div>
      )}
    </div>
  );
}

export default function FeatureView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams({ from: '/feature/$featureId' });
  const map = useMap();
  const { data: user, status: useGetSelfStatus } = useGetSelf();
  const queueEvent = useQueueEvent();
  const uploadImage = useUploadFeatureImage();
  const createAscent = useCreateAscent();

  const [error, setError] = React.useState<string | null>(null);
  const [isAscentDialogOpen, setIsAscentDialogOpen] = useState(false);

  const feature = useGetFeature(Number.parseInt(params.featureId, 10));

  const isMobile = window.innerWidth < 768;

  // biome-ignore lint/correctness/useExhaustiveDependencies: We don't want to recenter the map only because the viewport changes
  useEffect(() => {
    if (feature.data) {
      map.default?.flyTo({
        center: [feature.data?.longitude, feature.data?.latitude],
        zoom: ZOOM_LEVEL,
        speed: FLY_SPEED,
        padding: {
          top: 50,
          bottom: isMobile ? 150 : 50,
          left: isMobile ? 50 : 450,
          right: 50,
        },
      });
    }
  }, [feature.data, isMobile]);

  const handleUploadClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (ev: Event) => {
      const target = ev.target as HTMLInputElement;
      const file = target.files?.[0];
      if (!file || !feature.data?.id) return;

      setError(null);

      void uploadImage
        .mutateAsync({
          id: feature.data.id,
          file,
        })
        .catch(() => {
          setError(t('feature.upload.error'));
        });
    };
    input.click();
  };

  const actions = [];

  if (useGetSelfStatus === 'success' && user.role === Role.ADMIN) {
    actions.push({
      icon: <FaPencil className="w-full h-full" />,
      text: t('feature.actions.edit'),
      onClick: () => {
        if (feature.data?.id) {
          navigate({
            to: '/feature/edit/$featureId',
            params: { featureId: feature.data.id.toString() },
          });
        }
      },
    });
    actions.push({
      icon: <FaArrowsRotate className="w-full h-full" />,
      text: t('feature.actions.forceRefresh'),
      onClick: () =>
        queueEvent.mutate({
          type: 'summittale-featurerefresh',
          data: {
            type: 'summittale-featurerefresh',
            featureId: feature.data?.id,
          },
        }),
    });
    // Upload photo. Opens a file selector directly.
    actions.push({
      icon: <FaCameraRetro className="w-full h-full" />,
      text: t('feature.actions.uploadPhoto'),
      onClick: handleUploadClick,
    });
  }

  actions.push({
    icon: <FaTimes className="w-full h-full" />,
    text: t('feature.actions.close'),
    onClick: () => navigate({ to: '/' }),
  });

  return (
    <>
      {createAscent.isPending && <LoadingSpinner />}
      <Sidebar
        header={
          <Header
            actions={actions}
            title={
              <>
                {feature.data?.type && featureTypeMap[feature.data?.type].icon}
                <span className="grow text-base font-semibold">
                  {feature.data?.name
                    ? feature.data?.name
                    : t('feature.unnamedFeature')}
                </span>
              </>
            }
          />
        }
        body={
          <Body>
            {feature.isLoading && <LoadingSpinner />}
            {feature.isError && (
              <ErrorMessage error={feature.error} retry={feature.refetch} />
            )}
            {feature.isSuccess && (
              <div>
                <Helmet>
                  <>
                    <title>
                      {t('feature.meta.title', {
                        name: feature.data.name || t('feature.unnamedFeature'),
                        elevation: feature.data.elevation
                          ? t('feature.meta.elevationSuffix', {
                              elevation: feature.data.elevation,
                            })
                          : '',
                      })}
                    </title>
                    <meta
                      property="og:title"
                      content={feature.data.name || t('feature.unnamedFeature')}
                    />
                    {feature.data.photoUrl && (
                      <meta
                        property="og:image"
                        content={feature.data.photoUrl}
                      />
                    )}
                    <meta
                      property="og:url"
                      content={t('feature.meta.ogUrl', {
                        id: feature.data.id,
                      })}
                    />
                    <meta property="og:type" content="website" />
                  </>
                </Helmet>
                {feature.data.photoUrl && (
                  <div className="w-full h-48 shadow-xs">
                    <img
                      className="w-full h-full object-cover shadow-xs"
                      src={feature.data.photoUrl}
                      alt={feature.data?.name || t('feature.unnamedFeature')}
                    />
                  </div>
                )}
                {uploadImage.isPending && (
                  <div className="flex flex-col items-center gap-4 p-4 bg-slate-800 m-4 rounded-lg">
                    <LoadingSpinner />
                  </div>
                )}
                {error && (
                  <div className="mx-4 mt-4">
                    <Alert message={error} variant="error" />
                  </div>
                )}
                <div className="flex flex-col mb-4 mx-4 mt-4">
                  <Section title="" first>
                    {feature.data?.regionIds &&
                      feature.data.regionIds.length > 0 && (
                        <div className="px-0">
                          <Regions regions={feature.data.regionIds} />
                        </div>
                      )}
                    <div className="grid grid-cols-2 gap-6 w-full px-4">
                      <MetricCard
                        icon={<FaMountain />}
                        label={t('feature.metrics.elevation')}
                        value={feature.data?.elevation}
                        unit={t('feature.metrics.units.meters')}
                      />
                      {feature.data?.prominence !== undefined && (
                        <MetricCard
                          icon={<FaArrowTrendUp />}
                          label={t('feature.metrics.prominence')}
                          value={feature.data?.prominence}
                          unit={t('feature.metrics.units.meters')}
                        />
                      )}
                    </div>
                  </Section>

                  {feature.data?.isolation && (
                    <MetricCard
                      icon={<CgEditBlackPoint />}
                      label={t('feature.metrics.isolation')}
                      value={feature.data?.isolation}
                      unit={t('feature.metrics.units.kilometers')}
                      formatter={(v) => v.toFixed(2)}
                    />
                  )}
                  {user && (
                    <Section
                      title={t('feature.sections.ascents')}
                      icon={<FaArrowTrendUp />}
                    >
                      <div className="flex flex-col gap-1">
                        {feature.data?.ascentIds?.map((ascentId) => (
                          <AscentListItem ascentId={ascentId} key={ascentId} />
                        ))}
                      </div>
                      <Button
                        variant="primary"
                        onPress={() => setIsAscentDialogOpen(true)}
                        className="w-full"
                      >
                        {t('feature.ascent.register')}
                      </Button>
                      {isAscentDialogOpen && (
                        <AscentRegistration
                          featureId={feature.data.id}
                          onClose={() => setIsAscentDialogOpen(false)}
                        />
                      )}
                    </Section>
                  )}

                  {feature.data?.listIds && feature.data.listIds.length > 0 && (
                    <Section
                      title={t('feature.sections.lists')}
                      icon={<FaList />}
                    >
                      <div className="flex flex-col gap-1">
                        {feature.data.listIds.map((listId) => (
                          <ListListItem listId={listId} key={listId} />
                        ))}
                      </div>
                    </Section>
                  )}

                  {feature.data?.photos && feature.data.photos.length > 0 && (
                    <Section title={t('feature.sections.photos')}>
                      <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                        {feature.data.photos.map((photo) => (
                          <Photo key={photo.id} photo={photo} />
                        ))}
                      </div>
                    </Section>
                  )}

                  {feature.data?.datasourceFeatures && (
                    <div className={clsx('transition-all duration-200')}>
                      <Section title={t('feature.sections.dataSources')}>
                        <div className="flex flex-col gap-2 w-full">
                          {feature.data.datasourceFeatures.length > 0 ? (
                            feature.data?.datasourceFeatures.map(
                              (datasourceFeature) => (
                                <DatasourceFeatureListItem
                                  datasourceFeature={datasourceFeature}
                                  key={datasourceFeature.id}
                                />
                              ),
                            )
                          ) : (
                            <div className="flex flex-row gap-2 items-center text-slate-100 rounded-md p-2 bg-slate-700">
                              <span className="grow">
                                {t('feature.actions.noSources')}
                              </span>
                            </div>
                          )}
                        </div>
                      </Section>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Body>
        }
      />
    </>
  );
}
