import { useTranslation } from 'react-i18next';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Button } from './react-aria/Button';

interface ErrorMessageProps {
  error: Error;
  retry?: () => void;
}

export default function ErrorMessage(props: ErrorMessageProps) {
  const { error, retry } = props;
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex flex-col justify-center items-center text-center gap-2 p-3">
      <FaExclamationTriangle className="text-4xl text-red-500" />
      <p>{t('errors.generic')}</p>
      <span className="text-red-400">{error.message}</span>
      {retry && (
        <Button
          onPress={retry}
          className="px-4 py-2 rounded-md bg-slate-800 text-white hover:bg-slate-700"
          type="button"
        >
          {t('errors.tryAgain')}
        </Button>
      )}
    </div>
  );
}
