import { RegionType } from '@/api/apiTypes';
import { useGetList, useGetLists, useGetRegion } from '@/api/hooks';
import type { GetListResponse } from '@/api/models';
import { ComboBox, ComboBoxItem } from '@/components/react-aria/ComboBox';
import { useFeatureQuery } from '@/contexts/featureQueryContext';
import type { Key } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaList } from 'react-icons/fa6';
import { Filter } from './filter';

const RegionImage = ({ regionId }: { regionId: number }) => {
  const { data: region } = useGetRegion(regionId);

  if (!region) {
    return (
      <FaList
        size={16}
        className="text-slate-300 shrink-0"
        aria-hidden="true"
      />
    );
  }

  if (region.type === RegionType.COUNTRY && region.isocode) {
    return (
      <span
        className={`fi fi-${region.isocode.toLocaleLowerCase()} h-4 w-4 shrink-0`}
      />
    );
  }

  if (region.imageUrl) {
    return (
      <img
        src={region.imageUrl}
        alt=""
        className="h-5 rounded-xs object-cover shrink-0 ring-1 ring-slate-700/50"
      />
    );
  }

  return (
    <FaList size={16} className="text-slate-300 shrink-0" aria-hidden="true" />
  );
};

const ListMenu = ({ onClose }: { onClose: () => void }) => {
  const { queryOptions, clearFilter, updateQueryOptions } = useFeatureQuery();
  const { t, i18n } = useTranslation();
  const { data } = useGetLists();
  const lists = data?.lists || [];

  const handleListSelect = (key: Key | null) => {
    if (!key || key === 'all') {
      clearFilter('listId');
    } else {
      updateQueryOptions('listId', key.toString());
    }
    onClose();
  };

  const favoriteLists = lists.filter(
    (list: GetListResponse) => list.userFavorite,
  );
  const otherLists = lists.filter(
    (list: GetListResponse) => !list.userFavorite,
  );

  return (
    <div
      className="absolute top-full left-0 mt-2 bg-slate-800/95 backdrop-blur-xs rounded-xl shadow-lg p-2 min-w-[280px] border border-slate-700/50 flex flex-col gap-2"
      role="menu"
      style={{ zIndex: 31 }}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <div className="px-1">
        <ComboBox
          className="w-full"
          selectedKey={queryOptions.listId?.value || 'all'}
          onSelectionChange={handleListSelect}
          defaultInputValue={
            queryOptions.listId?.value
              ? lists
                  .find((l) => l.id.toString() === queryOptions.listId?.value)
                  ?.translations?.find((t) => t.language === i18n.language)
                  ?.name ||
                lists.find(
                  (l) => l.id.toString() === queryOptions.listId?.value,
                )?.name ||
                t('mapFilters.lists.all')
              : t('mapFilters.lists.all')
          }
          autoFocus
        >
          <ComboBoxItem id="all">
            <div className="flex items-center gap-2">
              <FaList size={14} className="text-slate-300 shrink-0" />
              {t('mapFilters.lists.all')}
            </div>
          </ComboBoxItem>
          {otherLists.map((list: GetListResponse) => (
            <ComboBoxItem key={list.id} id={list.id.toString()}>
              <div className="flex items-center gap-2">
                {list.regionId ? (
                  <RegionImage regionId={list.regionId} />
                ) : (
                  <FaList size={14} className="text-slate-300 shrink-0" />
                )}
                {list.translations?.find((t) => t.language === i18n.language)
                  ?.name || list.name}
              </div>
            </ComboBoxItem>
          ))}
        </ComboBox>
      </div>

      {favoriteLists.length > 0 && (
        <div className="border-t border-slate-700/50 pt-2">
          <div className="px-3 mb-1">
            <span className="text-xs font-medium text-slate-400">
              {t('mapFilters.lists.favorites')}
            </span>
          </div>
          <div className="flex flex-col gap-1 px-1">
            {favoriteLists.map((list: GetListResponse) => (
              <button
                key={list.id}
                type="button"
                onClick={() => {
                  updateQueryOptions('listId', list.id.toString());
                  onClose();
                }}
                className={`group flex items-center gap-2 px-3 py-2 rounded-lg cursor-pointer transition-all duration-150 ease-in-out ${
                  queryOptions.listId?.value === list.id.toString()
                    ? 'bg-slate-700/80'
                    : 'hover:bg-slate-700/70 active:bg-slate-700/90'
                }`}
              >
                <div className="flex items-center gap-2 flex-1">
                  {list.regionId ? (
                    <RegionImage regionId={list.regionId} />
                  ) : (
                    <FaList size={14} className="text-slate-300 shrink-0" />
                  )}
                  <span className="text-slate-200 font-medium text-start text-balance">
                    {list.translations?.find(
                      (t) => t.language === i18n.language,
                    )?.name || list.name}
                  </span>
                </div>
                {queryOptions.listId?.value === list.id.toString() && (
                  <div
                    className="h-2 w-2 rounded-full bg-blue-500 shadow-2xs shadow-blue-500/20"
                    aria-hidden="true"
                  />
                )}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const ListFilter = () => {
  const { queryOptions, clearFilter } = useFeatureQuery();
  const { t, i18n } = useTranslation();
  const { data: list } = useGetList(
    queryOptions.listId?.value ? Number(queryOptions.listId.value) : undefined,
  );
  const [showMenu, setShowMenu] = useState(false);

  // Find translation for current language, fallback to default name
  const translation = list?.translations?.find(
    (t) => t.language === i18n.language,
  );
  const listName = translation?.name || list?.name;

  return (
    <Filter
      onClick={() => setShowMenu(!showMenu)}
      showMenu={showMenu}
      onMenuClose={() => setShowMenu(false)}
      active={!!queryOptions.listId?.value}
      isAutomatic={queryOptions.listId?.isAutomatic}
      onClear={
        queryOptions.listId?.value && !queryOptions.listId?.isAutomatic
          ? () => clearFilter('listId')
          : undefined
      }
      ariaLabel={listName || t('mapFilters.lists.all')}
      menuContent={showMenu && <ListMenu onClose={() => setShowMenu(false)} />}
    >
      {queryOptions.listId?.value && list?.regionId ? (
        <RegionImage regionId={list.regionId} />
      ) : (
        <FaList
          size={16}
          className="text-slate-300 shrink-0"
          aria-hidden="true"
        />
      )}
      <div className="flex flex-col min-w-0 max-w-[150px]">
        <span className="text-[10px] leading-none text-slate-400 mb-0.5">
          {t('mapFilters.lists.label')}
        </span>
        <span className="text-slate-200 font-medium truncate">
          {queryOptions.listId?.value ? listName : t('mapFilters.lists.all')}
        </span>
      </div>
    </Filter>
  );
};
