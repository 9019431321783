import apiClient from '@/api/apiClient';
import type { Feature, List } from '@/api/apiTypes';
import type {
  GetListLeaderboardOptions,
  GetListLeaderboardResponse,
  GetListResponse,
  GetListsResponse,
  ListTranslation,
  UpdateListRequest,
} from '@/api/models';
import { queryClient } from '@/api/queryClient';
import {
  type UseMutationResult,
  type UseQueryOptions,
  type UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';

export const useAddList = (): UseMutationResult<
  List,
  unknown,
  { data: List }
> => useMutation({ mutationFn: ({ data }) => apiClient.post('/lists', data) });

export const useUpdateList = (): UseMutationResult<
  GetListResponse,
  AxiosError,
  UpdateListRequest
> =>
  useMutation({
    mutationFn: (data) =>
      apiClient
        .put<GetListResponse>(`/lists/${data.id}`, data)
        .then((res) => res.data),
    onSuccess: (_data, variables) => {
      queryClient.setQueryData(
        ['list', variables.id],
        (oldData: GetListResponse) => {
          if (!oldData || !variables.language) return oldData;

          // For English, update the base name/description
          if (variables.language === 'en') {
            return {
              ...oldData,
              name: variables.name || oldData.name,
              description: variables.description ?? oldData.description,
            };
          }

          // For other languages, update or add the translation
          const existingTranslationIndex = oldData.translations.findIndex(
            (t) => t.language === variables.language,
          );

          const newTranslation: ListTranslation = {
            language: variables.language,
            name: variables.name || '',
            description: variables.description || null,
          };

          const updatedTranslations = [...oldData.translations];
          if (existingTranslationIndex >= 0) {
            updatedTranslations[existingTranslationIndex] = newTranslation;
          } else {
            updatedTranslations.push(newTranslation);
          }

          return {
            ...oldData,
            translations: updatedTranslations,
          };
        },
      );
    },
  });

export const useFavoriteList = (): UseMutationResult<
  null,
  unknown,
  { listId: number }
> =>
  useMutation({
    mutationFn: ({ listId }) =>
      apiClient.put(`/athlete/favoritelists/${listId}`),

    onSuccess: (_, { listId }) => {
      queryClient.setQueryData<List>(['list', listId], (list) =>
        list
          ? {
              ...list,
              favoriteCount: list.favoriteCount + 1,
              userFavorite: true,
            }
          : undefined,
      );
    },
  });

export const useUnfavoriteList = (): UseMutationResult<
  null,
  unknown,
  { listId: number }
> =>
  useMutation({
    mutationFn: ({ listId }) =>
      apiClient.delete(`/athlete/favoritelists/${listId}`),
    onSuccess: (_, { listId }) => {
      queryClient.setQueryData<List>(['list', listId], (list) =>
        list
          ? {
              ...list,
              favoriteCount: list.favoriteCount - 1,
              userFavorite: false,
            }
          : undefined,
      );
    },
  });

export const useGetLists = (
  options?: Partial<UseQueryOptions<GetListsResponse>>,
): UseQueryResult<GetListsResponse, AxiosError> =>
  useQuery({
    queryKey: ['lists'],
    queryFn: () =>
      apiClient.get<GetListsResponse>('/lists').then((res) => ({
        ...res.data,
        lists: res.data.lists.map((list) => ({
          ...list,
          createdAt: new Date(list.createdAt),
          userLastAscentAt: list.userLastAscentAt
            ? new Date(list.userLastAscentAt)
            : null,
        })),
      })),
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    ...options,
  });

export const useGetList = (
  id: number | undefined,
): UseQueryResult<GetListResponse, AxiosError> =>
  useQuery({
    queryKey: ['list', id],
    queryFn: () =>
      apiClient.get<GetListResponse>(`/lists/${id}`).then((res) => ({
        ...res.data,
        createdAt: new Date(res.data.createdAt),
        userLastAscentAt: res.data.userLastAscentAt
          ? new Date(res.data.userLastAscentAt)
          : null,
      })),
    enabled: id !== undefined,
  });

export const useGetListLeaderboard = (
  listId: number,
  options?: GetListLeaderboardOptions,
): UseQueryResult<GetListLeaderboardResponse, AxiosError> =>
  useQuery({
    queryKey: ['listLeaderboard', { listId, options }],
    queryFn: () =>
      apiClient
        .get<GetListLeaderboardResponse>(`/lists/${listId}/leaderboard`, {
          params: options,
        })
        .then((res) => res.data),
  });

export const useGetFeaturesInlist = (params: {
  listId: string;
}): UseQueryResult<Feature[], AxiosError> =>
  useQuery({
    queryKey: ['features', params],
    queryFn: () =>
      apiClient
        .get<Feature[]>(`/lists/${params.listId}/features`)
        .then((res) => res.data),
  });

export const useAddFeatureToList = (): UseMutationResult<
  AxiosResponse<List>,
  unknown,
  { listId: number; featureId: number }
> =>
  useMutation({
    mutationFn: (data: { listId: number; featureId: number }) =>
      apiClient.post<List>(`/lists/${data.listId}/features`, {
        featureId: data.featureId,
      }),
    onMutate: ({ listId }) => {
      queryClient.cancelQueries({
        queryKey: ['features', { listId: listId.toString() }],
      });

      queryClient.invalidateQueries({ queryKey: ['list', listId.toString()] });

      const previousFeatures = queryClient.getQueryData<Feature[]>([
        'features',
        { listId: listId.toString() },
      ]);

      // TODO: Fix and enable optimistic updates
      /* queryClient.setQueryData<Feature[]>(
          ['features', { listId: list.id.toString() }],
          (old) => {
            return old ? [...old, feature] : undefined;
          }
        ); */

      return { previousFeatures };
    },
    onError: (_error, { listId }, context) => {
      if (context?.previousFeatures) {
        queryClient.setQueryData<Feature[]>(
          ['features', { listId: listId.toString() }],
          context.previousFeatures,
        );
      }
    },
    onSettled: (_data, _error, { listId }) => {
      queryClient.invalidateQueries({ queryKey: ['lists'] });
      queryClient.invalidateQueries({ queryKey: ['list', listId.toString()] });
      queryClient.invalidateQueries({
        queryKey: ['features', { listId: listId.toString() }],
      });
    },
  });

export const useRemoveFeatureFromList = (): UseMutationResult<
  AxiosResponse<List>,
  unknown,
  { listId: number; featureId: number }
> =>
  useMutation({
    mutationFn: (data: { listId: number; featureId: number }) =>
      apiClient.delete<List>(
        `/lists/${data.listId}/features/${data.featureId}`,
      ),
    onMutate: async ({ listId }) => {
      await queryClient.cancelQueries({
        queryKey: ['features', { listId: listId.toString() }],
      });

      const previousFeatures = queryClient.getQueryData<Feature[]>([
        'features',
        { listId: listId.toString() },
      ]);

      // Remove feature from list
      // TODO: Fix and enable optimistic updates
      /* queryClient.setQueryData<Feature[]>(
          ['features', { listId: list.id.toString() }],
          (old) => {
            return old ? old.filter((f) => f.id !== feature.id) : undefined;
          }
        );
        */

      return { previousFeatures };
    },
    onError: (_error, { listId }, context) => {
      if (context?.previousFeatures) {
        queryClient.setQueryData<Feature[]>(
          ['features', { listId: listId.toString() }],
          context.previousFeatures,
        );
      }
    },
    onSettled: (_data, _error, { listId }) => {
      queryClient.invalidateQueries({ queryKey: ['lists'] });
      queryClient.invalidateQueries({ queryKey: ['list', listId.toString()] });
      queryClient.invalidateQueries({
        queryKey: ['features', { listId: listId.toString() }],
      });
    },
  });
