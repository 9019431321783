import { useNavigate, useParams } from '@tanstack/react-router';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FaHeart,
  FaList,
  FaRegHeart,
  FaSave,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTimes,
} from 'react-icons/fa';
import { FaPencil } from 'react-icons/fa6';
import { useMap } from 'react-map-gl/maplibre';

import { APIContext } from '@/api/apiProvider';
import { Role } from '@/api/apiTypes';
import {
  useAddFeatureToList,
  useFavoriteList,
  useGetFeatures,
  useGetList,
  useGetSelf,
  useRemoveFeatureFromList,
  useUnfavoriteList,
  useUpdateList,
} from '@/api/hooks';
import type { UpdateListRequest } from '@/api/models';
import ErrorMessage from '@/components/errorMessage';
import LoadingSpinner from '@/components/loadingSpinner';
import {
  Button,
  ProgressBar,
  Select,
  SelectItem,
  Tab,
  TabList,
  Tabs,
  TextField,
} from '@/components/react-aria';
import Search from '@/components/search';
import Section from '@/components/shared/section';
import Body from '@/components/sidebar/body';
import Header, { type HeaderAction } from '@/components/sidebar/header';
import Sidebar from '@/components/sidebar/sidebar';
import { useHoveredFeatureId } from '@/hooks/useHoveredFeature';
import toast from 'react-hot-toast';
import ListLeaderboard from './leaderboard';

import { CreatorBadge, PrivacyBadge, RegionBadge } from './badges';
import FeatureListItem from './featureListItem';
import {
  getTranslatedDescription,
  getTranslatedName,
  getTranslationForLanguage,
} from './utils';

export default function ListView() {
  const { t, i18n } = useTranslation();
  const params = useParams({ from: '/list/$listId' });
  const map = useMap();
  const navigate = useNavigate();
  const { isSignedIn, currentUserId } = useContext(APIContext);
  const { data: currentUser } = useGetSelf();

  const [sortBy, setSortBy] = useState('prominence');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const favoriteListMutation = useFavoriteList();
  const unfavoriteListMutation = useUnfavoriteList();

  const [editMode, setEditMode] = useState(false);

  const { hoveredFeatureId, setHoveredFeatureId } = useHoveredFeatureId();

  const { data: list, status: listStatus } = useGetList(
    Number.parseInt(params.listId as string, 10),
  );

  const {
    data: featureData,
    status: featureStatus,
    error: featureQueryError,
    ...featureQuery
  } = useGetFeatures({
    listId: params.listId as string,
  });

  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if (
      listStatus === 'success' &&
      list?.boundEast &&
      list?.boundWest &&
      list?.boundNorth &&
      list?.boundSouth
    ) {
      map.default?.fitBounds(
        [
          [
            Math.min(list.boundEast, list.boundWest),
            Math.min(list.boundSouth, list.boundNorth),
          ],
          [
            Math.max(list.boundEast, list.boundWest),
            Math.max(list.boundSouth, list.boundNorth),
          ],
        ],
        {
          padding: {
            top: 50,
            bottom: isMobile ? 150 : 50,
            left: isMobile ? 50 : 450,
            right: 50,
          },
          maxZoom: 13,
          speed: 2,
        },
      );
    }
  }, [
    listStatus,
    list?.boundEast,
    list?.boundWest,
    list?.boundNorth,
    list?.boundSouth,
    map.default,
    isMobile,
  ]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    watch,
    reset,
  } = useForm<UpdateListRequest>();

  const updateListMutation = useUpdateList();

  const onSubmit = handleSubmit((data) =>
    updateListMutation.mutateAsync(
      {
        id: Number.parseInt(params.listId as string, 10),
        name: data.name,
        description: data.description,
        language: data.language,
      },
      {
        onSuccess: () => {
          // Don't close edit mode, just show success message
          toast.success(t('list.updateSuccess'));
        },
        onError: (error) => {
          console.error('Failed to update list:', error);
          toast.error(t('list.updateError'));
        },
      },
    ),
  );

  // Watch the language field to show current translation
  const selectedLanguage = watch('language', i18n.language);

  // Add this effect to update form values when language changes
  useEffect(() => {
    if (list && selectedLanguage) {
      const translation = getTranslationForLanguage(list, selectedLanguage);
      reset({
        language: selectedLanguage,
        // For English, always use the base list properties
        name: selectedLanguage === 'en' ? list.name : translation.name,
        description:
          selectedLanguage === 'en'
            ? list.description
            : translation.description,
      });
    }
  }, [selectedLanguage, list, reset]);

  const removeFeatureFromListMutation = useRemoveFeatureFromList();
  const addFeatureToListMutation = useAddFeatureToList();

  const actions = [];

  if (listStatus === 'success') {
    if (list.userFavorite) {
      actions.push({
        icon: <FaHeart className="w-full h-full text-red-400" />,
        text: t('list.actions.removeFavorite'),
        onClick: () => {
          unfavoriteListMutation.mutate({
            listId: list.id,
          });
        },
      } as HeaderAction);
    } else {
      actions.push({
        icon: <FaRegHeart className="w-full h-full text-red-400" />,
        text: t('list.actions.favorite'),
        onClick: () => {
          favoriteListMutation.mutate({
            listId: list.id,
          });
        },
      } as HeaderAction);
    }

    // Only show edit button if user is admin or list creator
    const canEdit =
      isSignedIn &&
      (currentUser?.role === Role.ADMIN || list.createdById === currentUserId);
    if (canEdit && !editMode) {
      actions.push({
        icon: <FaPencil className="w-full h-full" />,
        text: t('list.actions.edit'),
        onClick: () => setEditMode(true),
      });
    }
  }

  const sortedFeatures = featureData?.sort((a, b) => {
    const direction = sortDirection === 'asc' ? 1 : -1;
    if (sortBy === 'name') {
      return direction * (a.name ?? '').localeCompare(b.name ?? '');
    }
    if (sortBy === 'elevation') {
      return direction * ((b.elevation || 0) - (a.elevation || 0));
    }
    if (sortBy === 'prominence') {
      return direction * ((b.prominence || 0) - (a.prominence || 0));
    }
    return 0;
  });

  const renderSortIcon = () => {
    if (sortDirection === 'asc') return <FaSortUp />;
    if (sortDirection === 'desc') return <FaSortDown />;
    return <FaSort />;
  };

  const languages = [
    { id: 'en', value: 'en' },
    { id: 'nb-NO', value: 'nb-NO' },
  ];

  return (
    <Sidebar
      header={
        <Header
          actions={[
            ...actions,
            {
              icon: <FaTimes className="w-full h-full" />,
              text: t('list.actions.close'),
              onClick: () => navigate({ to: '/' }),
            },
          ]}
          title={
            <>
              <FaList className="h-4 w-4 shrink-0" />
              <span className="grow text-base font-semibold">
                {list && getTranslatedName(list, i18n.language)}
              </span>
            </>
          }
        />
      }
      body={
        <Body>
          {listStatus === 'pending' && featureStatus === 'pending' ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="mx-4 mt-4 grow-0">
                {listStatus === 'pending' && <LoadingSpinner />}
                {listStatus === 'success' && (
                  <>
                    <Helmet>
                      <title>
                        {t('list.meta.title', {
                          name: getTranslatedName(list, i18n.language),
                        })}
                      </title>
                      <meta
                        property="og:title"
                        content={getTranslatedName(list, i18n.language)}
                      />
                      {getTranslatedDescription(list, i18n.language) && (
                        <meta
                          property="og:description"
                          content={getTranslatedDescription(
                            list,
                            i18n.language,
                          )}
                        />
                      )}
                      <meta
                        property="og:url"
                        content={`https://www.summittale.com/list/${list.id}`}
                      />
                    </Helmet>
                    {editMode ? (
                      <form onSubmit={onSubmit} className="flex flex-col gap-2">
                        <Controller
                          control={control}
                          name="language"
                          render={({ field }) => (
                            <Select
                              label={t('list.language')}
                              selectedKey={field.value}
                              onSelectionChange={(key) => field.onChange(key)}
                              onBlur={field.onBlur}
                              isDisabled={isSubmitting}
                              items={languages}
                            >
                              {(item) => (
                                <SelectItem id={item.id}>
                                  {t(`languages.${item.value}`)}
                                </SelectItem>
                              )}
                            </Select>
                          )}
                        />
                        <Controller
                          control={control}
                          name="name"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={t('list.name')}
                              isDisabled={isSubmitting}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="description"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              value={field.value || ''}
                              label={t('list.description')}
                              isDisabled={isSubmitting}
                            />
                          )}
                        />
                        <div className="flex justify-end gap-2 mt-4">
                          <Button
                            variant="secondary"
                            onPress={() => setEditMode(false)}
                            isDisabled={isSubmitting}
                          >
                            {t('common.cancel')}
                          </Button>
                          <Button
                            type="submit"
                            variant="primary"
                            isDisabled={isSubmitting || !isDirty}
                            className="flex items-center gap-2"
                          >
                            {isSubmitting ? (
                              <>
                                <div className="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                                <span>{t('common.saving')}</span>
                              </>
                            ) : (
                              <>
                                <FaSave className="w-4 h-4" />
                                <span>{t('common.save')}</span>
                              </>
                            )}
                          </Button>
                        </div>
                      </form>
                    ) : (
                      <div className="flex flex-col gap-4">
                        {list.description && (
                          <div className="bg-slate-700/50 rounded-lg p-4 border border-slate-600/50">
                            <p className="text-sm text-slate-200 leading-relaxed">
                              {getTranslatedDescription(list, i18n.language)}
                            </p>
                          </div>
                        )}

                        <div className="flex flex-wrap gap-2 justify-center">
                          <CreatorBadge list={list} />
                          <PrivacyBadge privacy={list.privacy} />
                          {list.regionId && <RegionBadge list={list} />}
                        </div>

                        {featureStatus === 'success' &&
                          featureData.length > 0 && (
                            <div className="flex flex-col gap-2 justify-around items-center">
                              <ProgressBar
                                value={
                                  featureData.filter(
                                    (feature) =>
                                      feature.ascentIds &&
                                      feature.ascentIds.length > 0,
                                  ).length
                                }
                                maxValue={featureData.length}
                                label={t('list.progress.features', {
                                  count: featureData.filter(
                                    (feature) =>
                                      feature.ascentIds &&
                                      feature.ascentIds.length > 0,
                                  ).length,
                                  total: featureData.length,
                                })}
                              />
                            </div>
                          )}
                        {!editMode && (
                          <ListLeaderboard
                            listId={list.id}
                            listFeatureCount={featureData?.length}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              <Section className="mx-4 mt-4 mb-4">
                {featureStatus === 'pending' && <LoadingSpinner />}
                {featureStatus === 'error' && (
                  <ErrorMessage
                    error={featureQueryError}
                    retry={featureQuery.refetch}
                  />
                )}
                {featureStatus === 'success' &&
                  (featureData?.length === 0 ? (
                    <div className="flex flex-col items-center justify-center gap-4 p-8">
                      <p className="text-slate-300 text-center">
                        {t('list.features.empty.noFeatures')}
                      </p>
                      {list &&
                        currentUser &&
                        (currentUser.role === Role.ADMIN ||
                          list.createdById === currentUserId) &&
                        !editMode && (
                          <>
                            <p className="text-slate-400 text-sm text-center">
                              {t('list.features.empty.getStarted')}
                            </p>
                            <Button
                              variant="primary"
                              onPress={() => setEditMode(true)}
                              className="flex items-center gap-2"
                            >
                              <FaPencil className="w-4 h-4" />
                              <span>{t('list.actions.edit')}</span>
                            </Button>
                          </>
                        )}
                    </div>
                  ) : (
                    <div className="overflow-auto flex flex-col gap-2">
                      <div className="flex justify-between items-center p-2">
                        <Tabs
                          selectedKey={sortBy}
                          onSelectionChange={(key) => setSortBy(key as string)}
                        >
                          <TabList>
                            <Tab id="name">{t('list.sort.name')}</Tab>
                            <Tab id="elevation">{t('list.sort.elevation')}</Tab>
                            <Tab id="prominence">
                              {t('list.sort.prominence')}
                            </Tab>
                          </TabList>
                        </Tabs>
                        <button
                          type="button"
                          onClick={() =>
                            setSortDirection((d) =>
                              d === 'asc' ? 'desc' : 'asc',
                            )
                          }
                          className="p-2 text-slate-400 hover:text-slate-200 transition-colors"
                          aria-label="Toggle sort direction"
                        >
                          {renderSortIcon()}
                        </button>
                      </div>

                      <div className="flex flex-col gap-1">
                        {sortedFeatures?.map((feature) => (
                          <FeatureListItem
                            key={feature.id}
                            feature={feature}
                            isEditing={editMode}
                            isHovered={feature.id === hoveredFeatureId}
                            onHover={setHoveredFeatureId}
                            onRemove={() =>
                              list &&
                              removeFeatureFromListMutation.mutate({
                                listId: list.id,
                                featureId: feature.id,
                              })
                            }
                          />
                        ))}
                      </div>
                    </div>
                  ))}

                {editMode && (
                  <div className="p-1 animate-fade-in">
                    <Search
                      onFeatureSelect={(featureId) =>
                        list &&
                        addFeatureToListMutation.mutate({
                          listId: list.id,
                          featureId: featureId,
                        })
                      }
                      resultContainerPosition="top"
                    />
                  </div>
                )}
              </Section>
            </>
          )}
        </Body>
      }
    />
  );
}
