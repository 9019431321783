import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import {
  FaCircleCheck,
  FaCircleXmark,
  FaCrown,
  FaGlobe,
  FaLock,
  FaUser,
  FaUserShield,
} from 'react-icons/fa6';

import { PrivacyLevel, type RegionDTO, RegionType, Role } from '@/api/apiTypes';
import type { GetAdminAtheleteResponse } from '@/api/models';
import { useGetAthletes, useGetRegion } from '../../api/hooks';
import AdminTable from './adminTable';

const getRegionIcon = (region: RegionDTO) => {
  if (region.type === RegionType.COUNTRY && !region.isocode) return null;

  return (
    <>
      {region.type === RegionType.COUNTRY && region.isocode && (
        <span
          className={`fi fi-${region.isocode.toLocaleLowerCase()} h-6 w-6`}
        />
      )}
      {region.type !== RegionType.COUNTRY && region.imageUrl && (
        <img className="h-6" alt={region.name} src={region.imageUrl} />
      )}
    </>
  );
};

const Region = ({ regionId }: { regionId: number }) => {
  const { data: region, isLoading } = useGetRegion(regionId);

  if (isLoading)
    return <div className="animate-pulse bg-slate-700 h-6 w-20 rounded-xs" />;

  return (
    <div className="flex items-center gap-2 px-2 py-1 rounded-md">
      {region && getRegionIcon(region)}
      <span>{region?.name}</span>
      <span className="text-xs text-slate-400">
        ({region?.type.toLowerCase()})
      </span>
    </div>
  );
};

const InitialSetup = ({ completed }: { completed: boolean }) => (
  <div
    className={`flex items-center gap-1 ${completed ? 'text-green-500' : 'text-amber-500'}`}
  >
    {completed ? (
      <>
        <FaCircleCheck />
        <span>Complete</span>
      </>
    ) : (
      <>
        <FaCircleXmark />
        <span>Pending</span>
      </>
    )}
  </div>
);

const getPrivacyIconAndText = (privacy: PrivacyLevel) => {
  switch (privacy) {
    case PrivacyLevel.PUBLIC:
      return (
        <>
          <FaGlobe className="text-green-300" title="Public" />
          <span className="text-green-400">Public</span>
        </>
      );
    case PrivacyLevel.PRIVATE:
      return (
        <>
          <FaLock className="text-red-300" title="Private" />
          <span className="text-red-400">Private</span>
        </>
      );
    case PrivacyLevel.FRIENDS:
      return (
        <>
          <FaUser className="text-blue-300" title="Friends" />
          <span className="text-blue-400">Friends</span>
        </>
      );
    case PrivacyLevel.USERS:
      return (
        <>
          <FaUserShield className="text-amber-300" title="Users" />
          <span className="text-amber-400">Users</span>
        </>
      );
  }
};

export default function Athletes() {
  const athletesQuery = useGetAthletes();

  const columnHelper = createColumnHelper<GetAdminAtheleteResponse>();

  const columns = [
    columnHelper.accessor('profilePhotoUrl', {
      cell: (props) => {
        const url = props.getValue();

        return (
          <div className="flex items-center gap-2">
            {url ? (
              <img
                alt=""
                className="w-8 h-8 rounded-full bg-slate-800 object-cover"
                src={url}
              />
            ) : (
              <div className="w-8 h-8 rounded-full" />
            )}
            <span>{props.row.original.username}</span>
          </div>
        );
      },
      header: undefined,
    }),
    columnHelper.accessor('id', { header: 'ID' }),
    columnHelper.accessor('email', {
      cell: (props) => (
        <div className="flex flex-col">
          <div>{props.getValue()}</div>
          <div className="flex items-center gap-1 text-xs">
            {props.row.original.emailVerified ? (
              <>
                <FaCircleCheck className="text-green-500" />
                <span className="text-green-600">Verified</span>
              </>
            ) : (
              <>
                <FaCircleXmark className="text-red-500" />
                <span className="text-red-600">Not Verified</span>
              </>
            )}
          </div>
        </div>
      ),
      header: 'Email',
    }),
    columnHelper.accessor('initialSetup', {
      cell: (props) => <InitialSetup completed={props.getValue()} />,
      header: 'Initial Setup',
    }),
    columnHelper.accessor('username', { header: 'Username' }),
    columnHelper.accessor('firstname', { header: 'First Name' }),
    columnHelper.accessor('lastname', { header: 'Last Name' }),
    columnHelper.accessor('profilePrivacy', {
      cell: (props) => (
        <div className="flex items-center gap-1">
          {getPrivacyIconAndText(props.getValue())}
        </div>
      ),
      header: 'Profile Privacy',
    }),
    columnHelper.accessor('ascentPrivacy', {
      cell: (props) => (
        <div className="flex items-center gap-1">
          {getPrivacyIconAndText(props.getValue())}
        </div>
      ),
      header: 'Ascent Privacy',
    }),
    columnHelper.accessor('role', {
      cell: (props) => (
        <div className="flex items-center gap-1">
          {props.getValue() === Role.ADMIN ? (
            <>
              <FaUserShield className="text-blue-300" title="Admin" />
              <span className="text-blue-400">Admin</span>
            </>
          ) : (
            <>
              <FaUser className="text-slate-300" title="User" />
              <span className="text-slate-400">User</span>
            </>
          )}
        </div>
      ),
      header: 'Role',
    }),
    columnHelper.accessor('regionId', {
      cell: (props) => {
        const regionId = props.getValue();
        return regionId ? (
          <Region regionId={regionId} />
        ) : (
          <span className="text-slate-400">-</span>
        );
      },
      header: 'Region',
    }),
    columnHelper.accessor('premium', {
      cell: (props) => (
        <div className="flex items-center gap-1">
          <FaCrown
            className={props.getValue() ? 'text-amber-500' : 'text-slate-300'}
          />
          <span
            className={props.getValue() ? 'text-amber-500' : 'text-slate-400'}
          >
            {props.getValue() ? 'Premium' : 'Basic'}
          </span>
        </div>
      ),
      header: 'Premium',
    }),
    columnHelper.accessor('activityCount', {
      cell: (props) => (
        <div className="font-mono">{props.getValue().toLocaleString()}</div>
      ),
      header: 'Activity Count',
    }),
    columnHelper.accessor('featureCount', {
      cell: (props) => (
        <div className="space-y-1">
          <div className="flex items-center gap-1">
            <span className="font-medium">Peaks:</span>
            <span className="font-mono">
              {props.getValue().PEAK.toLocaleString()}
            </span>
          </div>
        </div>
      ),
      header: 'Feature Count',
    }),
    columnHelper.accessor('totalProminence', {
      cell: (props) => (
        <div className="font-mono">{props.getValue().toLocaleString()}m</div>
      ),
      header: 'Total Prominence',
    }),
  ];

  if (athletesQuery.isLoading) {
    return <div>Loading...</div>;
  }
  if (athletesQuery.isError) {
    return <div>Error</div>;
  }

  return (
    <AdminTable
      columns={columns as ColumnDef<GetAdminAtheleteResponse>[]}
      data={athletesQuery.data || []}
    />
  );
}
