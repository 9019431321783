import { clsx } from 'clsx';
import { FaCircleExclamation, FaTriangleExclamation } from 'react-icons/fa6';

interface AlertProps {
  message: string;
  variant: 'error' | 'warning';
}

export default function Alert({ message, variant }: AlertProps) {
  return (
    <div
      className={clsx('p-4 rounded-md flex items-center gap-2', {
        'bg-red-900/50 text-red-200 border border-red-700': variant === 'error',
        'bg-yellow-900/50 text-yellow-200 border border-yellow-700':
          variant === 'warning',
      })}
    >
      {variant === 'error' ? (
        <FaCircleExclamation className="h-5 w-5 shrink-0" />
      ) : (
        <FaTriangleExclamation className="h-5 w-5 shrink-0" />
      )}
      <span>{message}</span>
    </div>
  );
}
