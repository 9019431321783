import { ErrorBoundary } from 'react-error-boundary';

import clsx from 'clsx';
import { useState } from 'react';
import ErrorFallback from '../errorFallback';

interface SidebarProps {
  header: React.ReactNode;
  body: React.ReactNode;
}

export default function Sidebar(props: SidebarProps) {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  return (
    <div
      className={clsx(
        'absolute w-full md:w-96 bottom-0 md:top-0 md:m-4 pointer-events-none z-40',
        sidebarExpanded ? 'top-0' : 'top-0',
      )}
    >
      <div className="flex h-full">
        <div className="w-full max-h-full overflow-y-auto bg-slate-800 flex flex-col md:rounded-md z-50 shadow-xs shadow-slate-700 pointer-events-auto self-end md:self-start">
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div
              className="cursor-pointer"
              onClick={() => setSidebarExpanded(!sidebarExpanded)}
              onKeyDown={() => setSidebarExpanded(!sidebarExpanded)}
            >
              {props.header}
            </div>
            <div
              className="grid grow overflow-hidden"
              style={{
                gridTemplateRows: sidebarExpanded ? '1fr' : '0fr',
                transition: 'grid-template-rows 0.2s ease-out',
              }}
            >
              {props.body}
            </div>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}
