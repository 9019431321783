import { useNavigate, useParams } from '@tanstack/react-router';
import { FaSpinner } from 'react-icons/fa';

import { useGetFeature } from '@/api/hooks';

import EditFeatureModal from '@/components/modals/editFeatureModal';

function EditFeature() {
  const params = useParams({ from: '/feature/edit/$featureId' });
  const navigate = useNavigate();
  const numericFeatureId = params.featureId
    ? Number.parseInt(params.featureId, 10)
    : undefined;
  const { data: feature, status } = useGetFeature(numericFeatureId);

  const handleClose = () => {
    navigate({
      to: '/feature/$featureId',
      params: { featureId: params.featureId },
    });
  };

  if (status === 'pending') {
    return (
      <div className="flex justify-center items-center h-full">
        <FaSpinner className="animate-spin text-4xl" />
      </div>
    );
  }

  if (!feature) {
    return null;
  }

  return <EditFeatureModal feature={feature} onClose={handleClose} />;
}

export default EditFeature;
