import axios from 'axios';
import type React from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import axiosInstance from './apiClient';
import type { AthleteDTO } from './models';

export interface APIContextData {
  isSignedIn: boolean;
  currentUserId?: number;
  isAuthError: (error: unknown) => boolean;
}

// Create the context
export const APIContext = createContext<APIContextData>({
  isSignedIn: true,
  currentUserId: undefined,
  isAuthError: () => false,
});

export const API_BASE = import.meta.env.PROD
  ? 'https://api.summittale.com'
  : import.meta.env.VITE_API_URL;

const isAuthError = (error: unknown) => {
  return axios.isAxiosError(error) && error.response?.status === 401;
};

// Create the provider using the context
function APIProvider({ children }: { children: React.ReactNode }) {
  const [isSignedIn, setIsSignedIn] = useState(false); // Initially, user is considered signed out
  const [currentUserId, setCurrentUserId] = useState<number | undefined>();

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response, // Simply pass through successful responses
      (error) => {
        // Unauthorized (invalid credentials or no credentials), different from 403 which says you are signed in but don't have permission
        if (isAuthError(error)) {
          setIsSignedIn(false);
          setCurrentUserId(undefined);
        }
        return Promise.reject(error);
      },
    );

    // Hook into /auth/logout to clear the session
    const logoutInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        if (response.config.url === '/auth/logout') {
          setIsSignedIn(false);
          setCurrentUserId(undefined);
        }
        return response;
      },
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
      axiosInstance.interceptors.response.eject(logoutInterceptor);
    };
  }, []);

  // Trigger a request to check if user is signed in
  useEffect(() => {
    axiosInstance
      .get<AthleteDTO>('/athlete')
      .then((response) => {
        setIsSignedIn(true);
        setCurrentUserId(response.data.id);
      })
      .catch(() => {
        setIsSignedIn(false);
        setCurrentUserId(undefined);
      });
  }, []);

  const apiContextValue = useMemo(
    () => ({
      isSignedIn,
      currentUserId,
      isAuthError,
    }),
    [isSignedIn, currentUserId],
  );

  return (
    <APIContext.Provider value={apiContextValue}>
      {children}
    </APIContext.Provider>
  );
}

export default APIProvider;
