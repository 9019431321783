import type { AthleteDTO } from '@/api/models';

/* eslint-disable no-use-before-define */
export type Activity = {
  id: number;
  name: string;
  mapPoyline?: string;
  mapSummaryPolyline?: string;
  sportType: string;
  startDateLocal: string;
  distance: number;
  movingTime: number;
  elapsedTime: number;
};

export enum RegionType {
  CONTINENT = 'CONTINENT',
  COUNTRY = 'COUNTRY',
  COUNTY = 'COUNTY',
  MUNICIPALITY = 'MUNICIPALITY',
  REGION = 'REGION',
  PLANET = 'PLANET',
}

// TODO: Fix and replace with DTO
export type Region = {
  id: number;
  name: string;
  description?: string;
  type: RegionType;
  parentRegion?: Region;
  parentRegionId?: number;
  childRegions: Region[];
  geometry?: string;
  geometrySimplified?: string;
  imageBlobStorage?: {
    url: string;
  };
  features: Feature[];
  lists: List[];
  athletes: AthleteDTO[];
  isocode?: string;
  boundEast?: number;
  boundNorth?: number;
  boundSouth?: number;
  boundWest?: number;
  centerLat?: number;
  centerLon?: number;
};

export type RegionDTO = {
  id: number;
  name: string;
  description?: string;
  type: RegionType;
  parentRegion?: RegionDTO;
  childRegions: RegionDTO[];
  geometry?: GeoJSON.Geometry;
  geometrySimplified?: GeoJSON.Geometry;
  isocode?: string;
  imageUrl?: string;
  boundEast?: number;
  boundNorth?: number;
  boundSouth?: number;
  boundWest?: number;
  centerLat?: number;
  centerLon?: number;
};

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum FeatureType {
  ATTRACTION = 'ATTRACTION',
  CABIN = 'CABIN',
  CLIMBING_GYM = 'CLIMBING_GYM',
  CRAG = 'CRAG',
  PARKING = 'PARKING',
  PEAK = 'PEAK',
  POINT = 'POINT',
  WAYPOINT = 'WAYPOINT',
}

export type Feature = {
  id: number;
  name?: string;
  type: FeatureType;
  latitude: number;
  longitude: number;
  elevation?: number;
  prominence?: number;
  isolation?: number;
  regions: Region[];
  notes?: string;
  primaryPhoto?: Photo;
  primaryPhotoId?: number;
  createdBySummitTale: boolean;
  createdBy?: AthleteDTO;
  createdById?: number;
  summitTaleVerified: boolean;
  activities: Activity[];
  featureOnList: FeatureOnList[];
  // userFeatures: UserFeatures[];
  // lockedFields: LockedField[];
  dataSourceFeatures: DataSourceFeature[];
  selectedFields: SelectedField[];
  photos: Photo[];
  ascents?: Ascent[];
};

export enum FeatureFieldname {
  ELEVATION = 'ELEVATION',
  ISOLATION = 'ISOLATION',
  PROMINENCE = 'PROMINENCE',
  LATITUDE = 'LATITUDE',
  LONGITUDE = 'LONGITUDE',
  NAME = 'NAME',
  NOTES = 'NOTES',
  TYPE = 'TYPE',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type GetOptions = {
  filter?: { field: string; value: string | number; operator: string }[];
  limit?: number;
  offset?: number;
  sort?: { field: string; direction: SortDirection }[];
};

export interface GetAllFeaturesQuery {
  athleteId?: string;
  bounds?: {
    north: string;
    east: string;
    south: string;
    west: string;
  };
  listId?: string;
  withProminenceOnly?: boolean;
  visited?: boolean;
  featureTypes?: FeatureType[];
  regionId?: string;
}

export interface GetAllActivitiesQuery {
  bounds?: {
    north: string;
    east: string;
    south: string;
    west: string;
  };
}

export type FeatureOnList = {
  id: number;
  feature: Feature;
  featureId: number;
  list: List;
  listId: number;
};

export type List = {
  id: number;
  name: string;
  description: string;
  createdBy?: AthleteDTO;
  createdById?: number;
  createdBySummitTale: boolean;
  features: FeatureOnList[];
  featureCount: number;
  region: Region;
  userList?: UserList[];
  favoriteCount: number;
  boundEast?: number;
  boundNorth?: number;
  boundSouth?: number;
  boundWest?: number;
};

export type UserList = {
  athlete: AthleteDTO;
  athleteId: number;
  list: List;
  listId: number;
  featureCount: number;
  favorite: boolean;
};

export type SelectedField = {
  dataSourceFeature: DataSourceFeature;
  dataSourceFeatureId: number;
  feature: Feature;
  featureId: number;
  fieldName: FeatureFieldname;
};

export type DataSourceFeature = {
  id: number;
  dataSourceFeatureId?: bigint;
  dataSource: DataSource;
  dataSourceId?: number;
  feature: Feature;
  featureId?: number;

  name: string;
  type: FeatureType;
  latitude: number;
  longitude: number;
  elevation?: number;
  prominence?: number;
  isolation?: number;
  notes?: string;
  link?: string;

  syncedAt?: string;

  selectedFields: SelectedField[];

  region?: Region;
  regionId?: number;
};

export type DataSource = {
  id: number;
  name: string;
  coverage?: Region;
  regionId?: number;
  license: string;
  link?: string;
  dataSourceFeatures: DataSourceFeature[];
  syncedAt: string;
  blobStorageObject?: {
    url: string;
  };
  blobStorageObjectId?: number;
};

export interface CreateFeatureDTO {
  name?: string;
  type: FeatureType;
  elevation?: number;
  prominence?: number;
  latitude: number;
  longitude: number;
  isolation?: number;
}

export interface UpdateFeatureDTO {
  id: number;
  name?: string;
  type?: FeatureType;
  elevation?: number;
  prominence?: number;
  latitude?: number;
  longitude?: number;
  isolation?: number;
  selectedFields?: {
    fieldName: FeatureFieldname;
    dataSourceFeatureId: number;
  }[];
}

export type Photo = {
  id: number;
  feature: Feature;
  featureId: number;
  description?: string;
  blobStorageObject?: {
    url: string;
  };
  blobStorageObjectId?: number;
  user?: AthleteDTO;
  userId?: number;
  primaryPhotoForFeatures: Feature[];
  attribution?: string;
  attributionLink?: string;
  attributionEnabled: boolean;
  createdAt: string;
};

export type Ascent = {
  id: number;
  athlete: AthleteDTO;
  athleteId: number;
  feature: Feature;
  featureId: number;
  activity?: Activity;
  activityId?: number;
  time?: number;
  date?: string;
  datePrecision?: DatePrecision;
  notes?: string;
};

export enum DatePrecision {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  SECOND = 'SECOND',
}

// Numeric DatePrecision values
export const DatePrecisionValue = {
  [DatePrecision.YEAR]: 1,
  [DatePrecision.MONTH]: 2,
  [DatePrecision.DAY]: 3,
  [DatePrecision.HOUR]: 4,
  [DatePrecision.MINUTE]: 4,
  [DatePrecision.SECOND]: 4,
};

export enum BlobStorageProvider {
  AZURE = 'AZURE',
  AWS = 'AWS',
}

export type Blob = {
  id: number;
  provider: BlobStorageProvider;
  container: string;
  blobName: string;
  url: string;
  contentType: string;
  size: number;
  createdAt: string;
};

export enum PrivacyLevel {
  PUBLIC = 'PUBLIC',
  USERS = 'USERS',
  FRIENDS = 'FRIENDS',
  PRIVATE = 'PRIVATE',
}

export enum ActivityType {
  WALK = 'WALK',
  HIKE = 'HIKE',
  RUN = 'RUN',
  BIKE = 'BIKE',
  CLIMB = 'CLIMB',
  SKI = 'SKI',
  SNOWBOARD = 'SNOWBOARD',
  OTHER = 'OTHER',
}
