import type React from 'react';
import { type ReactNode, createContext, useContext, useState } from 'react';

type FeatureId = number | null;

interface HoveredFeatureIdContextType {
  hoveredFeatureId: FeatureId;
  setHoveredFeatureId: (id: FeatureId) => void;
}

const HoveredFeatureIdContext = createContext<
  HoveredFeatureIdContextType | undefined
>(undefined);

interface HoveredFeatureIdProviderProps {
  children: ReactNode;
}

export const HoveredFeatureIdProvider: React.FC<
  HoveredFeatureIdProviderProps
> = ({ children }) => {
  const [hoveredFeatureId, setHoveredFeatureId] = useState<FeatureId>(null);

  return (
    <HoveredFeatureIdContext.Provider
      value={{ hoveredFeatureId, setHoveredFeatureId }}
    >
      {children}
    </HoveredFeatureIdContext.Provider>
  );
};

export const useHoveredFeatureId = (): HoveredFeatureIdContextType => {
  const context = useContext(HoveredFeatureIdContext);
  if (!context) {
    throw new Error(
      'useHoveredFeatureId must be used within a HoveredFeatureIdProvider',
    );
  }
  return context;
};
