import { ActivityType, RegionType } from '@/api/apiTypes';
import {
  useGetActivities,
  useGetAscent,
  useGetFeature,
  useGetRegion,
} from '@/api/hooks';
import type { GetActivityResponse } from '@/api/models';
import Alert from '@/components/shared/alert';
import Section from '@/components/shared/section';
import { renderActivityOutline } from '@/utils/geoUtils';
import { Link } from '@tanstack/react-router';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  FaBicycle,
  FaClock,
  FaHiking,
  FaMapMarkerAlt,
  FaMountain,
  FaQuestion,
  FaRoute,
  FaRunning,
  FaSkiing,
  FaSnowboarding,
  FaWalking,
} from 'react-icons/fa';
import { GiMountainClimbing } from 'react-icons/gi';

type ActivitiesProps = {
  athleteId: number;
};

export const Activities = ({ athleteId }: ActivitiesProps) => {
  const {
    data: activities,
    isLoading,
    error,
  } = useGetActivities({
    athleteId,
    limit: 5,
    offset: 0,
    sort: [{ field: 'startDate', direction: 'desc' }],
  });

  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Section title={t('activities.title')}>
        <div className="animate-pulse space-y-3">
          {[...Array(3)].map((_, i) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: Skeleton loading
            <div key={i} className="h-12 bg-gray-700/50 rounded-md" />
          ))}
        </div>
      </Section>
    );
  }

  if (error) {
    return (
      <Section title={t('activities.title')}>
        <Alert
          variant="error"
          message="Failed to load activities. Please try again later."
        />
      </Section>
    );
  }

  if (!activities?.length) {
    return null;
  }

  return (
    <Section title={t('activities.title')}>
      <div className="flex flex-col gap-1">
        {activities?.map((activity) => (
          <ActivityListItem key={activity.id} activity={activity} />
        ))}
      </div>
    </Section>
  );
};

const Badge = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex items-center gap-1 bg-slate-800 px-1.5 py-0.5 rounded text-slate-400">
      {children}
    </div>
  );
};

const ActivityListItem = ({ activity }: { activity: GetActivityResponse }) => {
  const activityDate = new Date(activity.startDateLocal);
  const { t } = useTranslation();
  const { data: region } = useGetRegion(activity.regionId);

  const formatDuration = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
  };

  const formatDistance = (meters: number) => {
    const km = meters / 1000;
    return km >= 1 ? `${km.toFixed(1)} km` : `${meters} m`;
  };

  const getActivityIcon = (type: ActivityType) => {
    switch (type) {
      case ActivityType.SKI:
        return <FaSkiing />;
      case ActivityType.BIKE:
        return <FaBicycle />;
      case ActivityType.CLIMB:
        return <GiMountainClimbing />;
      case ActivityType.SNOWBOARD:
        return <FaSnowboarding />;
      case ActivityType.RUN:
        return <FaRunning />;
      case ActivityType.HIKE:
        return <FaHiking />;
      case ActivityType.WALK:
        return <FaWalking />;
      case ActivityType.OTHER:
        return <FaQuestion />;
    }
  };

  return (
    <Link
      to="/activity/$activityId"
      params={{ activityId: activity.id.toString() }}
      className={clsx(
        'group bg-slate-700 rounded-md shadow-xs transition-all duration-150',
        'hover:bg-slate-600/80 hover:shadow-md overflow-hidden',
      )}
    >
      <div className="flex h-full gap-1">
        {activity.geoJson && (
          <div className="text-slate-400 bg-slate-600 min-h-full w-12 flex items-center justify-center shrink-0 p-1">
            {renderActivityOutline(activity, 48, 48)}
          </div>
        )}
        <div className="flex flex-col p-2 grow gap-2">
          <div className="flex justify-between items-start gap-1">
            <div className="flex flex-col gap-1">
              <h3 className="text-sm font-medium text-slate-100 group-hover:text-white transition-colors">
                {activity.name}
              </h3>
              <div className="flex flex-wrap items-center gap-2 text-xs">
                <Badge>
                  {getActivityIcon(activity.type)}
                  <span>{t(`activities.types.${activity.type}`)}</span>
                </Badge>
                <Badge>
                  <time dateTime={activityDate.toISOString()}>
                    {activityDate.toLocaleDateString(navigator.language, {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </time>
                </Badge>
                {region && <RegionBadge region={region} />}
                {activity.distance && (
                  <Badge>
                    <FaRoute className="w-3 h-3" />
                    <span>{formatDistance(activity.distance)}</span>
                  </Badge>
                )}
                {activity.duration && (
                  <Badge>
                    <FaClock className="w-3 h-3" />
                    <span>{formatDuration(activity.duration)}</span>
                  </Badge>
                )}
              </div>
            </div>
          </div>

          {activity.ascentIds?.length > 0 && (
            <div>
              <div className="text-xs text-slate-400 space-y-1">
                {activity.ascentIds.map((ascentId) => (
                  <AscentFeature key={ascentId} ascentId={ascentId} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

const RegionBadge = ({
  region,
}: { region: NonNullable<ReturnType<typeof useGetRegion>['data']> }) => {
  return (
    <Badge>
      {((region.type === RegionType.COUNTRY && region.isocode) ||
        region.type !== RegionType.COUNTRY) && (
        <>
          {region.type === RegionType.COUNTRY && region.isocode && (
            <span
              className={`fi fi-${region.isocode.toLocaleLowerCase()} h-3 w-3`}
            />
          )}
          {region.type !== RegionType.COUNTRY && region.imageUrl && (
            <img className="h-3" alt={region.name} src={region.imageUrl} />
          )}
        </>
      )}
      {!(
        (region.type === RegionType.COUNTRY && region.isocode) ||
        (region.type !== RegionType.COUNTRY && region.imageUrl)
      ) && <FaMapMarkerAlt className="w-3 h-3" />}
      <span>{region.name}</span>
    </Badge>
  );
};

const AscentFeature = ({ ascentId }: { ascentId: number }) => {
  const { data: ascent } = useGetAscent(ascentId);
  const { data: feature } = useGetFeature(ascent?.featureId);

  if (!feature) return null;

  return (
    <div className="flex items-center gap-1">
      <FaMountain className="w-3 h-3" />
      <span>{feature.name || 'Unnamed feature'}</span>
      {feature.elevation && (
        <>
          <span>•</span>
          <span>{feature.elevation.toLocaleString()} m</span>
        </>
      )}
    </div>
  );
};
