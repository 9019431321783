import { Link } from '@tanstack/react-router';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHeart, FaLightbulb, FaSignInAlt, FaUserCog } from 'react-icons/fa';
import { FaClock, FaMountain, FaTriangleExclamation } from 'react-icons/fa6';
import { Tooltip } from 'react-tooltip';

import { useGetAthleteAscents, useGetLists, useGetSelf } from '@/api/hooks';
import type { GetListResponse } from '@/api/models';
import Section from '@/components/shared/section';
import { APIContext } from '../../../api/apiProvider';
import { Role } from '../../../api/apiTypes';
import Body from '../body';
import Header from '../header';
import AscentListItem from '../listitems/ascentListItem';
import Sidebar from '../sidebar';
import Lists from './lists';

function LoadingSkeleton({ rows = 1 }: { rows?: number }) {
  return (
    <div className="flex flex-col gap-2">
      {Array.from({ length: rows }).map((_, i) => (
        <div
          // biome-ignore lint/suspicious/noArrayIndexKey: This is a loading skeleton
          key={i}
          className="h-12 bg-slate-700/50 rounded-xs animate-pulse"
        />
      ))}
    </div>
  );
}

function ErrorMessage({ message }: { message: string }) {
  return (
    <div className="flex items-center gap-2 text-red-400 text-sm">
      <FaTriangleExclamation />
      <span>{message}</span>
    </div>
  );
}

function WelcomeMessage() {
  const { t } = useTranslation();
  const { data: listsData, isLoading: listsLoading } = useGetLists();
  const [listProgressTooltipContent, setListProgressTooltipContent] =
    useState<GetListResponse | null>(null);

  const lists = listsData?.lists ?? null;

  const popularLists = useMemo(() => {
    if (!lists) return [];

    return lists.sort((a, b) => b.favoriteCount - a.favoriteCount).slice(0, 10);
  }, [lists]);

  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="flex flex-col gap-6 items-center text-center">
        <FaMountain className="text-6xl text-sky-500" />
        <div className="flex flex-col gap-2">
          <h2 className="text-2xl font-bold text-white">
            {t('overview.welcome.title')}
          </h2>
          <p className="text-slate-300">{t('overview.welcome.description')}</p>
        </div>
        <Link
          to="/login"
          className="flex items-center gap-2 px-6 py-3 bg-sky-500 hover:bg-sky-600 text-white rounded-md transition-colors"
        >
          <FaSignInAlt />
          <span>{t('overview.welcome.getStarted')}</span>
        </Link>
      </div>

      {listsLoading ? (
        <Section
          title={t('overview.popularLists')}
          icon={<FaLightbulb className="text-yellow-500" />}
        >
          <LoadingSkeleton rows={5} />
        </Section>
      ) : (
        popularLists.length > 0 && (
          <Section
            title={t('overview.popularLists')}
            icon={<FaLightbulb className="text-yellow-500" />}
          >
            <Lists
              lists={popularLists}
              setListProgressTooltipContent={setListProgressTooltipContent}
            />
          </Section>
        )
      )}

      <Tooltip anchorSelect=".list-progress" disableStyleInjection>
        {listProgressTooltipContent && (
          <div className="text-sm">
            {listProgressTooltipContent.featureCount} {t('overview.peaks')}
          </div>
        )}
      </Tooltip>
    </div>
  );
}

export default function Overview() {
  const { t } = useTranslation();
  const { isSignedIn } = useContext(APIContext);
  // Move listProgressTooltipContent state to component level since it's used in both states
  const [listProgressTooltipContent, setListProgressTooltipContent] =
    useState<GetListResponse | null>(null);

  // Only fetch data if user is signed in
  const { data: athlete, error: athleteError } = useGetSelf({
    enabled: isSignedIn,
  });
  const {
    data: listsData,
    error: listsError,
    isLoading: listsLoading,
  } = useGetLists({
    enabled: isSignedIn,
  });
  const {
    data: athleteAscents,
    error: ascentsError,
    isLoading: ascentsLoading,
  } = useGetAthleteAscents(athlete?.id ?? 0, {
    enabled: isSignedIn && !!athlete,
  });

  const lists = listsData?.lists ?? null;

  const { favoriteLists, popularLists } = useMemo(() => {
    if (!lists) return { favoriteLists: [], popularLists: [] };

    const parsedLists = lists.map((list) => ({
      ...list,
      favorite: list.userFavorite,
      progress: (list.userFeatureCount / list.featureCount) * 100,
    }));

    return {
      favoriteLists: parsedLists
        .filter((l) => l.favorite)
        .sort((a, b) => b.progress - a.progress)
        .sort(
          (a, b) =>
            (b.userLastAscentAt?.getTime() ?? 0) -
            (a.userLastAscentAt?.getTime() ?? 0),
        ),
      popularLists: parsedLists
        .filter((l) => !l.favorite)
        .sort((a, b) => b.favoriteCount - a.favoriteCount)
        .sort(
          (a, b) =>
            (b.userLastAscentAt?.getTime() ?? 0) -
            (a.userLastAscentAt?.getTime() ?? 0),
        )
        .sort((a, b) => b.progress - a.progress)
        .slice(0, 5),
    };
  }, [lists]);

  const allQueriesFailed = athleteError && listsError && ascentsError;

  return (
    <Sidebar
      header={
        <Header
          title={
            <span className="grow text-base font-semibold">
              {t('overview.title')}
            </span>
          }
        />
      }
      body={
        <Body>
          {!isSignedIn ? (
            <WelcomeMessage />
          ) : (
            <div className="flex flex-col p-4">
              {allQueriesFailed ? (
                <ErrorMessage message={t('overview.errors.overview')} />
              ) : (
                <>
                  {ascentsError ? (
                    <ErrorMessage message={t('overview.errors.ascents')} />
                  ) : ascentsLoading ? (
                    <Section
                      title={t('overview.stats.recentAscents')}
                      icon={<FaClock />}
                      first
                    >
                      <LoadingSkeleton rows={3} />
                    </Section>
                  ) : (
                    <Section
                      title={
                        athleteAscents && athleteAscents.length > 0
                          ? t('overview.stats.recentAscents')
                          : ''
                      }
                      icon={
                        athleteAscents && athleteAscents.length > 0 ? (
                          <FaClock />
                        ) : undefined
                      }
                      first
                    >
                      {athleteAscents && athleteAscents.length > 0 ? (
                        <div className="flex flex-col gap-1">
                          {athleteAscents
                            .sort((a, b) =>
                              (b.date || '').localeCompare(a.date || ''),
                            )
                            .slice(0, 3)
                            .map((ascent) => (
                              <AscentListItem key={ascent.id} ascent={ascent} />
                            ))}
                        </div>
                      ) : (
                        <div className="flex flex-col items-center justify-center gap-4 p-6 text-center">
                          <FaMountain className="text-4xl text-slate-500" />
                          <div className="text-slate-300">
                            {t('overview.stats.noAscents')}
                          </div>
                        </div>
                      )}
                    </Section>
                  )}

                  {listsError ? (
                    <ErrorMessage message={t('overview.errors.lists')} />
                  ) : listsLoading ? (
                    <>
                      <Section
                        title={t('overview.favorites')}
                        icon={<FaHeart className="text-red-500" />}
                      >
                        <LoadingSkeleton rows={2} />
                      </Section>
                      <Section
                        title={t('overview.popularLists')}
                        icon={<FaLightbulb className="text-yellow-500" />}
                      >
                        <LoadingSkeleton rows={5} />
                      </Section>
                    </>
                  ) : (
                    <>
                      {favoriteLists.length > 0 && (
                        <Section
                          title={t('overview.favorites')}
                          icon={<FaHeart className="text-red-500" />}
                        >
                          <Lists
                            lists={favoriteLists}
                            setListProgressTooltipContent={
                              setListProgressTooltipContent
                            }
                          />
                        </Section>
                      )}

                      {popularLists.length > 0 && (
                        <Section
                          title={t('overview.popularLists')}
                          icon={<FaLightbulb className="text-yellow-500" />}
                        >
                          <Lists
                            lists={popularLists}
                            setListProgressTooltipContent={
                              setListProgressTooltipContent
                            }
                          />
                        </Section>
                      )}
                    </>
                  )}

                  {isSignedIn && athlete?.role === Role.ADMIN && (
                    <div className="border-t border-slate-700 pt-4 mt-2">
                      <Link
                        to="/admin"
                        className="flex items-center justify-center gap-2 text-lg font-semibold text-slate-300 hover:text-slate-100"
                      >
                        <FaUserCog className="inline-block" />
                        <span>{t('overview.admin')}</span>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <Tooltip anchorSelect=".list-progress" disableStyleInjection>
            {listProgressTooltipContent && (
              <div className="text-sm">
                {listProgressTooltipContent.userFeatureCount} /{' '}
                {listProgressTooltipContent.featureCount}
              </div>
            )}
          </Tooltip>
        </Body>
      }
    />
  );
}
