import { DatePrecision, DatePrecisionValue } from '@/api/apiTypes';
import { useCreateAscent, useGetFeature } from '@/api/hooks';
import { Button } from '@/components/react-aria/Button';
import { NumberField } from '@/components/react-aria/NumberField';
import { Select, SelectItem } from '@/components/react-aria/Select';
import { TextField } from '@/components/react-aria/TextField';
import { featureTypeMap } from '@/utils/featureTypeMap';
import { getLocalTimeZone, now } from '@internationalized/date';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { FaArrowTrendUp, FaCalendar, FaClock } from 'react-icons/fa6';

interface AscentRegistrationProps {
  featureId: number;
  onClose: () => void;
}

export default function AscentRegistration({
  featureId,
  onClose,
}: AscentRegistrationProps) {
  const { t } = useTranslation();
  const createAscent = useCreateAscent();
  const { data: feature } = useGetFeature(featureId);

  const [datePrecision, setDatePrecision] = useState<DatePrecision>(
    DatePrecision.DAY,
  );
  const [notes, setNotes] = useState('');

  const today = now(getLocalTimeZone());
  const [year, setYear] = useState(today.year);
  const [month, setMonth] = useState(today.month);
  const [day, setDay] = useState(today.day);
  const [hour, setHour] = useState(today.hour);
  const [minute, setMinute] = useState(today.minute);

  const monthOptions = useMemo(
    () =>
      Array.from({ length: 12 }, (_, i) => ({
        id: i + 1,
        name: new Date(2000, i).toLocaleString('default', { month: 'long' }),
      })),
    [],
  );

  const dayOptions = useMemo(
    () =>
      Array.from({ length: 31 }, (_, i) => ({
        id: i + 1,
        name: String(i + 1),
      })),
    [],
  );

  const hourOptions = useMemo(
    () =>
      Array.from({ length: 24 }, (_, i) => ({
        id: i,
        name: i.toString().padStart(2, '0'),
      })),
    [],
  );

  const minuteOptions = useMemo(
    () =>
      Array.from({ length: 60 }, (_, i) => ({
        id: i,
        name: i.toString().padStart(2, '0'),
      })),
    [],
  );

  const increasePrecision = () => {
    const nextValue = DatePrecisionValue[datePrecision] + 1;
    const nextPrecision = Object.entries(DatePrecisionValue).find(
      ([_, value]) => value === nextValue,
    )?.[0] as DatePrecision;

    if (nextPrecision) {
      setDatePrecision(nextPrecision);
    }
  };

  const decreasePrecision = () => {
    const prevValue = DatePrecisionValue[datePrecision] - 1;
    const prevPrecision = Object.entries(DatePrecisionValue).find(
      ([_, value]) => value === prevValue,
    )?.[0] as DatePrecision;

    if (prevPrecision) {
      setDatePrecision(prevPrecision);
    }
  };

  const getNextPrecisionText = (current: DatePrecision): string | null => {
    switch (current) {
      case DatePrecision.YEAR:
        return t('feature.ascentLog.date.addMonth');
      case DatePrecision.MONTH:
        return t('feature.ascentLog.date.addDay');
      case DatePrecision.DAY:
        return t('feature.ascentLog.date.addTime');
      default:
        return null;
    }
  };

  const getPreviousPrecisionText = (current: DatePrecision): string | null => {
    switch (current) {
      case DatePrecision.MONTH:
        return t('feature.ascentLog.date.removeMonth');
      case DatePrecision.DAY:
        return t('feature.ascentLog.date.removeDay');
      case DatePrecision.HOUR:
      case DatePrecision.MINUTE:
      case DatePrecision.SECOND:
        return t('feature.ascentLog.date.removeTime');
      default:
        return null;
    }
  };

  const renderGranularityControls = () => {
    const currentValue = DatePrecisionValue[datePrecision];
    const showIncrease =
      currentValue < DatePrecisionValue[DatePrecision.MINUTE];
    const showDecrease = currentValue > DatePrecisionValue[DatePrecision.YEAR];

    if (!showIncrease && !showDecrease) return null;

    return (
      <div className="flex gap-2">
        {showIncrease && (
          <Button
            variant="secondary"
            onPress={increasePrecision}
            className="flex-1 flex items-center justify-center gap-2"
          >
            {currentValue < DatePrecisionValue[DatePrecision.DAY] ? (
              <FaCalendar className="w-4 h-4" />
            ) : (
              <FaClock className="w-4 h-4" />
            )}
            {getNextPrecisionText(datePrecision)}
          </Button>
        )}
        {showDecrease && (
          <Button
            variant="secondary"
            onPress={decreasePrecision}
            className="flex-1 flex items-center justify-center gap-2"
          >
            {currentValue <= DatePrecisionValue[DatePrecision.DAY] ? (
              <FaCalendar className="w-4 h-4" />
            ) : (
              <FaClock className="w-4 h-4" />
            )}
            {getPreviousPrecisionText(datePrecision)}
          </Button>
        )}
      </div>
    );
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && !createAscent.isPending) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose, createAscent.isPending]);

  const handleLogAscent = () => {
    if (!featureId) return;

    const ascentDate = new Date(
      year,
      month - 1,
      datePrecision >= DatePrecision.DAY ? day : 1,
      datePrecision === DatePrecision.MINUTE ? hour : 0,
      datePrecision === DatePrecision.MINUTE ? minute : 0,
    );

    createAscent.mutate(
      {
        featureId,
        date: ascentDate,
        datePrecision,
        notes: notes.trim() || undefined,
      },
      {
        onSuccess: () => {
          onClose();
          setNotes('');
          setDatePrecision(DatePrecision.YEAR);
        },
      },
    );
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-xs flex items-center justify-center p-4 z-50">
      <div className="bg-slate-800 rounded-lg shadow-xl w-full max-w-md">
        <div className="flex items-center justify-between p-4 border-b border-slate-700">
          <div className="flex flex-col">
            <h2 className="text-xl font-semibold text-white">
              {t('feature.ascentLog.title')}
            </h2>
            {feature && (
              <div className="text-sm text-slate-300">
                <div className="flex items-center gap-2">
                  {feature.type && featureTypeMap[feature.type].icon}
                  <span>{feature.name || t('feature.unnamedFeature')}</span>
                </div>
                {feature.elevation && (
                  <div className="text-slate-400">
                    {t('feature.metrics.elevation')}: {feature.elevation}m
                  </div>
                )}
              </div>
            )}
          </div>
          <Button
            variant="icon"
            onPress={onClose}
            aria-label={t('common.close')}
          >
            <FaTimes className="w-5 h-5" />
          </Button>
        </div>

        <div className="p-6 space-y-4">
          <div className="space-y-4">
            <div className="flex gap-2">
              <NumberField
                label={t('feature.ascentLog.date.year')}
                value={year}
                onChange={setYear}
                maxValue={today.year}
                className={'w-20'}
                formatOptions={{
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  style: 'decimal',
                  useGrouping: false,
                }}
              />

              {DatePrecisionValue[datePrecision] >=
                DatePrecisionValue[DatePrecision.MONTH] && (
                <Select
                  label={t('feature.ascentLog.date.month')}
                  selectedKey={month}
                  onSelectionChange={(key) => setMonth(Number(key))}
                  items={monthOptions}
                >
                  {(item) => <SelectItem id={item.id}>{item.name}</SelectItem>}
                </Select>
              )}

              {DatePrecisionValue[datePrecision] >=
                DatePrecisionValue[DatePrecision.DAY] && (
                <Select
                  label={t('feature.ascentLog.date.day')}
                  selectedKey={day}
                  onSelectionChange={(key) => setDay(Number(key))}
                  items={dayOptions}
                >
                  {(item) => <SelectItem id={item.id}>{item.name}</SelectItem>}
                </Select>
              )}
            </div>

            {DatePrecisionValue[datePrecision] >=
              DatePrecisionValue[DatePrecision.MINUTE] && (
              <div className="flex gap-2">
                <Select
                  label={t('feature.ascentLog.date.hour')}
                  selectedKey={hour}
                  onSelectionChange={(key) => setHour(Number(key))}
                  items={hourOptions}
                  className="flex-1"
                >
                  {(item) => <SelectItem id={item.id}>{item.name}</SelectItem>}
                </Select>
                <Select
                  label={t('feature.ascentLog.date.minute')}
                  selectedKey={minute}
                  onSelectionChange={(key) => setMinute(Number(key))}
                  items={minuteOptions}
                  className="flex-1"
                >
                  {(item) => <SelectItem id={item.id}>{item.name}</SelectItem>}
                </Select>
              </div>
            )}

            {renderGranularityControls()}

            <TextField
              label={t('feature.ascentLog.notes')}
              value={notes}
              onChange={setNotes}
              description={t('feature.ascentLog.notesPlaceholder')}
            />
          </div>
        </div>

        <div className="flex justify-end gap-3 p-4 border-t border-slate-700">
          <Button
            variant="secondary"
            onPress={onClose}
            isDisabled={createAscent.isPending}
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant="primary"
            onPress={handleLogAscent}
            isDisabled={createAscent.isPending}
            className="flex items-center gap-2"
          >
            {createAscent.isPending ? (
              <>
                <div className="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                <span>{t('common.saving')}</span>
              </>
            ) : (
              <>
                <FaArrowTrendUp className="w-4 h-4" />
                <span>{t('common.save')}</span>
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
