import { useCallback } from 'react';
import { Button } from 'react-aria-components';
import { useTranslation } from 'react-i18next';
import { Select, SelectItem } from './react-aria/Select';

const languages = [
  { id: 'en', name: 'English', isocode: 'gb' },
  { id: 'nb-NO', name: 'Norsk', isocode: 'no' },
];

export function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const nextLanguage = useCallback(() => {
    const currentIndex = languages.findIndex(
      (lang) => lang.id === i18n.language,
    );
    const nextIndex = (currentIndex + 1) % languages.length;
    i18n.changeLanguage(languages[nextIndex].id);
  }, [i18n]);

  const currentLanguage = languages.find((lang) => lang.id === i18n.language);

  return (
    <>
      {/* Desktop version */}
      <div className="hidden md:block">
        <Select
          defaultSelectedKey={i18n.language}
          onSelectionChange={(key) => i18n.changeLanguage(key.toString())}
          aria-label={i18n.t('language')}
          items={languages}
        >
          {(item) => (
            <SelectItem id={item.id}>
              <div className="flex items-center gap-2">
                <span className={`fi fi-${item.isocode}`} />
                {item.name}
              </div>
            </SelectItem>
          )}
        </Select>
      </div>

      {/* Mobile version */}
      <div className="md:hidden">
        <Button
          onPress={nextLanguage}
          aria-label={i18n.t('language')}
          className="p-2 rounded-md hover:bg-slate-700/50 transition-colors"
        >
          <span className={`fi fi-${currentLanguage?.isocode} text-lg`} />
        </Button>
      </div>
    </>
  );
}
