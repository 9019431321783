import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import type { Key } from 'react-aria-components';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import {
  FaExclamationTriangle,
  FaLock,
  FaSave,
  FaTimes,
  FaUser,
} from 'react-icons/fa';
import { z } from 'zod';

import { PrivacyLevel } from '@/api/apiTypes';
import { useGetSelf, useUpdateProfile } from '@/api/hooks';
import { Button } from '@/components/react-aria/Button';
import { Tab, TabList, TabPanel, Tabs } from '@/components/react-aria/Tabs';
import { useTranslation } from 'react-i18next';
import { FaCircleExclamation } from 'react-icons/fa6';
import DangerZoneTab from './DangerZoneTab';
import PrivacyTab from './PrivacyTab';
import ProfileTab from './ProfileTab';
import type { FormData } from './types';

interface EditProfileModalProps {
  onClose: () => void;
}

const schema = z
  .object({
    username: z
      .string()
      .max(30)
      .transform((str) => (str === '' ? null : str))
      .optional()
      .nullable(),
    firstname: z
      .string()
      .max(30)
      .transform((str) => (str === '' ? null : str))
      .optional()
      .nullable(),
    lastname: z
      .string()
      .max(30)
      .transform((str) => (str === '' ? null : str))
      .optional()
      .nullable(),
    profilePrivacy: z.nativeEnum(PrivacyLevel),
    ascentPrivacy: z.nativeEnum(PrivacyLevel),
  })
  .refine(
    (data) => {
      const privacyLevels = {
        [PrivacyLevel.PUBLIC]: 0,
        [PrivacyLevel.USERS]: 1,
        [PrivacyLevel.FRIENDS]: 2,
        [PrivacyLevel.PRIVATE]: 3,
      };
      return (
        privacyLevels[data.ascentPrivacy] >= privacyLevels[data.profilePrivacy]
      );
    },
    {
      message: 'profile.privacyError',
      path: ['ascentPrivacy'],
    },
  );

export default function EditProfileModal({ onClose }: EditProfileModalProps) {
  const { t } = useTranslation();
  const updateProfile = useUpdateProfile();
  const { data: athlete } = useGetSelf();
  const [selectedTab, setSelectedTab] = useState<Key>('profile');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      username: athlete?.username,
      firstname: athlete?.firstname,
      lastname: athlete?.lastname,
      profilePrivacy: athlete?.profilePrivacy,
      ascentPrivacy: athlete?.ascentPrivacy,
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      await updateProfile.mutateAsync(data);
      toast.success(t('profile.updateSuccess'));
      onClose();
    } catch (error) {
      console.error('Failed to update profile:', error);
      setError('root', {
        type: 'manual',
        message: t('profile.updateError'),
      });
    }
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && !isSubmitting) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose, isSubmitting]);

  if (!athlete) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-xs flex items-center justify-center p-4 z-50">
      <div className="bg-slate-800 rounded-lg shadow-xl w-full max-w-xl">
        <div className="flex items-center justify-between p-4 border-b border-slate-700">
          <div className="flex items-center gap-2">
            <FaUser className="w-6 h-6 text-slate-400" />
            <h2 className="text-xl font-semibold text-white">
              {t('profile.edit')}
            </h2>
          </div>
          <Button
            variant="icon"
            onPress={onClose}
            aria-label={t('common.close')}
          >
            <FaTimes className="w-5 h-5" />
          </Button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-6">
            <Tabs selectedKey={selectedTab} onSelectionChange={setSelectedTab}>
              <TabList
                aria-label="Profile settings tabs"
                className="flex gap-4 flex-wrap justify-center"
              >
                <Tab id="profile">
                  <FaUser className="w-4 h-4 mr-2" />
                  {t('profile.tabs.profile')}
                </Tab>
                <Tab id="privacy">
                  <FaLock className="w-4 h-4 mr-2" />
                  {t('profile.tabs.privacy')}
                </Tab>
                <Tab id="danger">
                  <FaExclamationTriangle className="w-4 h-4 mr-2" />
                  {t('profile.tabs.dangerZone')}
                </Tab>
              </TabList>

              <TabPanel id="profile">
                <ProfileTab register={register} errors={errors} />
              </TabPanel>

              <TabPanel id="privacy">
                <PrivacyTab register={register} errors={errors} watch={watch} />
              </TabPanel>

              <TabPanel id="danger">
                <DangerZoneTab athleteId={athlete.id} />
              </TabPanel>
            </Tabs>

            {/* Add error message display */}
            {errors.root && (
              <div className="mt-4 p-4 bg-red-500/10 border border-red-500/20 rounded-lg">
                <p className="text-red-400 text-sm flex items-center gap-2">
                  <FaCircleExclamation className="w-3.5 h-3.5 shrink-0" />
                  <span>{errors.root.message}</span>
                </p>
              </div>
            )}
          </div>

          {selectedTab !== 'danger' && (
            <div className="flex justify-end gap-3 p-4 border-t border-slate-700">
              <Button
                variant="secondary"
                onPress={onClose}
                isDisabled={isSubmitting}
              >
                {t('common.cancel')}
              </Button>
              <Button
                type="submit"
                variant="primary"
                className="flex items-center gap-2"
                isDisabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <div className="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                    <span>{t('common.saving')}</span>
                  </>
                ) : (
                  <>
                    <FaSave className="w-4 h-4" />
                    <span>{t('common.save')}</span>
                  </>
                )}
              </Button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
