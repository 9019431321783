import apiClient from '@/api/apiClient';
import type {
  AthleteDTO,
  CreateMagicLinkRequestDTO,
  CreateMagicLinkResponseDTO,
} from '@/api/models';
import {
  type UseMutationResult,
  type UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { queryClient } from '../queryClient';

export const useCreateMagicLink = (): UseMutationResult<
  CreateMagicLinkResponseDTO,
  AxiosError,
  CreateMagicLinkRequestDTO
> =>
  useMutation({
    mutationFn: (data: CreateMagicLinkRequestDTO) =>
      apiClient
        .post<CreateMagicLinkResponseDTO>('/auth/magic-link', data)
        .then((res) => res.data),
  });

export const useVerifyMagicLink = (): UseMutationResult<
  { athlete: AthleteDTO },
  AxiosError,
  string
> =>
  useMutation({
    mutationFn: (token: string) =>
      apiClient
        .get<{ athlete: AthleteDTO }>(`/auth/verify?token=${token}`)
        .then((res) => res.data),
    onSuccess: () => {
      // Invalidate the current user query to refetch
      queryClient.invalidateQueries({ queryKey: ['me'] });
    },
  });

export const useLogout = (): UseMutationResult<
  { success: boolean },
  AxiosError,
  void
> =>
  useMutation({
    mutationFn: () =>
      apiClient
        .post<{ success: boolean }>('/auth/logout')
        .then((res) => res.data),
    onSuccess: () => {
      // Clear the current user query
      queryClient.setQueryData(['me'], null);
    },
  });

export const useCurrentUser = (): UseQueryResult<
  AthleteDTO | null,
  AxiosError
> =>
  useQuery({
    queryKey: ['me'],
    queryFn: () =>
      apiClient
        .get<{ athlete: AthleteDTO }>('/auth/me')
        .then((res) => res.data.athlete),
  });
