import { useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { FaBook } from 'react-icons/fa6';

export default function DocsButton() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <button
      type="button"
      onClick={() => navigate({ to: '/docs' })}
      className="flex items-center gap-2 px-3 py-2 text-sm text-slate-300 hover:text-white hover:bg-slate-800 rounded-lg transition-colors"
    >
      <FaBook className="w-4 h-4" />
      <span className="hidden lg:inline-block">{t('docs.title')}</span>
    </button>
  );
}
