import { clsx } from 'clsx';
import { FaClock, FaTrashCan } from 'react-icons/fa6';

import { DatePrecision } from '@/api/apiTypes';
import { useDeleteAscent, useGetAscent } from '@/api/hooks';

interface AscentListItemProps {
  ascentId: number;
}

export default function AscentListItem(props: AscentListItemProps) {
  const { ascentId } = props;

  const { data: ascent, status: getAscentStatus } = useGetAscent(ascentId);

  const localeDateStringOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  switch (ascent?.datePrecision) {
    case DatePrecision.YEAR:
      localeDateStringOptions.weekday = undefined;
      localeDateStringOptions.month = undefined;
      localeDateStringOptions.day = undefined;
      localeDateStringOptions.hour = undefined;
      localeDateStringOptions.minute = undefined;
      localeDateStringOptions.second = undefined;
      break;
    case DatePrecision.MONTH:
      localeDateStringOptions.weekday = undefined;
      localeDateStringOptions.day = undefined;
      localeDateStringOptions.hour = undefined;
      localeDateStringOptions.minute = undefined;
      localeDateStringOptions.second = undefined;
      break;
    case DatePrecision.MINUTE:
    case DatePrecision.HOUR:
      localeDateStringOptions.second = undefined;
      break;
    default:
      localeDateStringOptions.hour = undefined;
      localeDateStringOptions.minute = undefined;
      localeDateStringOptions.second = undefined;
      break;
  }

  const ascentDate = ascent?.date ? new Date(ascent.date) : null;

  const deleteAscent = useDeleteAscent();

  const handleDeleteAscent = () => {
    if (!ascent) return;
    deleteAscent.mutate(ascent.id);
  };

  if (getAscentStatus !== 'success') {
    return null;
  }

  return (
    <div
      className={clsx(
        'group bg-slate-700 p-3 rounded-md shadow-xs transition-all duration-150',
        'hover:bg-slate-600/80 hover:shadow-md',
        'flex flex-col gap-2',
      )}
    >
      <div className="flex items-start justify-between gap-2">
        <div className="flex flex-row text-sm font-medium text-slate-100 group-hover:text-white transition-colors items-center gap-2 w-full">
          <FaClock className="w-3 h-3" />
          {ascentDate ? (
            <time dateTime={ascentDate.toISOString()} className="capitalize">
              {ascentDate.toLocaleDateString(
                navigator.language,
                localeDateStringOptions,
              )}
            </time>
          ) : (
            <span>No Date Registered</span>
          )}
          <div className="grow" />
          <button
            type="button"
            onClick={handleDeleteAscent}
            className="text-slate-400 hover:text-white transition-colors"
          >
            <FaTrashCan />
          </button>
        </div>
      </div>

      {ascent.notes && <div className="text-slate-400">{ascent.notes}</div>}

      {ascent.activity && (
        <div className="flex items-center justify-between text-xs">
          <div className="text-slate-400">
            {ascent.activity.distance && (
              <span>{(ascent.activity.distance / 1000).toFixed(1)} km</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
